<template>
	<ul :id="this.$props.id" class="nav nav-tabs justify-content-center" :class="this.$props.wrapClass ?? ''">
		<li class="nav-item" v-for="(item, index) of items" :key="index">
			<a class="d-flex align-items-center gap-1"
				:class="['nav-link', (item.active || this.active == item.id) ? 'active' : '']" aria-current="page"
				:id="item.id ? item.id : null" :data-bs-toggle="item.data_bs_toggle ? item.data_bs_toggle : null"
				:data-bs-target="item.data_bs_target ? item.data_bs_target : null"
				:title="(item.title) ? item.title : item.label" :href="(item.href) ? item.href : 'javascript:{}'"
				@click="this.runAction(item.action, $event)">
				<img v-if="item.icon" :src="require('@/assets/icons/' + item.icon)" alt="Вперёд">
				{{ item.label }}
				<span v-if="item.count"
					class="badge bg-primary rounded-1 d-flex align-items-center justify-content-center">
					<span v-text="item.count"></span>
					<span class="visually-hidden">{{ item.title }}</span>
				</span>
			</a>
		</li>
	</ul>
</template>

<script>
import CommonService from "@/services/CommonService";

export default {
	name: "NavTabs",
	props: {
		id: { type: String, default: 'nav-tabs' },
		navItems: { type: [Array, Object] },
		activeItem: { type: [Number, String] },
		wrapClass: { type: [Number, String] },
		icon: { type: String, default: '' }
	},
	data() {
		return {

		};
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		runAction(action, event) {

			let items = this.$el.querySelectorAll(".nav-link");
			[].forEach.call(items, function (elemn) {
				elemn.classList.remove("active");
			});

			this.$emit('onClick', event.target.id);
			event.target.classList.add('active');

			if (typeof action == "function")
				return action();
			else
				return;
		}
	},
	mounted() {

	},
	watch: {
		items: function (value, old_value) {
			CommonService.log('info', 'watch::items', { value, old_value });
		},
		active: function (value, old_value) {
			CommonService.log('info', 'watch::active', { value, old_value });
		},
	},
	computed: {
		items: {
			get() {
				return (typeof this.$props.navItems !== "undefined") ? this.$props.navItems : [];
			},
			set(value) {
				value = (!this.isEmpty(value)) ? value : null;
			}
		},
		active: {
			get() {
				return (typeof this.$props.activeItem !== "undefined") ? this.$props.activeItem : null;
			},
			set(value) {
				value = (!this.isEmpty(value)) ? value : null;
			}
		},
	}
};
</script>