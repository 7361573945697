<template>
	<div class="modal fade" id="bindReqClientModal" ref="bindReqClientModal" tabindex="-1" aria-labelledby="Создать заявку?" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-3">
				<div class="modal-header d-flex mb-20 border-0 p-1">
					<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Создать заявку?
					</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div v-if="this.loadet"  class="modal-body d-flex flex-column p-1">
                    <div class="modal-body d-flex flex-column p-0">
                        <InputSelect inputId="funnel_id"
                                    inputLabel="Воронка:"
                                    inputName="funnel_id"
                                    ref="funnel_id"
                                    :inputOptions="this.funnels_list"
                                    :inputValue="this.funnel_id"
                                    inputClass="rounded-1"
                                    inputWrapClass="flex-column mb-3"
                                    :inputRequired="true"
                                    @setValue="(value) => this.funnel_id = value"/>
                    </div>
                    <span v-if="this.is_object_req" class="text-white fs-3 fw-normal bg-raspberry  p-1 px-2 rounded-1" style="text-align: center;"><span class="fw-semi font-semi">Объект уже добавлен в заявку на реализацию</span></span>
                    <button @click="create" v-else type="button" class="btn btn-primary fw-bold  rounded-3 text-white fs-3">Создать новую заявку?</button>
					<button @click="attach" style="margin: 0.5rem 0;" type="button" class="btn btn-primary fw-bold  rounded-3 text-white fs-3">Прикрепить к ранее созданной заявке?</button>
				</div>
				<div v-else class="modal-body d-flex flex-column p-1" data-geo-lat="null" data-geo-lng="null">
					<Loader />
				</div>

				<!--<div class="modal-footer border-0 p-1">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						data-bs-dismiss="modal"
					>
						Закрыть
					</button>
				</div>-->
			</div>
		</div>
	</div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import api from "@/api";
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import CommonService from "@/services/CommonService";
import Loader from "@/components/common/Loader";
import InputSelect from "@/components/inputs/InputSelect";

export default {
	name: 'BindReqClientModal',
	props: {
		reqId: {type: Number, defsault: 0},
        objectId: {type: Number, default: 0},
	},
	data() {
		return {
            funnel_id: 0,
			funnels_list: (typeof this.funnelsList !== "undefined") ? this.funnelsList : [],
			is_object_req: false,
            loadet: false,
		};
	},
	components: {Loader, InputSelect },
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
        hideModal() {

            let modalEl = this.$refs.bindReqClientModal;
			let modal = new bootstrap.Modal(modalEl);
            modal.hide();

        },
        showModal() {
            let modalEl = this.$refs.bindReqClientModal;
			let modal = new bootstrap.Modal(modalEl);
            modal.show();

        },

        getFunnels() {
            var section = 'requisitions';
			if (this.$store.clients_funnels)
				return this.$store.clients_funnels;
			else
				return api.get('common/funnels', {
					params: {
						section: section,
						use_counters: true,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getFunnels()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						return list;
					} else {
						return {};
					}
				}).catch(function (error) {

					CommonService.log('error', 'getFunnels()::axios', error);

				});
		},

        getReqObject(){
            
            var object_id = 0;
            if(this.$props.objectId > 0){
                object_id = parseInt(this.$props.objectId);
            }
            console.log(object_id);
            if(object_id > 0){
                return api.get('requisition/check_object', {
                        params: {
                            object_id: this.$props.objectId,
                            req_id: this.$props.reqId,
                        }
                    })
                    .then((response) => {
						if (response.status == 200 && response.data.success) {
							if(response.data.res){
								var res = response.data.res;
								if(res.result === 'error'){
									this.is_object_req = true
								} else {
									this.is_object_req = false;
								}
							}
						}
						this.loadet = true;
                    }).catch(function (error) {

                        CommonService.log('error', 'getReqObject()::axios', error);

                    });
            }
				else {
					this.loadet = true;
				}
			},

			//Создание заявки
			create(){

				//console.log(this.funnel_id);
				this.$emit('saveBind', this.funnel_id);
				
				let modal = CommonService.getModal('bindReqClientModalAddObject');
				
							if (modal)
								modal.hide();
			},

			//прикркпление заявки
			attach(){
				this.$emit('attachReqBind');
				let modal = CommonService.getModal('bindReqClientModalAddObject');
				
							if (modal)
								modal.hide();
			}
    	},
	watch: {

	},
	mounted() {
		var _this = this;
		var modal = this.$refs.bindReqClientModal;
		modal.addEventListener('hidden.bs.modal', function (event) {
			
			_this.emitter.emit("global.modalClose", modal);
		});
		modal.addEventListener('show.bs.modal', function (event) {
			_this.loadet = false;
			_this.funnel_id = 0;
			let target = event.relatedTarget;
            _this.getReqObject();

			/*let lat = target.getAttribute('data-bs-lat');
			let lng = target.getAttribute('data-bs-lng');*/

			/*setTimeout(() => {
				_this.coords = [parseFloat(lat), parseFloat(lng)];
			}, 600);*/

			CommonService.log('debug', 'bindReqClientModal::show.bs.modal', _this.coords);

			_this.emitter.emit("global.modalShown", modal);
		});

        this.getFunnels().then(data => { this.funnels_list = data });
        

	},
	computed: {
		map_coords_ready() {
			return !this.isEmpty(this.coords);
		},
	}
}
</script>