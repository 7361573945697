<template>
    <div v-if="this.item.id"
		 :id="!this.isEmpty(this.item.id) ? 'item_' + this.item.id : false"
		 class="card req-item mb-20"
		 :class="{
			'pb-0 border rounded-1': (this.$props.layout == 'default'),
		 	'border-bottom mb-3': (this.$props.layout == 'autosearch'),
		 	'border-brd-primary': (!this.item.is_new && !this.item.is_deleted),
		 	'border-success shadow': (this.item.is_new && !this.item.is_deleted),
		 	//'border-danger shadow': (this.item.is_deleted && !this.item.is_new),
		}">
        <!--header-->
        <div class="card-header pt-3" :class="{ 'border-bottom border-brd-primary': (this.$props.layout == 'default') }">
            <!--header-top-->
            <div class="d-flex align-items-start mb-3">
                <div class="col">
                    <div class="d-flex flex-wrap mb-20">
                        <span class="text-secondary me-20 fs-5"
							  v-text="this.item.created_at" />

                        <span v-if="this.item.type_id && this.typeName" class="text-secondary me-20 fs-5"
							  v-text="this.typeName" />

                        <span v-if="this.item.source_id && !this.isEmpty(this.source)" class="text-secondary me-20 fs-5"
							  v-text="this.source" />

                        <span v-if="this.item.partner_id && !this.isEmpty(this.partner)" class="text-secondary me-20 fs-5"
							  v-text="this.partner" />

                        <span v-if="this.item.id" class="text-secondary me-20 fs-5"
							  @click="this.copyID(this.item.id)"
							  v-text="'ID ' + this.item.id" />

                    </div>

                    <div v-if="this.item.name" class="d-flex align-items-center">
                        <div v-if="this.$props.layout == 'default'" class="form-check">
                            <input class="form-check-input me-10 rounded-1"
								   type="checkbox"
								   :id="'requisition_'+this.item.id"
								   :checked="this.is_checked ? true : false"
								   v-model="this.is_checked" />
						</div>
                        <span class="d-inline-flex fs-3 fw-semi font-semi"
							  for="requisition"
							  style="text-decoration: underline; max-width: 300px; cursor: pointer;"
							  @click="this.isDetailsOpened = !this.isDetailsOpened">
							{{ this.item.name }}
							<span v-if="this.item.is_hot" class="d-inline-flex ms-2 my-auto">
								<img :src="require('@/assets/icons/fire-icon.svg')" alt="HOT" width="24" height="24">
							</span>
						</span>
						<span v-if="this.item.priority == 1"
							  class="d-inline-flex p-2 ms-2 my-auto bg-raspberry rounded-circle" />
						<span v-else-if="this.item.priority == 2"
							  class="d-inline-flex p-2 ms-2 my-auto bg-warning rounded-circle" />
						<span v-else-if="this.item.priority == 3"
							  class="d-inline-flex p-2 ms-2 my-auto bg-ocean rounded-circle" />
						<span v-else-if="this.item.priority == 4"
							  class="d-inline-flex p-2 ms-2 my-auto bg-success rounded-circle" />
                    </div>
                </div>

              <div v-if="this.item.this_user_can_edit_req || this.$store.getters.userInfo.permissions.menu_all_submissions_edit == 1 || this.$store.getters.userInfo.id == this.item.master_work_id">
                <div v-if="this.$props.layout === 'default'" class="d-flex flex-column">
                  <router-link :to="'/edit-requisition?id=' + this.item.id" class="btn">
						<img :src="require('@/assets/icons/options-icon.svg')" alt="Редактировать" width="24" height="24">
					</router-link>
					<button v-if="this.item.is_deleted" type="button"
							class="btn"
							alt="Восстановить заявку"
							data-bs-toggle="modal"
							:data-bs-target="'#' + this.confirmModalState.fromSection + this.item.id"
							@click="this.fillConfirmModalState('restoreRequisition')">
						<img :src="require('@/assets/icons/back-icon.svg')" alt="Восстановить" width="22" height="22">
					</button>
					<button v-if="!this.item.is_deleted" type="button"
							class="btn"
							data-bs-toggle="modal"
							data-bs-target="#closeClientModal"
							:data-bs-requisition-id="this.item.id"
							title="Закрыть заявку">
						<img :src="require('@/assets/icons/close-icon.svg')" alt="Закрыть" width="18" height="18">
					</button>
					<button v-else-if="this.item.is_deleted && this.user_can.delete" type="button"
							class="btn"
							title="Удалить заявку"
							@click="this.deleteRequisition()">
						<img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="22" height="22">
					</button>
                </div>
            </div>
            </div>
            <!--end header-top-->

            <!--header-middle-->
			<div class="row align-items-center">
				<!--header-middle-->
<!--        <div v-if="this.item.this_user_can_edit_req" class="col d-flex align-items-center">-->
				<div class="col d-flex align-items-center">
					<div class="d-inline-flex"
						 role="button"
						 data-bs-toggle="modal"
						 data-bs-target="#changeStageModal"
						 :data-bs-requisition-id="this.item.id"
						 :data-bs-object-id="this.item.object_id"
						 :data-bs-funnel-id="this.item.funnel_id">
						<div v-if="this.is_last_stage" class="crm-btn btn border border-brd-primary icon icon-color-secondary bg-light-gray rounded-3 me-10">
							<img :src="require('@/assets/icons/back-icon.svg')" alt="Этап воронки" width="20" height="20" />
						</div>
						<div v-else class="crm-btn btn border border-brd-primary bg-light-gray rounded-3 me-10">
							<img :src="require('@/assets/icons/btn-arrow-icon.svg')" alt="Этап воронки" width="20" height="20" />
						</div>
						<div class="d-flex flex-column justify-content-center">
							<span class="text-font-secondary pt-1 fs-4">Этап воронки:</span>
							<span v-if="!this.isEmpty(this.item.stage_name)"
								  class="link border-0"
								  :class="(this.is_last_stage) ? 'text-danger' : ''"
								  :data-stage-id="this.item.stage_id">
								{{ this.item.stage_name }}
							</span>
							<span v-else class="link border-0"
								  :data-stage-id="this.item.stage_id">
								Новый клиент
							</span>
						</div>
					</div>
				</div>
				<!--end header-middle-->

				<!--header-bottom-->
				<div class="col d-flex flex-row flex-fill mt-3" :class="{'flex-fill': !this.isEmpty(this.tags_filtered)}">
					<div v-if="!this.isEmpty(this.tags_filtered)"
						 class="d-flex flex-fill align-items-center ms-0 me-auto mw-60">
						<div class="d-flex border bg-light-gray rounded-circle p-2 me-10">
							<img :src="require('@/assets/icons/tag-icon.svg')" alt="Теги" width="12" height="12">
						</div>
						<ul class="d-inline-flex align-items-center flex-nowrap text-nowrap-mask mb-0 px-0 pe-4">
							<li class="fs-5 text-ocean"
								v-for="(tag, index) of this.tags_filtered"
								:key="tag.id">
								<span :data-tag-id="tag.id" v-text="(index > 0) ? ', ' + tag.name : tag.name"/>
							</li>
						</ul>
					</div>
					<div v-if="this.item.is_confirm" class="d-flex flex-shrink-0 ms-auto me-0">
						<a href="javascript:{}" class="btn d-flex align-items-center p-0"
						   data-bs-toggle="modal"
						   data-bs-section="requisitions"
						   :data-bs-requisition-id="this.item.id"
						   :data-bs-tags-list="JSON.stringify(this.tags)"
						   data-bs-target="#setTagsModal">
							<span
								class="d-flex flex-shrink-0 align-items-center justify-content-center bg-primary rounded-circle me-1"
								style="width:1.875rem; height:1.875rem;">
								<img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="10"
									 height="10">
							</span>
							<span class="text-font-dark fs-5 ps-2">Добавить тег</span>
						</a>
					</div>
				</div>
				<!--end header-bottom-->
			</div>
            <!--end header-middle-->
        </div>

        <!--content-->
        <div class="card-body py-20 border-bottom border-brd-primary" v-show="this.isDetailsOpened && (((this.layout == 'default' || this.item.user_id == this.$store.getters.userInfo.id) || (this.item.autosearch_id && (this.item.type_id == 1 || this.item.heir_type_id == 1) && this.layout == 'default')) || !this.isEmpty(this.clientInfo) || !this.isEmpty(this.item.object))">
            <div v-if="!this.isEmpty(this.clientInfo) || !this.isEmpty(this.item.object)" class="d-flex flex-column mb-20">
<!--                <ul v-if="this.item.this_user_can_edit_req" class="d-flex flex-column px-0">-->
                <ul class="d-flex flex-column px-0">
                    <li v-if="this.item.client_id && !this.isEmpty(this.clientInfo.phone)"
						class="d-flex flex-column mb-3">
                        <span class="fs-4 text-font-secondary mb-0">Телефон</span>
                        <a :href="'tel:' + this.clientInfo.phone"
						   class="link d-inline me-auto"
						   v-text="this.clientInfo.phone" />
                    </li>
                    <li v-if="this.item.client_id && !this.isEmpty(this.clientInfo.email)"
						class="d-flex flex-column mb-3">
                        <span class="fs-4 text-font-secondary mb-0">Почта</span>
						<a :href="'mailto:' + this.clientInfo.email"
						   class="link d-inline me-auto"
						   v-text="this.clientInfo.email" />
                    </li>
                    <li v-if="!this.isEmpty(this.item.object)"
						class="d-flex flex-column mb-3">
                        <span class="fs-4 text-font-secondary mb-0">Объекты</span>
                        <ul class="d-flex flex-wrap px-0" style="max-width: 80%;">
                            <li v-for="(object, index) in this.item.object"
								:key="index"
								class="me-20">
                                <span class="crm-underline">{{ object.toString() }}</span>
                            </li>
                        </ul>
                    </li>
                    <li v-else-if="!this.isEmpty(this.objectInfo) && this.item.object_id"
						class="d-flex flex-column mb-3">
                        <span class="fs-4 text-font-secondary mb-0">Объект</span>
                        <a href="javascript:{}"
						   class="link d-inline me-auto"
						   data-bs-toggle="modal"
						   data-bs-target="#clientEditModal"
						   :data-bs-client-id="this.item.client_id"
						   :data-bs-object-id="this.item.object_id"
						   v-text="this.objectInfo.title" />
                    </li>
                    <li v-if="!this.isEmpty(this.clientInfo) && this.item.client_id" class="d-flex flex-column mb-3">
                        <span class="fs-4 text-font-secondary mb-0">Клиент</span>
                        <span>
							<router-link :to="'/edit-client?id=' + this.item.client_id"
										 class="link d-inline me-auto">
								{{ this.clientInfo.name }}
							</router-link>
							<a v-if="(this.layout == 'default' || this.item.user_id == this.$store.getters.userInfo.id)"
							   href="javascript:{}"
							   class="btn btn-sm btn-light-gray ms-2 border rounded-1"
							   title="Задачи по клиенту"
							   data-bs-toggle="modal"
							   data-bs-target="#historyTasksModal"
							   data-bs-section="clients"
							   :data-bs-client-id="this.item.client_id">
								<img :src="require('@/assets/icons/checklist.svg')" alt="Задачи" width="20" height="20" style="margin-top: -2px">
							</a>
						</span>
                    </li>
					<li v-if="!this.isEmpty(this.item.contracts) && !this.isEmpty(this.contract_types)"
						class="d-flex flex-column mb-3">
						<span class="fs-4 text-font-secondary mb-0">Договоры</span>
						<ul v-if="!this.isEmpty(this.item.contracts)"
							class="list-unstyled d-flex flex-column">
							<li v-for="contract in this.item.contracts" :key="contract.id"
								class="d-inline-block mb-2">
								<a href="javascript:{}"
								   class="link fs-4"
								   data-bs-toggle="modal"
								   data-bs-target="#addEditContractModal"
								   :data-bs-contract-id="contract.id"
								   :data-bs-source-id="item.id"
								   :data-bs-section="'requisitions'">
									<img :src="require('@/assets/icons/pen-icon.svg')" alt="Edit" class="d-inline-flex ms-0 me-1" width="14" height="14" style="margin-top: 1px;">
									{{ contract.name }}
								</a>
								<sup v-show="contract.expired" class="ms-1 text-danger">[просрочен]</sup>
							</li>
						</ul>
					</li>
                </ul>
				<ul v-if="this.layout == 'autosearch'" class="d-flex flex-column px-0">
					<li>
						<div class="d-flex mt-1">
							<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка доступа" class="me-2" style="margin-top: 2px;" width="16" height="16">
							<span class="text-dark-gray fs-4 pt-1">
									<span class="text-danger">Информация скрыта</span><br/>
									Вы можете сообщить Вашему коллеге название или ID этой заявки ({{ this.item.id }}).
								</span>
						</div>
					</li>
				</ul>
            </div>
            <div v-if="(this.layout == 'default' || this.item.user_id == this.$store.getters.userInfo.id) || (this.item.autosearch_id && (this.item.type_id == 1 || this.item.heir_type_id == 1) && this.layout == 'default')" class="d-flex align-items-center">
                <button v-if="this.layout == 'default' || this.item.user_id == this.$store.getters.userInfo.id"
						type="button"
						class="btn bg-light-gray text-secondary border border-brd-primary rounded-1 font-semi fw-semi position-relative me-20"
						data-bs-toggle="modal"
						data-bs-target="#historyTasksModal"
						data-bs-section="requisitions"
						:data-bs-requisition-id="this.item.id"
						:data-bs-client-id="this.item.client_id"
						:data-bs-object-id="this.item.object_id">
                    История и задачи
					<span class="badges-group">
						<span v-if="!this.isEmpty(this.item.events_count.requisitions.expired)"
							  class="badge bg-danger"
							  style="width: 26px; height: 26px;">
							{{ this.item.events_count.requisitions.expired }}
							<span class="visually-hidden">Просроченные задачи</span>
						</span>
						<span v-if="!this.isEmpty(this.item.events_count.requisitions.today)"
							  class="badge bg-warning"
							  style="width: 26px; height: 26px;">
							{{ this.item.events_count.requisitions.today }}
							<span class="visually-hidden">Задачи на сегодня</span>
						</span>
						<span v-if="!this.isEmpty(this.item.events_count.requisitions.total)"
							  class="badge bg-success"
							  style="width: 26px; height: 26px;">
							{{ this.item.events_count.requisitions.total }}
							<span class="visually-hidden">Непрочитанные задачи</span>
						</span>
					</span>
                </button>
                <button v-if="this.item.autosearch_id && !this.isEmpty(this.item.autosearch.active) && (this.item.type_id == 1 || this.item.heir_type_id == 1) && this.layout == 'default'"
						type="button"
						class="btn bg-light-gray text-secondary border border-brd-primary rounded-1 font-semi fw-semi position-relative"
						data-bs-toggle="modal"
						data-bs-target="#autoSearchResultModal"
						:disabled="this.isEmpty(this.item.autosearch.active)"
						:data-bs-asfilter-id="this.item.autosearch_id">
					Автопоиск
					<span v-show="!this.isEmpty(this.item.autosearch.active) && this.isEmpty(this.item.autosearch.count) && this.isEmpty(this.item.autosearch.error)" class="badge badge-loader rounded-circle"
						  v-text="' '" />
					<span v-if="!this.isEmpty(this.item.autosearch.active) && !this.isEmpty(this.item.autosearch.count) || !this.isEmpty(this.item.autosearch.error)" class="badges-group">
						<span v-if="!this.isEmpty(this.item.autosearch.count)"
							  class="badge bg-success px-2"
							  style="min-width: 26px; height: 26px;"
							  :style="(this.isEmpty(this.item.autosearch.error)) ? 'border-radius: 14px;' : 'border-top-left-radius: 14px; border-bottom-left-radius: 14px;'">
							+{{ this.item.autosearch.count }}
							<span class="visually-hidden">Новых объектов</span>
						</span>
						<span v-if="!this.isEmpty(this.item.autosearch.error)"
							  class="badge bg-warning icon-color-white"
							  style="width: 26px; height: 26px;">
							<img :src="require('@/assets/icons/warning.svg')" alt="Warning" class="d-inline-flex m-auto" width="14" height="14" />
							<span class="visually-hidden">{{ this.item.autosearch.error }}</span>
						</span>
					</span>
                </button>
			</div>
        </div>
		<div>
			
			<button style="width:100%" v-if="this.layout == 'bind_req'" @click="checkReqBind(this.item)"
						type="button" class="btn btn-primary fw-bold rounded-3 text-white fs-3">Выбрать
            </button>
		</div>

        <!--footer-->
        <div v-if="(this.layout == 'default' || this.layout == 'autosearch')" class="card-footer">
            <div class="d-flex flex-column pt-10 pb-2">
                <span v-if="this.item.manager"
					  class="text-font-light fs-5"
					  v-text="'Добавил ' + this.item.manager + ', ' + this.item.manager_phone" />

                <span v-if="this.item.master_work"
					  class="text-font-light fs-5"
					  v-text="'В работе у ' + this.item.master_work + ', ' + this.item.master_work_phone" />

                <span v-if="this.item.master_delete"
					  class="text-font-light fs-5"
					  v-text="'Удалил ' + this.item.master_delete + ', ' + this.item.master_delete_phone" />

                <span v-if="!this.isEmpty(this.item.requested_doers)"
					  class="text-font-light fs-5"
					  v-text="'Приглашен к совместной работе' + ((this.item.requested_doers_ids.length > 1) ? ': ' : ' ') + this.item.requested_doers" />

                <span v-if="!this.isEmpty(this.item.accepted_doers)"
					  class="text-font-light fs-5"
					  v-text="'В совместной работе с' + ((this.item.accepted_doers_ids.length > 1) ? ': ' : ' ') + this.item.accepted_doers" />

			</div>
			<div v-if="this.isDetailsOpened && !this.isEmpty(this.item.description) && this.item.this_user_can_edit_req"
				 class="d-flex flex-column pt-20 border-top border-brd-primary pb-20">
				<span class="mb-1 fs-5 text-font-secondary">Описание</span>
				<span>{{ this.item.description }}</span>
			</div>
			<div v-if="this.isDetailsOpened && !this.isEmpty(this.item.close_reason) && (this.item.is_deleted || this.item.is_completed || this.item.is_canceled)"
				 class="d-flex flex-column pt-20 border-top border-brd-primary pb-20">
				<span class="mb-1 fs-5 text-font-secondary">Причина закрытия</span>
				<span>{{ this.item.close_reason }}</span>
			</div>
			<div v-if="!this.item.is_confirm && (this.item.employee_id != this.$store.getters.userInfo.id || this.isEmpty(this.item.employee_id))" class="d-flex flex-column pt-3 border-top border-light">
				<span class="d-inline-flex mb-3 align-self-center">Взять заявку в работу?</span>
				<ul class="nav justify-content-center mb-3">
					<li class="nav-item px-2">
						<button class="nav-item btn btn-warning rounded-3 px-4"
								@click="this.fillConfirmModalState('cancelTranfer')">Нет</button>
					</li>
					<li class="nav-item px-2">
						<button class="nav-item btn btn-success text-white rounded-3 px-4"
								@click="this.acceptTransfer()">Да</button>
					</li>
				</ul>
			</div>
			<div v-else-if="!this.item.is_confirm && !this.isEmpty(this.item.employee_id)" class="d-flex flex-column pt-3 border-top border-light">
				<span class="d-inline-flex mb-3 align-self-center">Вы передали эту заявку.</span>
				<ul class="nav justify-content-center mb-3">
					<li class="nav-item px-2">
						<button class="nav-item btn btn-danger text-white rounded-3 px-4"
								@click="this.fillConfirmModalState('cancelTranfer')">Отменить передачу</button>
					</li>
				</ul>
			</div>
        </div>

        <div v-if="(this.layout == 'default' || this.layout == 'autosearch') && this.isDetailsOpened && this.item.autosearch.is_search && !this.isEmpty(this.item.autosearch_id)" class="card-footer">
            <div class="d-flex flex-column">
				<div :data-autosearch-id="this.item.autosearch_id">
					<span class="text-font-light fs-5 mb-3">Параметры поиска объектов</span>
					<ul class=" d-flex flex-wrap m-0 p-0 mb-10">
						<li v-for="(filter, field, index) in this.item.autosearch.filter"
							:key="index"
							class="d-inline-flex py-1 me-10">
							<span class="font-semi fw-semi fs-4" :data-field="field">
								{{ filter.label }}: <span class="text-font-secondary fw-normal">{{ filter.value }}</span>
							</span>
						</li>
						<li class="d-inline-flex py-1 me-10">
							<span class="font-semi fw-semi fs-4">
								ID фильтра: <span class="text-font-secondary fw-normal">{{ this.item.autosearch_id }}</span>
							</span>
						</li>
						<li class="d-inline-flex py-1 me-10">
							<span class="font-semi fw-semi fs-4">
								Автопоиск:
								<span v-if="(this.item.autosearch.active)" class="text-success">вкл.</span>
								<span v-else class="text-danger">откл.</span>
							</span>
						</li>
						<li class="d-inline-flex py-1 me-10">
							<span class="font-semi fw-semi fs-4">
								Уведомления:
								<span v-if="(this.item.autosearch.notify)" class="text-success">вкл.</span>
								<span v-else class="text-danger">откл.</span>
							</span>
						</li>
					</ul>
				</div>
                <div v-if="this.user_can.autosearch.edit" class="d-flex align-items-center mb-0">
                    <button type="button"
							class="btn px-0 me-10"
							data-bs-toggle="modal"
							data-bs-target="#autoSearchEditModal"
							:data-bs-asfilter-id="this.item.autosearch_id">
						<img :src="require('@/assets/icons/options-icon.svg')" alt="Редактировать" width="24" height="24">
						<span class="ms-10 fs-4 text-font-secondary">Редактировать автопоиск</span>
					</button>
                </div>
            </div>
        </div>
    </div>

	<ConfirmModal :id="this.item.id"
				  :title="this.confirmModalState.title"
				  :text="this.confirmModalState.text"
				  :recipient="this.confirmModalState.recipient"
				  :fromSection="this.confirmModalState.fromSection"
				  :action="this.confirmModalState.action"
				  :btnText="this.confirmModalState.btnText"
				  @confirmed="(data) => {
					  if (data.action == 'restoreRequisition') {
						this.initRestoreRequisition(data.id);
					  } else if (data.action == 'cancelTransfer') {
						this.cancelTransfer(data.id);
					  }
				  }" />
</template>

<script>
	import api from "@/api";
	import CommonService from "@/services/CommonService";
	import {CommonDataService} from "@/services/CommonDataService";
	import {copyText} from "vue3-clipboard";
	import ConfirmModal from "@/components/modals/ConfirmModal";

    export default {
        name: 'RequestCard',
        props: {
			requisitionItem: { type: Object },
            layout: { type: String, default: 'default' },
            isChecked: { type: Boolean },
			tagsList: { type: Array, default: () => [] },
			objectsList: {type: Array, default: () => [] },
			clientsList: {type: Array, default: () => [] },
			sourcesList: { type: Array, default: () => [] },
			partnersList: { type: Array, default: () => [] },
			contractTypes: { type: Array, default: () => [] },
			funnelsList: { type: Array, default: () => [] },
			typesList: { type: Array, default: () => [] },
        },
        components: {
			ConfirmModal

        },
        data() {
            return {
                isDetailsOpened: false,
				confirmModalState: {
					title: '',
					text: '',
					recipient: '',
					fromSection: 'clientCard',
					action: null,
					btnText: ''
				},
                isObjectExist: false,
				//item: (typeof this.requisitionItem !== "undefined") ? this.requisitionItem : {},
				sources: [],
				partners: [],
            }
        },
		methods: CommonService.mergeRecursive({
			copyID(requisition_id) {
				copyText(requisition_id, undefined, (error, event) => {
					if (error) {
						console.error('Не удалось скопировать ID заявки: ', error);
						alert('Не удалось скопировать ID заявки.');
					} else {
						alert('ID заявки скопирован!');
						console.log(event);
					}
				});
			},
			checkReqBind(requisition){
				this.$emit('checkReqBind', requisition);
			},
			formatPhone(value) {
				return CommonService.formatPhoneNumber(value);
			},
			formatDateTime(datetime, format) {
				if (typeof format !== 'undefined')
					return CommonService.formatDateTime(datetime, 'ru', format);
				else
					return CommonService.formatDateTime(datetime, 'ru');
			},
			alertNote(message) {
				return alert(message);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			formatDate(format, datetime) {
				return CommonService.formatDateTime(datetime, 'ru', format);
			},
			formatPrice(value, currency, sign) {
				return CommonService.formatPrice(value, currency, sign);
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			buildTagsList(tags, tags_list) {
				let list = CommonService.resetProxy(tags_list);
				if (tags && list) {
					list.find((item, i) => {
						if (tags.indexOf(item.id) !== -1) {
							list[i].isChecked = true;
						} else {
							list[i].isChecked = false;
						}
					});
				}

				return list;
			},
			getSources() { // @todo: need to remove, implement in `CommonDataService`

				let _this = this;
				return api.get('/common/sources', {
					params: {}
				})
				.then((response) => {

					CommonService.log('debug', 'getSources()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, source]) => {
								list.push({
									value: source.id.toString(),
									name: source.name
								});
							});
						}

						CommonService.log('info', 'RequestCard::getSources()', list);

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setSourcesList', list);

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getSources()::axios', error);

				});
			},
			getTypes() {
				if (this.$store.req_types)
					return this.$store.req_types;
				else
					return api.get('/common/types', {
						params: {
							section: 'requisitions'
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});
							this.$store.commit('setReqTypes', list);
							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getTypes()::axios', error);

					});
			},
			getPartners() {
				return api.get('/partners/list', {
					params: {
						filters: {
							only_active: 1,
							with_managers: 1
						}
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getPartners()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							let groups = (response.data.groups) ? response.data.groups : [];
							Object.entries(response.data.list).forEach(([key, partner]) => {
								let group_id = partner.group_id ?? null;
								list.push({
									value: partner.id.toString(),
									name: partner.name,
									company: (!this.isEmpty(groups[group_id])) ? groups[group_id] : null,
								});
							});
						}

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getPartners()::axios', error);

				});
			},
			getFunnels() {
				if (this.$store.req_funnels)
					return this.$store.req_funnels;
				else
					return api.get('requisitions/funnels', {
						params: {
							section: 'requisitions'
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getFunnels()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push(option);
							});
							this.$store.commit('setReqFunnels', list);
							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getFunnels()::axios', error);

					});
			},
			getObject(object_id) {

				this.emitter.emit("global.startGetObject", { ref: 'RequestCard::getObject()', object_id });

				return api.get('/object', {
					params: {
						object_id: object_id,
					}
				})
					.then((response) => {

						CommonService.log('debug', 'getObject()::axios', {response: response.data});

						this.emitter.emit("global.endGetObject", { response: response });

						if (response.status == 200 && response.data.success)
							return response.data.item;

						return null;
					}).catch(function (error) {

						CommonService.log('error', 'getObject()::axios', error);
						this.emitter.emit("global.endGetObject", { error: error });

					});
			},
			getContractType(type_id) {

				let contract_type = false;
				if (typeof type_id !== "undefined" && !this.isEmpty(this.contract_types)) {
					this.contract_types.forEach((type) => {
						if (type.value == type_id)
							contract_type = type.name;
					});
				}

				return contract_type;
			},
			fillConfirmModalState(str) {
				if (str === 'restoreRequisition') {
					this.confirmModalState.title = "Восстановление заявки";
					this.confirmModalState.text = "Вы действительно хотите восстановить заявку?";
					this.confirmModalState.action = 'restoreClient';
					this.confirmModalState.btnText = "Подтвердить";
				} else if (str === 'cancelTransfer') {
					this.confirmModalState.title = "Отмена передачи";
					this.confirmModalState.text = "Вы действительно хотите отменить передачу заявки?";
					this.confirmModalState.action = 'cancelTransfer';
					this.confirmModalState.btnText = "Подтвердить";
				}
			},
			acceptTransfer() {
				api.put('/requisitions/transfer', {
					requisitions_ids: [this.item.id],
				}).then((response) => {

					CommonService.log('debug', 'acceptTransfer()::axios', {response});

					this.$emit('updateRequisitions');
				}).catch(function (error) {

					CommonService.log('error', 'acceptTransfer()::axios', error);

				});
			},
			deleteRequisition() {
				if (confirm('Вы точно хотите безвозвратно удалить эту заявку?')) {
					api.delete('/requisition', {
						params: {
							requisition_id: this.item.id
						}
					}).then((response) => {

						CommonService.log('debug', 'deleteRequisition()::axios', {response});

						this.$emit('updateRequisitions');
					}).catch(function (error) {

						CommonService.log('error', 'deleteRequisition()::axios', error);

					});
				}
			},
			initRestoreRequisition(requisition_id) {
				if (requisition_id && confirm('Вы уверены, что желаете восстановить данную заявку?')) {
					let requisitions_ids = [];
					requisitions_ids.push(requisition_id);
					api.post('/requisitions/restore', {
						requisitions_ids: requisitions_ids
					}).then((response) => {

						CommonService.log('debug', 'initRestoreRequisition()::axios', {response});

						if (response.status == 200 && response.data.success) {
							this.$emit('initRestoreRequisition', {requisitions_ids: requisitions_ids});
							this.$emit('updateRequisitions');
						}
					}).catch(function (error) {

						CommonService.log('error', 'initRestoreRequisition()::axios', error);

					});
				}
			},
			async getClient(client_id) {
				return api.get('/client', {
					params: {
						client_id: client_id,
					}
				})
					.then((response) => {

						CommonService.log('debug', 'getClient()::axios', {response: response.data});

						if (response.status == 200 && response.data.success)
							return response.data.item;

						return null;
					}).catch(function (error) {

						CommonService.log('error', 'getClient()::axios', error);

					});
			},
		}, CommonDataService.methods),
		mounted() {

			console.debug('mounted', this.$props);

			if (CommonService.isEmpty(this.$props.typesList) && typeof this.item.type_id !== "undefined") {
				this.getTypes().then(data => {
					this.types = data;
				});
			}

			if (CommonService.isEmpty(this.$props.sourcesList) && typeof this.item.source_id !== "undefined") {
				this.getSources().then(data => {
					this.sources = data;
				});
			}

			if (CommonService.isEmpty(this.$props.partnersList) && typeof this.item.partner_id !== "undefined") {
				this.getPartners().then(data => {
					this.partners = data;
				});
			}

			if (CommonService.isEmpty(this.$props.funnelsList) && typeof this.item.funnel_id !== "undefined") {
				this.getFunnels().then(data => {
					this.funnels = data;
				});
			}

			/*if (CommonService.isEmpty(this.$props.objectsList) && !CommonService.isEmpty(this.item.object_id)) {
				this.getObject(this.item.object_id).then((item) => {
					this.objects.push(item);
				});
			}*/

			/*if (CommonService.isEmpty(this.$props.clientsList) && !CommonService.isEmpty(this.item.client_id)) {
				this.getClient(this.item.client_id).then((item) => {
					this.clients.push(item);
				});
			}*/

			this.emitter.on('autoSearchFilterAdded', (filter_id) => {

				if (!this.isEmpty(filter_id))
					this.item.autosearch_id = filter_id;

				if (process.env.NODE_ENV == "development") {
					console.debug('Fire `autoSearchFilterAdded`', {
						filter_id: filter_id,
						autosearch_id: this.item.autosearch_id
					});
				}
			});
		},
		watch: {
			item: function (value, old_value) {
				console.info(value);
			},
			user_can: function (value, old_value) {
				console.info(value);
			},
		},
		computed: {
			item() {
				return this.$props.requisitionItem ?? {
					id: null,
					name: '',
					autosearch: {},
					autosearch_id: null,
					description: '',
					who_work: null,
					is_deleted: null,
					is_completed: null,
					is_canceled: null,
					close_reason: null,
					is_can_see: false,
					is_can_see_other: false,
					is_can_edit: false,
				};
			},
			is_checked: {
				get() {
					return this.$props.isChecked ?? this.value;
				},
				set(value) {
					this.$emit("setItemChecked", { item_id: this.item.id, checked: value });
				},
			},
			objects: {
				get() {
					return this.$props.objectsList || [];
				},
				set(value) {
					value = !this.isEmpty(value) ? value : [];
				}
			},
			clients: {
				get() {
					return this.$props.clientsList || [];
				},
				set(value) {
					value = !this.isEmpty(value) ? value : [];
				}
			},
			funnels: {
				get() {
					return this.$props.funnelsList || [];
				},
				set(value) {
					value = !this.isEmpty(value) ? value : [];
				}
			},
			types: {
				get() {
					return this.$props.typesList || [];
				},
				set(value) {
					value = !this.isEmpty(value) ? value : [];
				}
			},
			typeName() {
				if (!CommonService.isEmpty(this.types)) {

					let type = this.types.filter(item => item.value == this.item.type_id);
					if (type.length)
						return type[0].name;

				}

				return false;
			},

			source() {

				let sources = this.$props.sourcesList ?? {};
				if (!this.isEmpty(sources)) {

					let source = sources.filter((source) => source.value == this.item.source_id).map((source) => {
						return source.name;
					});

					if (!this.isEmpty(source))
						return source.toString();

				}

				return null;
			},
			partner() {

				let partners = this.$props.partnersList ?? {};
				if (!this.isEmpty(partners)) {

					let partner = partners.filter((partner) => partner.value == this.item.partner_id).map((partner) => {
						return partner.company;
					});

					if (!this.isEmpty(partner))
						return partner.toString();

				}

				return null;
			},

			funnelName() {
				if (!CommonService.isEmpty(this.funnels) && typeof this.item.funnel_id !== "undefined") {

					let funnel = this.funnels.filter(item => item.value == this.item.funnel_id);
					if (funnel)
						return funnel[0].name;

				}

				return false;
			},
			tags_list() {

				let list = this.$props.tagsList ?? {};
				if (!this.isEmpty(list))
					return list;

				return {};
			},
			tags() {
				let tags = null;
				let selected = this.item.tags;

				if (!CommonService.isEmpty(selected))
					tags = this.buildTagsList(selected, this.tags_list);
				else
					tags = this.tags_list;

				return tags;
			},
			tags_filtered() {

				console.info(this.tags == 'undefined');

				if (!CommonService.isEmpty(this.tags))
					return this.tags.filter(tag => tag.isChecked);

				return {};
			},
			contract_types() {
				return this.$props.contractTypes ?? {};
			},
			user_can() {
				return {
					delete: this.$store.getters.userInfo.user_can.delete_requisition,
					view: (this.item.user_id == this.$store.getters.userInfo.id) || (this.item.is_can_see && this.item.is_can_edit),
					view_other: (this.item.user_id == this.$store.getters.userInfo.id) || (this.item.is_can_see_other && this.item.is_can_edit),
					autosearch: {
						edit: this.$store.getters.userInfo.user_can.autosearch && (this.layout == 'default') && (this.item.user_id == this.$store.getters.userInfo.id) && (this.item.type_id == 1 || this.item.heir_type_id == 1),
						view: this.item.autosearch_id && (this.item.type_id == 1 || this.item.heir_type_id == 1),
					}
				};
			},
			stageName() {
				if (!CommonService.isEmpty(this.funnels) && typeof this.item.funnel_id !== "undefined" && typeof this.item.stage !== "undefined") {

					let funnel = this.funnels.filter(item => item.value == this.item.funnel_id);

					let step_or_stage = this.item.stage;
					if (this.item.funnel_id !== 0)
						step_or_stage = this.item.step_id;

					return funnel[0].stages[step_or_stage].name;
				}

				return false;
			},
			funnelStep() {
				return (this.funnelName && !this.stageName) ?
					this.funnelName : (
						(this.funnelName && this.stageName) ?
							this.funnelName + ' / ' + this.stageName : ''
					);
			},
			objectInfo() {
				let object = this.objects.filter(object => object.id == this.item.object_id);
				if (!CommonService.isEmpty(object[0]))
					return object[0];
				else
					return false;
			},
			clientInfo() {
				let client = this.clients.filter(client => client.id == this.item.client_id);
				if (!CommonService.isEmpty(client[0]))
					return client[0];
				else
					return false;
			},
			userInfo() {
				return this.$store.getters.userInfo();
			},
		},
	}
</script>

<style lang="scss">
    $gray-100: #F5F5F5;
    
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: $gray-100;
        opacity: 0.7;
    }

    .tag-menu-container {
        position: absolute;
        top: calc(100% + 16px);
        left: calc(-100% - 30px);
        z-index: 100;
        width: calc(200% + 50px);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    }

    .tag-menu-caret {
        position: absolute;
        top: -8px;
        left: calc(50% + 11px);
        z-index: -1;
        transform: rotate(-90deg);
    }
</style>