<template>
    <div class="crm d-flex flex-column" style="height: 100%; min-height: 100vh;">
        <MainHeader ref="header" />
		<Loader v-show="this.isRefreshing"
				ref="loader"
				className="page-loader flex-column flex-fill" />

			<main class="container px-0"
				 ref="main">
				<div class="row-fluid">
					<div class="col">
						<router-view class="transition" />
					</div>
				</div>
			</main>
		<EventsList v-if="!this.inArray(this.$route.name, ['login', 'register', 'restore'])" />
        <Footer/>
    </div>
</template>

<script>
import MainHeader from '../components/common/MainHeader'
import EventsList from "@/components/common/EventsList";
import Footer from '../components/common/Footer'
import Loader from "@/components/common/Loader";
import CommonService from "@/services/CommonService";
export default {
    name: "MainLayout",
	data() {
		return {
			isRefreshing: false
		}
	},
	methods: {
		afterEnter: () => {
			window.scrollTo(0, 0);
		},
		afterLeave: () => {
			this.$store.commit("setPageTransition", "default");
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},

	},
	mounted() {

		let header = this.$refs.header;
		if (header) {

			let header_height = this.$refs.header.$el.clientHeight ?? 0;
			if (header_height)
				this.$refs.main.style.marginTop = header_height + 'px';

			
		}
	},
	components: {
		Loader,
        MainHeader,
		EventsList,
        Footer
    },
	computed: {
		transition_name () {
			return this.$store.getters.pageTransition.name;
		},
		transition_mode () {
			return this.$store.getters.pageTransition.mode;
		},
		is_beta_tester() {
			return true
			// let user_id = parseInt(this.$store.getters.userInfo.id);
			// let beta_testers = this.$root.config.beta_testers_ids;
			// return this.inArray(user_id, beta_testers);
		},
	}
};
</script>

<style lang="scss">

$duration: 0.5s;
.transition {
	overflow: hidden;
}
.router-view,
.router-view-back {
	&-enter-active,
	&-leave-active {
		position: fixed;
		width: 100%;
		background: white;
		min-height: 100vh;
		top: 0;
	}
}

// router view
.router-view-enter-active {
	transition: transform $duration ease-in-out;
	z-index: 2;
	transform: translateX(100%);
}
.router-view-enter-to {
	z-index: 2;
	transform: translateX(0%);
}
.router-view-leave-active {
	z-index: -1;
}
.router-view-leave-to {
	z-index: -1;
}

// router view back
.router-view-back-leave-active {
	transition: transform $duration ease-in-out;
	z-index: 2;
	transform: translateX(0%);
}
.router-view-back-leave-to {
	z-index: 2;
	transform: translateX(100%);
}
</style>