<template>
    <div class="filters position-relative"
		 ref="filters"
		 style="padding-bottom: 0 !important; z-index: 1000;"
		 v-if="this.is_open">

		<!-- Фильтры: клиенты -->
		<div v-if="this.$props.section == 'clients'" class="filter-block">
			<div class="d-flex align-items-start p-20">
				<span class="me-auto fs-2">Фильтр</span>
				<button type="button" class="btn-close ms-auto me-0" @click="this.$emit('closeFilter', true)"></button>
			</div>
			<div v-if="this.options.status" class="">
				<div class="d-flex align-items-start p-20">
					<span class="me-auto fs-2">Статус</span>
				</div>
				<ul class="p-0 m-0">
					<li v-for="(item, value) in this.options.status"
						:key="value"
						class="border-top border-brd-primary p-20 position-relative">
						<a href="javascript:{}"
						   role="button"
						   class="stretched-link fs-3 font-semi fw-semi text-font-secondary"
						   :class="{ 'text-black': (this.$props.filters['status'] == value) }"
						   @click.prevent="this.setupFilter('status', value)">

							<span class="me-10">{{ item.label }}</span>

							<img v-if="item.icon"
								 :src="item.icon"
								 alt="Icon"
								 class="me-10"
								 width="24"
								 height="24">

							<span v-if="item.count" class="badge bg-primary rounded-1">
								<span v-text="item.count"></span>
								<span class="visually-hidden">{{ item.title }}</span>
							</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
        <div v-if="this.$props.section == 'clients'" class="filter-block mb-20">
            <div class="mb-20 border-top border-brd-primary px-20 pt-20">
                <span class="fs-2">Параметры</span>
            </div>

			<div v-if="!this.isEmpty(this.employees_list)" class="mb-3">
				<SelectBox inputId="employees"
						   inputName="employees"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Ответственный"
						   :inputOptions="this.employees_list"
						   :inputValue="this.$props.filters['employees']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('employees', value)" />
			</div>

			<div v-if="!this.isEmpty(this.types_list)" class="mb-3">
				<SelectBox inputId="type"
						   inputName="type"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Тип"
						   :inputOptions="this.types_list"
						   :inputValue="this.$props.filters['type']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('type', value)" />
			</div>

			<div v-if="!this.isEmpty(this.tags_list)" class="mb-3">
				<SelectBox inputId="activities"
						   inputName="activities"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Теги"
						   :inputOptions="this.tags_list"
						   :inputValue="this.$props.filters['activities']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('activities', value)" />
			</div>

			<div v-if="!this.isEmpty(this.estate_types_list)" class="mb-3">
				<SelectBox inputId="estate"
						   inputName="estate"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Тип недвижимости"
						   :inputOptions="this.estate_types_list"
						   :inputValue="this.$props.filters['estate']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('estate', value)" />
			</div>

			<div v-if="!this.isEmpty(this.roles_list)" class="mb-3">
				<SelectBox inputId="roles"
						   inputName="roles"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Роль клиента"
						   :inputOptions="this.roles_list"
						   :inputValue="this.$props.filters['roles']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('roles', value)" />
			</div>

			<div v-if="!this.isEmpty(this.sources_list)" class="mb-3">
				<SelectBox inputId="sources"
						   inputName="sources"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Источник"
						   :inputOptions="this.sources_list"
						   :inputValue="this.$props.filters['sources']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('sources', value)" />
			</div>

			<div v-if="!this.isEmpty(this.partners_list)" class="mb-3">
				<SelectBox inputId="partners"
						   inputName="partners"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Партнёр"
						   :inputOptions="this.partners_list"
						   :inputValue="this.$props.filters['partners']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('partners', value)" />
			</div>

			<div v-if="!this.isEmpty(this.stages_list)" class="mb-3">
				<SelectBox inputId="stages"
						   inputName="stages"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Этап"
						   :inputOptions="this.stages_list"
						   :inputValue="this.$props.filters['stages']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('stages', value)" />	
                
					   
			</div>
			

            <div v-if="!this.isEmpty(this.denial_work_list)" class="mb-3">
				<SelectBox inputId="denials"
						   inputName="denials"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Причина закрытия"
						   :inputOptions="this.denial_work_list"
						   :inputValue="this.$props.filters['denials']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('denials', value)" />
            </div>

			<div v-if="!this.isEmpty(this.periods_list)" class="filter-block mb-3">
				<SelectBox inputId="period"
						   inputName="period"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Время добавления"
						   :inputOptions="this.periods_list"
						   :inputValue="(!this.isEmpty(this.$props.filters['period'])) ? this.$props.filters['period'] : [8]"
						   inputClass="rounded-1 p-2"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('period', value)" />
				<div v-if="(this.$props.filters['period'] == 9) ? true : false" class="row col-12 px-0 mt-2">
					<DateTimePicker inputId="period_start"
									inputName="period_start"
									ref="period_start"
									inputLabel="От:"
									inputLabelClass="col-4 pt-2 text-font-secondary text-center"
									inputGroupClass="col"
									inputClass="rounded-3 p-2"
									inputWrapClass="row px-0 col"
									:inputValue="this.$props.filters['period_start']"
									displayFormat="DD.MM.YYYY"
									@setValue="(value) => this.setupFilter('period_start', value)" />
					<DateTimePicker inputId="period_end"
									inputName="period_end"
									ref="period_end"
									inputLabel="До:"
									inputLabelClass="col-4 pt-2 text-font-secondary text-center"
									inputGroupClass="col"
									inputClass="rounded-3 p-2"
									inputWrapClass="row px-0 col"
									:inputValue="this.$props.filters['period_end']"
									displayFormat="DD.MM.YYYY"
									@setValue="(value) => this.setupFilter('period_end', value)" />
				</div>
			</div>
        </div>
        <div v-if="this.$props.section == 'clients'" class="filter-block mb-20">
            <div class="d-flex flex-column px-20 mb-10">
				<span class="text-font-secondary mb-3">Категория клиента</span>
				<ul class="d-flex check-group flex-wrap align-items-center m-0 p-0">
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority1"
							   id="priority_1"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (1 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.1', event.target.value)">
						<label class="btn btn-raspberry btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_1" />
					</li>
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority2"
							   id="priority_2"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (2 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.2', event.target.value)">
						<label class="btn btn-warning btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_2" />
					</li>
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority3"
							   id="priority_3"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (3 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.3', event.target.value)">
						<label class="btn btn-ocean btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_3" />
					</li>
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority4"
							   id="priority_4"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (4 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.4', event.target.value)">
						<label class="btn btn-primary btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_4" />
					</li>
				</ul>
			</div>
        </div>
        <div v-if="this.$props.section == 'clients'" class="filter-block mb-20">

			<Switcher inputId="is_contract"
					  inputLabel="C договорами"
					  inputName="is_contract"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_contract'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_contract', value)" />

			<div v-if="(!this.isEmpty(this.$props.filters['is_contract'])) ? true : false" class="d-flex flex-column">
				<SelectBox inputId="contract_type"
						   inputName="contract_type"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Тип договора"
						   :inputOptions="this.contract_types"
						   :inputValue="this.$props.filters['contract_type']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="flex-column px-20 mb-3"
						   @setValue="(value) => this.setupFilter('contract_type', value)" />

				<InputText inputId="contract_number"
						   inputName="contract_number"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Номер договора"
						   :inputValue="this.$props.filters['contract_number']"
						   inputClass="rounded-1 p-2"
						   inputWrapClass="flex-column px-20 mb-3"
						   @setValue="(value) => this.setupFilter('contract_number', value)" />

				<div class="d-flex flex-column px-20">
					<div class="row mb-3">
						<div class="col-12 mb-3">
							<span class="text-font-secondary">Период окончания договора</span>
						</div>
						<div class="col-6">
							<DateTimePicker inputId="contract_start"
											inputName="contract_start"
											ref="contract_start"
											inputLabel="От"
											inputLabelClass="col-4 pt-2 text-font-secondary text-center"
											inputGroupClass="col"
											inputClass="rounded-3 p-2"
											inputWrapClass="row"
											:inputValue="this.$props.filters['contract_start']"
											displayFormat="DD.MM.YYYY"
											@setValue="(value) => this.setupFilter('contract_start', value)" />
						</div>
						<div class="col-6">
							<DateTimePicker inputId="contract_end"
											inputName="contract_end"
											ref="contract_end"
											inputLabel="до"
											inputLabelClass="col-4 pt-2 text-font-secondary text-center"
											inputGroupClass="col"
											inputClass="rounded-3 p-2"
											inputWrapClass="row"
											:inputValue="this.$props.filters['contract_end']"
											displayFormat="DD.MM.YYYY"
											@setValue="(value) => this.setupFilter('contract_end', value)" />
						</div>
					</div>
				</div>
			</div>

			<Switcher inputId="is_tasks"
					  inputLabel="С задачами"
					  inputName="is_tasks"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_tasks'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_tasks', value)" />

			<Switcher inputId="is_no_tasks"
					  inputLabel="Клиенты без задач"
					  inputName="is_no_tasks"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_no_tasks'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_no_tasks', value)" />

			<Switcher inputId="is_today_tasks"
					  inputLabel="Задачи на сегодня"
					  inputName="is_today_tasks"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_today_tasks'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_today_tasks', value)" />

			<Switcher inputId="is_over_tasks"
					  inputLabel="С просроченными задачами"
					  inputName="is_over_tasks"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_over_tasks'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_over_tasks', value)" />

			<Switcher inputId="is_partner"
					  inputLabel="Совместные клиенты"
					  inputName="is_partner"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_partner'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_partner', value)" />

        </div>

		<!-- Фильтры: заявки -->
		<div v-if="this.$props.section == 'requisitions'" class="filter-block mb-20">
			<div class="d-flex align-items-start p-20">
				<span class="me-auto fs-2">Фильтр</span>
				<button type="button" class="btn-close ms-auto me-0" @click="this.$emit('closeFilter', true)"></button>
			</div>
			<div v-if="this.options.status" class="">
				<div class="d-flex align-items-start p-20">
					<span class="me-auto fs-2">Статус</span>
				</div>
				<ul class="p-0 m-0">
					<li v-for="(item, value) in this.options.status"
						:key="value"
						class="border-top border-brd-primary p-20 position-relative">
						<a href="javascript:{}"
						   role="button"
						   class="stretched-link fs-3 font-semi fw-semi text-font-secondary"
						   :class="{ 'text-black': (this.$props.filters['status'] == value) }"
						   @click.prevent="this.setupFilter('status', value)">

							<span class="me-10">{{ item.label }}</span>

							<img v-if="item.icon"
								 :src="item.icon"
								 alt="Icon"
								 class="me-10"
								 width="24"
								 height="24">

							<span v-if="item.count" class="badge bg-primary rounded-1">
								<span v-text="item.count"></span>
								<span class="visually-hidden">{{ item.title }}</span>
							</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div v-if="this.$props.section == 'requisitions'" class="filter-block mb-20">
			<div class="mb-20 border-top border-brd-primary px-20 pt-20">
				<span class="fs-2">Параметры</span>
			</div>

			<div v-if="!this.isEmpty(this.employees_list)" class="mb-3">
				<SelectBox inputId="employees"
						   inputName="employees"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Ответственный"
						   :inputOptions="this.employees_list"
						   :inputValue="this.$props.filters['employees']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('employees', value)" />
			</div>

			<div v-if="!this.isEmpty(this.types_list)" class="mb-3">
				<SelectBox inputId="type"
						   inputName="type"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Тип"
						   :inputOptions="this.types_list"
						   :inputValue="this.$props.filters['type']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('type', value)" />
			</div>

			<div v-if="!this.isEmpty(this.tags_list)" class="mb-3">
				<SelectBox inputId="activities"
						   inputName="activities"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Теги"
						   :inputOptions="this.tags_list"
						   :inputValue="this.$props.filters['activities']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('activities', value)" />
			</div>

			<div v-if="!this.isEmpty(this.sources_list)" class="mb-3">
				<SelectBox inputId="sources"
						   inputName="sources"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Источник"
						   :inputOptions="this.sources_list"
						   :inputValue="this.$props.filters['sources']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('sources', value)" />
			</div>

			<div v-if="!this.isEmpty(this.partners_list)" class="mb-3">
				<SelectBox inputId="partners"
						   inputName="partners"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Партнёр"
						   :inputOptions="this.partners_list"
						   :inputValue="this.$props.filters['partners']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('partners', value)" />
			</div>

			<div v-if="!this.isEmpty(this.stages_list)" class="mb-3">
				<SelectBox inputId="stages"
						   inputName="stages"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Этап"
						   :inputOptions="this.stages_list"
						   :inputValue="this.$props.filters['stages']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('stages', value)" />
			</div>
			<div v-if="!this.isEmpty(this.stages_list)" class="mb-3">
				<Switcher inputId="is_date_step"
					  inputLabel="По дате закрытия"
					  inputName="is_date_step"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_date_step'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_date_step', value)" />	
			</div>
			<div v-if="(!this.isEmpty(this.$props.filters['is_date_step'])) ? true : false" class="d-flex flex-column">
				<div class="d-flex flex-column px-20">
					<div class="row mb-3">
						<div class="col-12 mb-3">
							<span class="text-font-secondary">Период закрытия этапов</span>
						</div>
						<div class="col-6">
							<DateTimePicker inputId="onDateStep"
											inputName="onDateStep"
											ref="onDateStep"
											inputLabel="От"
											inputLabelClass="col-4 pt-2 text-font-secondary text-center"
											inputGroupClass="col"
											inputClass="rounded-3 p-2"
											inputWrapClass="row"
											:inputValue="this.$props.filters['onDateStep']"
											displayFormat="DD.MM.YYYY"
											@setValue="(value) => this.setupFilter('onDateStep', value)" />
						</div>
						<div class="col-6">
							<DateTimePicker inputId="offDateStep"
											inputName="offDateStep"
											ref="offDateStep"
											inputLabel="до"
											inputLabelClass="col-4 pt-2 text-font-secondary text-center"
											inputGroupClass="col"
											inputClass="rounded-3 p-2"
											inputWrapClass="row"
											:inputValue="this.$props.filters['offDateStep']"
											displayFormat="DD.MM.YYYY"
											@setValue="(value) => this.setupFilter('offDateStep', value)" />
						</div>
					</div>
				</div>
			</div>	

			<div v-if="!this.isEmpty(this.denial_work_list)" class="mb-3">
				<SelectBox inputId="denials"
						   inputName="denials"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Причина закрытия"
						   :inputOptions="this.denial_work_list"
						   :inputValue="this.$props.filters['denials']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('denials', value)" />
			</div>

			<div v-if="!this.isEmpty(this.periods_list)" class="filter-block mb-3">
				<SelectBox inputId="period"
						   inputName="period"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Время добавления"
						   :inputOptions="this.periods_list"
						   :inputValue="(!this.isEmpty(this.$props.filters['period'])) ? this.$props.filters['period'] : [8]"
						   inputClass="rounded-1 p-2"
						   inputSearch="true"
						   inputWrapClass="px-20"
						   @setValue="(value) => this.setupFilter('period', value)" />
				<div v-if="(this.$props.filters['period'] == 9) ? true : false" class="row px-20 mt-2">
					<div class="col">
						<DateTimePicker inputId="period_start"
										inputName="period_start"
										ref="period_start"
										inputLabel="с"
										inputLabelClass="col-3 pt-2 text-font-secondary text-center"
										inputGroupClass="col"
										inputClass="rounded-3 p-2"
										inputWrapClass="row px-0"
										:inputValue="this.$props.filters['period_start']"
										displayFormat="DD.MM.YYYY"
										@setValue="(value) => this.setupFilter('period_start', value)" />
					</div>
					<div class="col">
						<DateTimePicker inputId="period_end"
										inputName="period_end"
										ref="period_end"
										inputLabel="по"
										inputLabelClass="col-3 pt-2 text-font-secondary text-center"
										inputGroupClass="col"
										inputClass="rounded-3 p-2"
										inputWrapClass="row px-0"
										:inputValue="this.$props.filters['period_end']"
										displayFormat="DD.MM.YYYY"
										@setValue="(value) => this.setupFilter('period_end', value)" />
					</div>
				</div>
			</div>

			<InputText inputId="object_id"
					   inputName="object_id"
					   inputLabelClass="text-font-secondary mb-3"
					   inputLabel="ID объекта по заявке"
					   :inputValue="this.$props.filters['object_id']"
					   inputClass="rounded-1 p-2"
					   inputWrapClass="px-20"
					   @setValue="(value) => this.setupFilter('object_id', value)" />

		</div>

		<!-- Кастомные поля -->
		<div v-if="this.inArray(this.$props.section, ['clients', 'requisitions'])" class="filter-block mb-20">
			<div v-if="!this.isEmpty(this.fields_list)" class="px-20">
				<div v-for="(field, key) in this.fields_list" :key="field.value">

					<SelectBox v-if="!this.isEmpty(field.is_sub_group)"
							   :inputId="'fields_' + key"
							   :inputName="'fields_' + key"
							   :ref="'fields.' + key"
							   :inputLabel="field.label + ':'"
							   :inputValue="(this.getCustomFieldValue(key)) ? this.getCustomFieldValue(key) : null"
							   :inputOptions="(!this.isEmpty(field.params.items)) ? Object.values(field.params.items) : null"
							   inputMultiple="true"
							   inputLabelClass="text-font-secondary mb-3"
							   inputClass="rounded-3 p-2"
							   inputWrapClass="mb-20"
							   @setValue="(value) => this.setupFilter('fields.' + key, value)" />

					<div v-if="!this.isEmpty(field.is_sub_group)">

						<div v-for="(group, key2) in field.params.items" :key="group.value">

							<div v-if="this.inArray(group.value, this.getCustomFieldValue(key))">

								<InputText v-if="group.type == 0"
										   :inputLabel="group.name + ':'"
										   :inputValue="(!this.isEmpty(this.$props.filters['fields'][key2])) ? this.$props.filters['fields'][key2] : null"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputWrapClass="mb-20"
										   @setValue="(value) => this.setupFilter('fields.' + key2, value)" />

								<InputNumber v-else-if="group.type == 1"
											 :inputLabel="group.name + ':'"
											 :inputValue="(!this.isEmpty(this.$props.filters['fields'][key2])) ? this.$props.filters['fields'][key2] : null"
											 inputLabelClass="text-font-secondary mb-3"
											 inputClass="rounded-3 p-2"
											 inputWrapClass="mb-20"
											 @setValue="(value) => this.setupFilter('fields.' + key2, value)" />

								<SelectBox v-else-if="group.type == 2 || group.type == 3"
										   :inputLabel="group.name + ':'"
										   :inputValue="(!this.isEmpty(this.$props.filters['fields'][key2])) ? this.$props.filters['fields'][key2] : null"
										   :inputOptions="group.options"
										   :inputMultiple="(group.type == 3)"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputWrapClass="mb-20"
										   @setValue="(value) => this.setupFilter('fields.' + key2, value)" />

								<Switcher v-else-if="group.type == 4"
										  :inputLabel="group.name"
										  :inputValue="(!this.isEmpty(this.$props.filters['fields'][key2])) ? this.$props.filters['fields'][key2] : null"
										  inputClass="px-0"
										  inputWrapClass="flex-column mb-3"
										  @setValue="(value) => this.setupFilter('fields.' + key2, value)" />

								<TextArea v-else-if="group.type == 5"
										  :inputLabel="group.name + ':'"
										  :inputValue="(!this.isEmpty(this.$props.filters['fields'][key2])) ? this.$props.filters['fields'][key2] : null"
										  inputLabelClass="text-font-secondary mb-3"
										  inputClass="rounded-3 p-2"
										  inputWrapClass="mb-20"
										  @setValue="(value) => this.setupFilter('fields.' + key2, value)" />

							</div>
						</div>
					</div>

					<InputText v-else-if="field.type == 0"
							   :inputId="'fields_' + key"
							   :inputName="'fields_' + key"
							   :ref="'fields.' + key"
							   :inputLabel="field.label + ':'"
							   :inputValue="(this.getCustomFieldValue(key)) ? this.getCustomFieldValue(key) : null"
							   inputLabelClass="text-font-secondary mb-3"
							   inputClass="rounded-3 p-2"
							   inputWrapClass="mb-20"
							   @setValue="(value) => this.setupFilter('fields.' + key, value)" />

					<InputNumber v-else-if="field.type == 1"
								 :inputId="'fields_' + key"
								 :inputName="'fields_' + key"
								 :ref="'fields.' + key"
								 :inputLabel="field.label + ':'"
								 :inputValue="(this.getCustomFieldValue(key)) ? this.getCustomFieldValue(key) : null"
								 inputLabelClass="text-font-secondary mb-3"
								 inputClass="rounded-3 p-2"
								 inputWrapClass="mb-20"
								 @setValue="(value) => this.setupFilter('fields.' + key, parseInt(value))" />

					<SelectBox v-else-if="field.type == 2 || field.type == 3"
							   :inputId="'fields_' + key"
							   :inputName="'fields_' + key"
							   :ref="'fields.' + key"
							   :inputLabel="field.label + ':'"
							   :inputValue="(this.getCustomFieldValue(key)) ? this.getCustomFieldValue(key) : null"
							   :inputOptions="(!this.isEmpty(field.params.items)) ? field.params.items : null"
							   :inputMultiple="(field.type == 3)"
							   inputLabelClass="text-font-secondary mb-3"
							   inputClass="rounded-3 p-2"
							   inputWrapClass="mb-20"
							   @setValue="(value) => this.setupFilter('fields.' + key, value)" />

					<Switcher v-else-if="field.type == 4"
							  :inputId="'fields_' + key"
							  :inputName="'fields_' + key"
							  :ref="'fields.' + key"
							  :inputLabel="field.label"
							  :inputValue="(this.getCustomFieldValue(key)) ? this.getCustomFieldValue(key) : null"
							  inputClass="px-0"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setupFilter('fields.' + key, parseInt(value))" />

					<TextArea v-else-if="field.type == 5"
							  :inputId="'fields_' + key"
							  :inputName="'fields_' + key"
							  :ref="'fields.' + key"
							  :inputLabel="field.label + ':'"
							  :inputValue="(this.getCustomFieldValue(key)) ? this.getCustomFieldValue(key) : null"
							  inputLabelClass="text-font-secondary mb-3"
							  inputClass="rounded-3 p-2"
							  inputWrapClass="mb-20"
							  @setValue="(value) => this.setupFilter('fields.modelp_' + key, value)" />

				</div>
			</div>
		</div>

		<div v-if="this.$props.section == 'requisitions'" class="filter-block mb-20">
			<div class="d-flex flex-column px-20 mb-10">
				<span class="text-font-secondary mb-3">Категория заявки</span>
				<ul class="d-flex check-group flex-wrap align-items-center m-0 p-0">
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority1"
							   id="priority_1"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (1 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.1', event.target.value)">
						<label class="btn btn-raspberry btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_1" />
					</li>
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority2"
							   id="priority_2"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (2 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.2', event.target.value)">
						<label class="btn btn-warning btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_2" />
					</li>
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority3"
							   id="priority_3"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (3 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.3', event.target.value)">
						<label class="btn btn-ocean btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_3" />
					</li>
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority4"
							   id="priority_4"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (4 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.4', event.target.value)">
						<label class="btn btn-primary btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_4" />
					</li>
				</ul>
			</div>
		</div>

		<div v-if="this.$props.section == 'requisitions'" class="filter-block mb-20">

			<Switcher inputId="is_contract"
					  inputLabel="C договорами"
					  inputName="is_contract"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_contract'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_contract', value)" />

			<div v-if="(!this.isEmpty(this.$props.filters['is_contract'])) ? true : false" class="d-flex flex-column">
				<SelectBox inputId="contract_type"
						   inputName="contract_type"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Тип договора"
						   :inputOptions="this.contract_types"
						   :inputValue="this.$props.filters['contract_type']"
						   inputClass="rounded-1 p-2"
						   inputMultiple="true"
						   inputSearch="true"
						   inputWrapClass="flex-column px-20 mb-3"
						   @setValue="(value) => this.setupFilter('contract_type', value)" />

				<InputText inputId="contract_number"
						   inputName="contract_number"
						   inputLabelClass="text-font-secondary mb-3"
						   inputLabel="Номер договора"
						   :inputValue="this.$props.filters['contract_number']"
						   inputClass="rounded-1 p-2"
						   inputWrapClass="flex-column px-20 mb-3"
						   @setValue="(value) => this.setupFilter('contract_number', value)" />

				<div class="d-flex flex-column px-20">
					<div class="row mb-3">
						<div class="col-12 mb-3">
							<span class="text-font-secondary">Период окончания договора</span>
						</div>
						<div class="col-6">
							<DateTimePicker inputId="contract_start"
											inputName="contract_start"
											ref="contract_start"
											inputLabel="От"
											inputLabelClass="col-4 pt-2 text-font-secondary text-center"
											inputGroupClass="col"
											inputClass="rounded-3 p-2"
											inputWrapClass="row"
											:inputValue="this.$props.filters['contract_start']"
											displayFormat="DD.MM.YYYY"
											@setValue="(value) => this.setupFilter('contract_start', value)" />
						</div>
						<div class="col-6">
							<DateTimePicker inputId="contract_end"
											inputName="contract_end"
											ref="contract_end"
											inputLabel="до"
											inputLabelClass="col-4 pt-2 text-font-secondary text-center"
											inputGroupClass="col"
											inputClass="rounded-3 p-2"
											inputWrapClass="row"
											:inputValue="this.$props.filters['contract_end']"
											displayFormat="DD.MM.YYYY"
											@setValue="(value) => this.setupFilter('contract_end', value)" />
						</div>
					</div>
				</div>
			</div>

			<Switcher inputId="is_tasks"
					  inputLabel="С задачами"
					  inputName="is_tasks"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_tasks'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_tasks', value)" />

			<Switcher inputId="is_no_tasks"
					  inputLabel="Заявки без задач"
					  inputName="is_no_tasks"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_no_tasks'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_no_tasks', value)" />

			<Switcher inputId="is_today_tasks"
					  inputLabel="Задачи на сегодня"
					  inputName="is_today_tasks"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_today_tasks'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_today_tasks', value)" />

			<Switcher inputId="is_over_tasks"
					  inputLabel="С просроченными задачами"
					  inputName="is_over_tasks"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_over_tasks'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_over_tasks', value)" />

			<Switcher inputId="is_partner"
					  inputLabel="Совместные заявки"
					  inputName="is_partner"
					  :inputValue="(!this.isEmpty(this.$props.filters['is_partner'])) ? 1 : 0"
					  inputClass="px-0"
					  inputWrapClass="flex-column px-20 mb-3"
					  @setValue="(value) => this.setupFilter('is_partner', value)" />

		</div>

		<!-- Фильтры: поиск -->
		<div v-if="this.$props.section == 'search'" class="filter-block mt-4 mb-20">
			<div v-if="this.types_list" class="p-0">

				<ToggleButtons v-if="this.types_list"
							   inputId="object_type"
							   inputName="object_type"
							   inputLabel="Тип объекта"
							   inputType="radio"
							   inputLabelClass="text-font-primary fs-1 mb-3"
							   :inputValue="this.$props.filters['object_type']"
							   :inputOptions="this.types_list"
							   inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
							   inputWrapClass="flex-column mb-3"
							   @setValue="(value) => this.setupFilter('object_type', value)" />
				
				<ToggleButtons v-if="this.$props.filters['operation_type'] == 0"
							   inputId="lease_type"
							   inputLabel="Срок аренды"
							   inputName="lease_type"
							   inputType="radio"
							   inputLabelClass="text-font-primary fs-1 mb-3"
							   :inputValue="this.$props.filters['lease_type']"
							   :inputOptions="this.lease_list"
							   inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
							   inputWrapClass="flex-column mb-3"
							   @setValue="(value) => this.setupFilter('lease_type', value)" />
				
				<ToggleButtons v-show="this.commercial_list && this.$props.filters['object_type'] == 4"
							   inputId="commercial_type"
							   inputName="commercial_type"
							   inputLabel="Категория помещения"
							   inputType="checkbox"
							   inputLabelClass="text-font-primary fs-1 mb-3"
							   :inputValue="this.$props.filters['commercial_type']"
							   :inputOptions="this.commercial_list"
							   inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
							   inputWrapClass="flex-column mb-3"
							   @setValue="(value) => this.setupFilter('commercial_type', value)"
								 @applyFilters="setCommercialType"
					/>

				<div class="col mb-3">
					<div class="form-label text-font-primary fs-1 mb-3">Основная информация</div>

<!--				<InputRangeBetween inputId="price"
									   inputName="price"
									   inputLabel="Цена, руб."
									   inputFromPlaceholder="от"
									   inputToPlaceholder="до"
									   inputStep="10000"
									   inputMin="0"
									   inputMax="2147000000"
									   inputIsPrice="true"
									   :inputValueFrom="this.$props.filters['price_from']"
									   :inputValueTo="this.$props.filters['price_to']"
									   inputClass="rounded-1"
									   inputWrapClass="flex-column mb-3"
									   @setValue="(value) => this.setupPriceFilter(value) "/>-->
					<Switcher inputId="use_area_price"
							  inputName="use_area_price"
							  inputLabel="Цена за квадратный метр"
							  :inputValue="this.$props.filters['use_area_price']"
							  inputClass="px-0"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setupFilter('use_area_price', value)" />
					<InputText inputId="price_from"
							   inputName="price_from"
							   ref="price_from"
							   inputLabel="Цена, от:"
							   inputLabelClass="text-font-secondary mb-3"
							   inputClass="rounded-3 p-2"
							   inputWrapClass="mb-2"
							   :inputValue="(!this.isEmpty(this.$props.filters['price_from'])) ? this.$props.filters['price_from'] : null"
							   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
							   inputMode="numeric"
							   inputAppend=" руб."
							   inputStep="10000"
							   inputMin="0"
							   inputMax="2147000000"
							   inputIsPrice="true"
							   @setValue="(value) => this.setupFilter('price_from', value)" />

					<InputText inputId="price_to"
							   inputName="price_to"
							   ref="price_to"
							   inputLabel="Цена, до:"
							   inputLabelClass="text-font-secondary mb-3"
							   inputClass="rounded-3 p-2"
							   inputWrapClass="mb-20"
							   :inputValue="(!this.isEmpty(this.$props.filters['price_to'])) ? this.$props.filters['price_to'] : null"
							   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
							   inputMode="numeric"
							   inputAppend=" руб."
							   inputStep="10000"
							   inputMin="0"
							   inputMax="2147000000"
							   inputIsPrice="true"
							   @setValue="(value) => this.setupFilter('price_to', value)" />

					<InputNumber v-if="this.$props.filters['object_type'] == 2" inputId="rooms"
								 inputName="rooms"
								 inputLabel="Всего комнат в квартире"
								 inputPlaceholder="до"
								 inputStep="1"
								 inputMin="1"
								 inputMax="30"
								 :inputValue="this.$props.filters['rooms']"
								 inputClass="rounded-1"
								 inputWrapClass="flex-column mb-3"
								 @setValue="(value) => { this.setupFilter('rooms', value) }" />

					<InputNumberBetween v-else-if="showAreaLive" inputId="rooms"
										inputName="rooms"
										inputLabel="Комнат"
										inputFromPlaceholder="от"
										inputToPlaceholder="до"
										inputStep="1"
										inputMin="1"
										inputMax="1000"
										:inputValueFrom="this.$props.filters['rooms_from']"
										:inputValueTo="this.$props.filters['rooms_to']"
										inputClass="rounded-1"
										inputWrapClass="flex-column mb-3"
										@setValue="(value) => {
											this.setupFilter('rooms_from', value.from);
											this.setupFilter('rooms_to', value.to);
										}" />

					<InputNumberBetween v-if="this.$props.filters['object_type'] == 2" inputId="area_full"
										inputName="area_full"
										inputLabel="Площадь комнаты, кв.м"
										inputFromPlaceholder="от"
										inputToPlaceholder="до"
										inputStep="1"
										inputMin="0"
										inputMax="10000"
										:inputValueFrom="this.$props.filters['area_full_from']"
										:inputValueTo="this.$props.filters['area_full_to']"
										inputClass="rounded-1"
										inputWrapClass="flex-column mb-3"
										@setValue="(value) => {
											this.setupFilter('area_full_from', value.from);
											this.setupFilter('area_full_to', value.to);
										}" />

					<InputNumberBetween v-else inputId="area_full"
										inputName="area_full"
										:inputLabel="areaFullInputLabel"
										inputFromPlaceholder="от"
										inputToPlaceholder="до"
										inputStep="1"
										inputMin="0"
										inputMax="10000"
										:inputValueFrom="this.$props.filters['area_full_from']"
										:inputValueTo="this.$props.filters['area_full_to']"
										inputClass="rounded-1"
										inputWrapClass="flex-column mb-3"
										@setValue="(value) => {
											this.setupFilter('area_full_from', value.from);
											this.setupFilter('area_full_to', value.to);
										}" />

          <InputNumberBetween v-if="showYearBetween" inputId="year"
                              inputName="year"
                              inputLabel="Год постройки"
                              inputFromPlaceholder="от"
                              inputToPlaceholder="до"
                              inputStep="1"
                              inputMin="1800"
                              inputMax="3000"
                              :inputValueFrom="this.$props.filters['year_from']"
                              :inputValueTo="this.$props.filters['year_to']"
                              inputClass="rounded-1"
                              inputWrapClass="flex-column mb-3"
                              @setValue="(value) => {
											this.setupFilter('year_from', value.from);
											this.setupFilter('year_to', value.to);
										}" />

          <InputNumberBetween v-if="this.inArray(this.$props.filters['object_type'], [3, 5])" inputId="land_area"
										inputName="land_area"
										inputLabel="Площадь участка"
										inputFromPlaceholder="от"
										inputToPlaceholder="до"
										inputStep="1"
										inputMin="0"
										inputMax="10000"
										:inputValueFrom="this.$props.filters['land_area_from']"
										:inputValueTo="this.$props.filters['land_area_to']"
										inputClass="rounded-1"
										inputWrapClass="flex-column mb-3"
										@setValue="(value) => {
											this.setupFilter('land_area_from', value.from);
											this.setupFilter('land_area_to', value.to);
										}" />

					<InputNumberBetween v-if="showFlatBetween" inputId="flat"
										inputName="flat"
										inputLabel="Этаж"
										inputFromPlaceholder="от"
										inputToPlaceholder="до"
										inputStep="1"
										inputMin="1"
										inputMax="120"
										:inputValueFrom="this.$props.filters['flat_from']"
										:inputValueTo="this.$props.filters['flat_to']"
										inputClass="rounded-1"
										inputWrapClass="flex-column mb-3"
										@setValue="(value) => {
											this.setupFilter('flat_from', value.from);
											this.setupFilter('flat_to', value.to);
										}" />

					<Switcher v-if="showNotFirstFloor" inputId="not_first_floor"
							  inputName="not_first_floor"
							  inputLabel="Не первый этаж"
							  :inputValue="this.$props.filters['not_first_floor']"
							  inputClass="px-0"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setupFilter('not_first_floor', value)" />

					<Switcher v-if="showNotFirstFloor" inputId="not_last_floor"
							  inputName="not_last_floor"
							  inputLabel="Не последний этаж"
							  :inputValue="this.$props.filters['not_last_floor']"
							  inputClass="px-0"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setupFilter('not_last_floor', value)" />


					<InputNumberBetween v-if="showAreaLive" inputId="area_live"
										inputName="area_live"
										inputLabel="Жилая площадь, кв.м"
										inputFromPlaceholder="от"
										inputToPlaceholder="до"
										inputStep="1"
										inputMin="1"
										inputMax="100"
										:inputValueFrom="this.$props.filters['area_live_from']"
										:inputValueTo="this.$props.filters['area_live_to']"
										inputClass="rounded-1"
										inputWrapClass="flex-column mb-3"
										@setValue="(value) => {
											this.setupFilter('area_live_from', value.from);
											this.setupFilter('area_live_to', value.to);
										}" />

					<InputNumberBetween v-if="showAreaLive" inputId="area_kitchen"
										inputName="area_kitchen"
										inputLabel="Площадь кухни, кв.м"
										inputFromPlaceholder="от"
										inputToPlaceholder="до"
										inputStep="1"
										inputMin="1"
										inputMax="100"
										:inputValueFrom="this.$props.filters['area_kitchen_from']"
										:inputValueTo="this.$props.filters['area_kitchen_to']"
										inputClass="rounded-1"
										inputWrapClass="flex-column mb-3"
										@setValue="(value) => {
											this.setupFilter('area_kitchen_from', value.from);
											this.setupFilter('area_kitchen_to', value.to);
										}" />

				</div>

				<div class="col mb-3">
					<div class="form-label text-font-primary fs-1 mb-3">Место</div>

					<SelectBox inputLabelClass="text-font-secondary mb-3"
							   inputId="district"
							   inputName="district"
							   inputLabel="Страна"
							   :inputOptions="this.countries_list"
							   :inputValue="this.country_id"
							   inputClass="rounded-1 p-2"
							   inputSearch="true"
							   inputWrapClass="mb-20"
							   @setValue="(value) => this.setupFilter('country_id', value)" />

					<InputSelect v-if="this.country_id == 171" inputId="regions"
								 inputLabel="Субъект РФ (регион)"
								 inputName="regions"
								 ref="regions"
								 :inputOptions="this.regions_list"
								 :inputValue="(!this.isEmpty(this.$props.filters['regions'])) ? this.$props.filters['regions'] : [this.user_region_id]"
								 inputClass="rounded-1"
								 inputUsePlaceholder="true"
								 inputWrapClass="flex-column mb-3"
								 @setValue="(value) => this.setupFilter('regions', value)" />

					<SelectBox v-if="this.country_id == 171" inputLabelClass="text-font-secondary mb-3"
							   inputId="district"
							   inputName="district"
							   inputLabel="Район"
							   :inputOptions="this.districts_list"
							   :inputValue="this.$props.filters['district']"
							   inputClass="rounded-1 p-2"
							   inputMultiple="true"
							   inputSearch="true"
							   inputWrapClass="mb-20"
							   @setValue="(value) => this.setupFilter('district', value)" />

					<SelectBox v-if="this.country_id == 171 && !this.isEmpty(this.metro_list)" inputLabelClass="text-font-secondary mb-3"
							   inputId="metro"
							   inputName="metro"
							   inputLabel="Метро"
							   :inputOptions="this.metro_list"
							   :inputValue="this.$props.filters['metro']"
							   inputClass="rounded-1 p-2"
							   inputMultiple="true"
							   inputSearch="true"
							   inputWrapClass="mb-20"
							   @setValue="(value) => this.setupFilter('metro', value)" />

					<div v-if="this.country_id == 171 && !this.isEmpty(this.metro_list)" class="row">
						<div class="col-8">
							<InputNumber inputId="metro_time"
										 inputLabel="До метро, мин."
										 inputName="metro_time"
										 :inputValue="this.$props.filters['metro_time'] ?? null"
										 inputClass="rounded-1 mb-10"
										 inputWrapClass="flex-column mb-3"
										 @setValue="(value) => this.setupFilter('metro_time', value)" />
						</div>
						<div class="col-4">
							<InputSelect inputId="to_metro"
										 inputLabel="&nbsp;"
										 inputName="to_metro"
										 :inputOptions="this.to_metro_list"
										 :inputValue="(!isEmpty(this.$props.filters['to_metro'])) ? this.$props.filters['to_metro'] : '1'"
										 inputClass="rounded-1 mb-10"
										 inputUsePlaceholder="true"
										 inputWrapClass="flex-column mb-3"
										 @setValue="(value) => this.setupFilter('to_metro', value)" />

						</div>
					</div>

					<InputText
						inputId="address"
						inputLabel="Адрес"
						inputName="address"
						inputPlaceholder="Улица, проспект, шоссе, переулок"
						inputClass="rounded-1 mb-10"
						:inputValue="this.$props.filters['address']"
						inputWrapClass="flex-column mb-0"
						@setValue="(value) => this.setupFilter('address', value)" />

				</div>

				<div class="col mb-3">
					<div class="form-label text-font-primary fs-1 mb-3">Дополнительно</div>

					<div v-if="this.$props.filters['object_type'] == 4" class="p-0">

						<InputText v-if="showTaxNumber" inputId="tax_number"
								   inputName="tax_number"
								   inputClass="rounded-1 mb-10"
								   inputWrapClass="flex-column mb-2"
								   inputLabel="Номер налоговой:"
								   :inputValue="this.$props.filters['tax_number'] ?? null"
								   @setValue="(value) => this.setupFilter('tax_number', value)" />

						<InputSelect v-if="showLegalAddress" inputId="legal_address"
									 inputName="legal_address"
									 inputLabel="Юридический адрес:"
									 :inputValue="this.$props.filters['legal_address'] ?? null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 :inputOptions="this.legal_address_list"
									 @setValue="(value) => this.setupFilter('legal_address', value)" />

						<InputSelect v-if="showOccupiedId" inputId="occupied_id"
									 inputName="occupied_id"
									 inputLabel="Помещение занято:"
									 :inputValue="this.$props.filters['occupied_id'] ?? null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 :inputOptions="this.occupieds_list"
									 @setValue="(value) => this.setupFilter('occupied_id', value)" />

						<InputSelect v-if="showLayoutId" inputId="layout_id"
									 inputName="layout_id"
									 inputLabel="Планировка:"
									 :inputValue="this.$props.filters['layout_id'] ?? null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 :inputOptions="this.layouts_list"
									 @setValue="(value) => this.setupFilter('layout_id', value)" />

						<InputSelect v-if="showLegalAddress" inputId="wet_spots_id"
									 inputName="wet_spots_id"
									 inputLabel="Количество мокрых точек:"
									 :inputValue="this.$props.filters['wet_spots_id'] ?? null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 :inputOptions="this.wet_spots_list"
									 @setValue="(value) => this.setupFilter('wet_spots_id', value)" />

						<InputNumberBetween v-if="showLegalAddress" inputId="electricity_power"
											inputName="electricity_power"
											inputLabel="Электрическая мощность, кВт:"
											inputFromPlaceholder="от"
											inputToPlaceholder="до"
											inputStep="1"
											inputMin="0"
											inputMax="10000"
											:inputValueFrom="this.$props.filters['electricity_power_from']"
											:inputValueTo="this.$props.filters['electricity_power_to']"
											inputClass="rounded-1"
											inputWrapClass="flex-column mb-3"
											@setValue="(value) => {
											this.setupFilter('electricity_power_from', value.from);
											this.setupFilter('electricity_power_to', value.to);
										}" />

						<SelectBox v-if="!isCommercialType" inputId="condition_id"
								   inputLabel="Состояние:"
								   inputName="condition_id"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-3"
								   :inputValue="this.$props.filters['condition_id'] ?? null"
								   :inputOptions="this.conditions_list"
								   inputSearch="true"
								   inputMultiple="true"
								   @setValue="(value) => this.setupFilter('condition_id', value)" />

						<InputSelect v-if="showFurniture" inputId="furniture_id"
									 inputName="furniture_id"
									 inputLabel="Мебель:"
									 :inputValue="this.$props.filters['furniture_id'] ?? null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 :inputOptions="this.furnitures_list"
									 @setValue="(value) => this.setupFilter('furniture_id', value)" />

						<InputSelect v-if="showAccessId" inputId="access_id"
									 inputName="access_id"
									 inputLabel="Доступ:"
									 :inputValue="this.$props.filters['access_id'] ?? null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 :inputOptions="this.access_list"
									 @setValue="(value) => this.setupFilter('access_id', value)" />

						<SelectBox v-if="showParkingType" inputId="parking_type_id"
								   inputName="parking_type_id"
								   inputLabel="Парковка:"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-4"
								   :inputValue="this.$props.filters['parking_type_id'] ?? null"
								   :inputOptions="this.parking_sub_types_list"
								   inputSearch="true"
								   inputMultiple="true"
								   @setValue="(value) => this.setupFilter('parking_type_id', value)" />

						<SelectBox v-else-if="showParkingTypeId" inputId="parking_type_id"
								   inputName="parking_type_id"
								   inputLabel="Тип парковки:"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-4"
								   :inputValue="this.$props.filters['parking_type_id'] ?? null"
								   :inputOptions="this.parking_types_list"
								   inputSearch="true"
								   inputMultiple="true"
								   @setValue="(value) => this.setupFilter('parking_type_id', value)" />

						<div v-if="showTaxNumber" class="row row-cols-2">
							<div class="col">
								<InputNumber inputId="parking_price"
											 inputName="parking_price"
											 inputClass="rounded-1 mb-10"
											 inputWrapClass="flex-column mb-3"
											 inputLabel="Стоимость парковки:"
											 inputMin="0"
											 inputMax="99999"
											 inputStep="1"
											 :inputValue="this.$props.filters['parking_price'] ?? null"
											 :inputDisabled="(this.$props.filters['parking_is_free'])"
											 @setValue="(value) => this.setupFilter('parking_price', value)" />
							</div>
							<div class="col pt-2">
								<InputCheckbox inputId="parking_is_free"
											   inputName="parking_is_free"
											   inputClass="mb-0"
											   inputWrapClass="flex-column pt-1 mt-4"
											   inputLabel="Бесплатно"
											   :inputValue="this.$props.filters['parking_is_free'] ?? null"
											   @setValue="(value) => this.setupFilter('parking_is_free', value)" />
							</div>
						</div>

						<InputNumberBetween v-if="showCeilingHeight" inputId="ceiling_height"
											inputName="ceiling_height"
											inputLabel="Высота потолков:"
											inputFromPlaceholder="от"
											inputToPlaceholder="до"
											inputStep="1"
											inputMin="0"
											inputMax="5"
											:inputValueFrom="this.$props.filters['ceiling_height_from']"
											:inputValueTo="this.$props.filters['ceiling_height_to']"
											inputClass="rounded-1"
											inputWrapClass="flex-column mb-3"
											@setValue="(value) => {
											this.setupFilter('ceiling_height_from', value.from);
											this.setupFilter('ceiling_height_to', value.to);
										}" />

						<InputText v-if="showColumnGrid" inputId="column_grid"
								   inputName="column_grid"
								   inputClass="rounded-1 mb-10"
								   inputWrapClass="flex-column mb-2"
								   inputLabel="Сетка колон:"
								   :inputValue="this.$props.filters['column_grid'] ?? null"
								   @setValue="(value) => this.setupFilter('column_grid', value)" />

						<InputSelect v-if="showTaxNumber" inputId="vat_type_id"
									 inputName="vat_type_id"
									 inputLabel="Налог:"
									 :inputValue="this.$props.filters['vat_type_id'] ?? null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 :inputOptions="this.vat_types_list"
									 @setValue="(value) => this.setupFilter('vat_type_id', value)" />

						<div v-if="!isBuildingGarage" class="row row-cols-3">
							<div class="col">
								<InputNumber inputId="commerce_site_area"
											 inputName="commerce_site_area"
											 inputClass="rounded-1 mb-10"
											 inputWrapClass="flex-column mb-3"
											 inputLabel="Участок, га:"
											 inputMin="0"
											 inputMax="99999"
											 inputStep="1"
											 :inputValue="this.$props.filters['commerce_site_area'] ?? null"
											 @setValue="(value) => this.setupFilter('commerce_site_area', value)" />
							</div>
							<div class="col pt-2">
								<inputCheckbox inputId="commerce_site_owned"
											   inputName="commerce_site_owned"
											   inputClass="mb-4"
											   inputWrapClass="flex-column pt-1 mt-4"
											   inputLabel="собств."
											   :inputValue="(this.$props.filters['commerce_site_owned'] == 1) ?? false"
											   @setValue="(value) => this.setupFilter('commerce_site_owned', 1)" />
							</div>
							<div class="col pt-2">
								<inputCheckbox inputId="commerce_site_owned2"
											   inputName="commerce_site_owned2"
											   inputClass="mb-0"
											   inputWrapClass="flex-column pt-1 mt-4"
											   inputLabel="аренда"
											   :inputValue="(this.$props.filters['commerce_site_owned'] == 2) ?? false"
											   @setValue="(value) => this.setupFilter('commerce_site_owned', 2)" />
							</div>
						</div>

						<div v-if="isCommercialType">

							<InputSelect inputId="commerce_land_category_id"
										 inputLabel="Категория земли:"
										 inputName="commerce_land_category_id"
										 :inputValue="this.$props.filters['commerce_land_category_id'] ?? null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_land_categories_list"
										 @setValue="(value) => this.setupFilter('commerce_land_category_id', value)" />

							<InputSelect inputId="commerce_permitted_use_id"
										 inputLabel="Вид разрешенного использования:"
										 inputName="commerce_permitted_use_id"
										 :inputValue="this.$props.filters['commerce_permitted_use_id'] ?? null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_permitted_use_list"
										 @setValue="(value) => this.setupFilter('commerce_permitted_use_id', value)" />

							<InputSelect inputId="commerce_electricity_id"
										 inputLabel="Электричество:"
										 inputName="commerce_electricity_id"
										 :inputValue="this.$props.filters['commerce_electricity_id'] ?? null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_electricity_list"
										 @setValue="(value) => this.setupFilter('commerce_electricity_id', value)" />

							<InputSelect inputId="commerce_gas_id"
										 inputLabel="Газ:"
										 inputName="commerce_gas_id"
										 :inputValue="this.$props.filters['commerce_gas_id'] ?? null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_gas_list"
										 @setValue="(value) => this.setupFilter('commerce_gas_id', value)" />

							<InputSelect inputId="commerce_water_id"
										 inputLabel="Вода:"
										 inputName="commerce_water_id"
										 :inputValue="this.$props.filters['commerce_water_id'] ?? null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_water_list"
										 @setValue="(value) => this.setupFilter('commerce_water_id', value)" />

							<InputSelect inputId="commerce_sewage_id"
										 inputLabel="Канализация:"
										 inputName="commerce_sewage_id"
										 :inputValue="this.$props.filters['commerce_sewage_id'] ?? null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_sewage_list"
										 @setValue="(value) => this.setupFilter('commerce_sewage_id', value)" />

							<InputSelect inputId="commerce_driveways_id"
										 inputLabel="Подъездные пути:"
										 inputName="commerce_driveways_id"
										 :inputValue="this.$props.filters['commerce_driveways_id'] ?? null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_driveways_list"
										 @setValue="(value) => this.setupFilter('commerce_driveways_id', value)" />

						</div>

						<SelectBox v-if="showCommercePurpose" inputId="commerce_purpose_id"
								   inputName="commerce_purpose_id"
								   inputLabel="Назначение:"
								   :inputValue="this.$props.filters['commerce_purpose_id'] ?? null"
								   inputClass="rounded-1"
								   inputWrapClass="flex-column mb-3"
								   inputMultiple="true"
								   :inputOptions="this.commerce_purpose_list"
								   @setValue="(value) => this.setupFilter('commerce_purpose_id', value)" />

						<SelectBox v-if="showColumnGrid" inputId="floor_material_id"
									 inputName="floor_material_id"
									 inputLabel="Материал пола:"
									 :inputValue="this.$props.filters['floor_material_id'] ?? null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputMultiple="true"
									 :inputOptions="this.floor_materials_list"
									 @setValue="(value) => this.setupFilter('floor_material_id', value)" />


						<SelectBox v-if="showColumnGrid" inputId="commerce_portal_id"
								   inputName="commerce_portal_id"
								   inputLabel="Ворота:"
								   :inputValue="this.$props.filters['commerce_portal_id'] ?? null"
								   inputClass="rounded-1"
								   inputWrapClass="flex-column mb-3"
								   inputMultiple="true"
								   :inputOptions="this.commerce_portal_list"
								   @setValue="(value) => this.setupFilter('commerce_portal_id', value)" />

						<InputNumberBetween v-if="!isCommercialOrGarage" inputId="building_year"
											inputName="building_year"
											inputLabel="Год постройки:"
											inputFromPlaceholder="от"
											inputToPlaceholder="до"
											inputStep="1"
											inputMin="1940"
											:inputMax="this.moment().format('YYYY')"
											:inputValueFrom="this.$props.filters['building_year_from']"
											:inputValueTo="this.$props.filters['building_year_to']"
											inputClass="rounded-1"
											inputWrapClass="flex-column mb-3"
											@setValue="(value) => {
												this.setupFilter('building_year_from', value.from)
												this.setupFilter('building_year_to', value.to);
											}" />

						<SelectBox v-if="!isCommercialOrGarage" inputId="building_type_id"
								   inputName="building_type_id"
								   inputLabel="Тип здания:"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-4"
								   :inputValue="this.$props.filters['building_type_id'] ?? null"
								   :inputOptions="this.building_types_list"
								   inputSearch="true"
								   inputMultiple="true"
								   @setValue="(value) => this.setupFilter('building_type_id', value)" />

						<SelectBox v-if="!isCommercialOrGarage" inputId="building_class_id"
								   inputName="building_class_id"
								   inputLabel="Класс здания:"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-4"
								   :inputValue="this.$props.filters['building_class_id'] ?? null"
								   :inputOptions="this.building_classes_list"
								   inputSearch="true"
								   inputMultiple="true"
								   @setValue="(value) => this.setupFilter('building_class_id', value)" />

						<InputNumberBetween v-if="!isCommercialOrGarage" inputId="building_area"
											inputName="building_area"
											inputLabel="Площадь здания, кв.м:"
											inputFromPlaceholder="от"
											inputToPlaceholder="до"
											inputStep="1"
											inputMin="0"
											inputMax="1000"
											:inputValueFrom="this.$props.filters['building_area_from']"
											:inputValueTo="this.$props.filters['building_area_to']"
											inputClass="rounded-1"
											inputWrapClass="flex-column mb-3"
											@setValue="(value) => {
											this.setupFilter('building_area_from', value.from);
											this.setupFilter('building_area_to', value.to);
										}" />

						<SelectBox v-if="showHouselineType" inputId="houseline_type_id"
								   inputLabel="Линия домов:"
								   inputName="houseline_type_id"
								   :inputValueTo="this.$props.filters['houseline_type_id']"
								   :inputOptions="this.houseline_types_list"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-2"
								   inputSearch="true"
								   inputMultiple="true"
								   @setValue="(value) => this.setupFilter('houseline_type_id', value)" />

						<InputSelect v-if="!isCommercialOrGarage" inputId="building_category_id"
									 inputName="building_category_id"
									 inputLabel="Категория:"
									 :inputValue="this.$props.filters['building_category_id'] ?? null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 :inputOptions="this.building_categories_list"
									 @setValue="(value) => this.setupFilter('building_category_id', value)" />

						<SelectBox inputId="commerce_entrance_id"
								   inputLabel="Вход:"
								   inputName="commerce_entrance_id"
								   :inputValue="this.$props.filters['commerce_entrance_id'] ?? null"
								   :inputOptions="this.entrance_types_list"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-2"
								   inputMultiple="true"
								   @setValue="(value) => this.setupFilter('commerce_entrance_id', value)" />

						<InputSelect v-if="!isBuildingGarage" inputId="building_ventilation_id"
									 inputLabel="Вентиляция:"
									 inputName="building_ventilation_id"
									 :inputValue="this.$props.filters['building_ventilation_id'] ?? null"
									 :inputOptions="this.building_ventilations_list"
									 inputClass="rounded-1 p-2"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setupFilter('building_ventilation_id', value)" />

						<InputSelect v-if="!isBuildingGarage" inputId="building_conditioning_id"
									 inputLabel="Кондиционирование:"
									 inputName="building_conditioning_id"
									 :inputValue="this.$props.filters['building_conditioning_id'] ?? null"
									 :inputOptions="this.building_conditioning_list"
									 inputClass="rounded-1 p-2"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setupFilter('building_conditioning_id', value)" />

						<InputSelect v-if="!isBuildingGarage" inputId="building_heating_id"
									 inputLabel="Отопление:"
									 inputName="building_heating_id"
									 :inputValue="this.$props.filters['building_heating_id'] ?? null"
									 :inputOptions="this.building_heating_list"
									 inputClass="rounded-1 p-2"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setupFilter('building_heating_id', value)" />

						<InputSelect v-if="!isBuildingGarage" inputId="building_firefighting_id"
									 inputLabel="Система пожаротушения:"
									 inputName="building_firefighting_id"
									 :inputValue="this.$props.filters['building_firefighting_id'] ?? null"
									 :inputOptions="this.building_firefighting_list"
									 inputClass="rounded-1 p-2"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setupFilter('building_firefighting_id', value)" />

						<InputSelect v-if="isBuildingGarage" inputId="garage_type_id"
									 inputLabel="Тип:"
									 inputName="garage_id"
									 :inputValue="this.$props.filters['garage_id'] ?? null"
									 :inputOptions="this.garages_list"
									 inputClass="rounded-1 p-2"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setupFilter('garage_id', value)" />

						<InputSelect v-if="!isBuildingGarage && this.$props.filters['garage_id'] == 2" inputId="garage_type_id"
									 inputLabel="Тип гаража:"
									 inputName="garage_type_id"
									 :inputValue="this.$props.filters['garage_type_id'] ?? null"
									 :inputOptions="this.garages_types_list"
									 inputClass="rounded-1 p-2"
									 inputWrapClass="flex-column mb-2"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setupFilter('garage_type_id', value)" />

						<InputSelect v-if="!isBuildingGarage && this.$props.filters['garage_id'] == 3" inputId="box_type_id"
									 inputLabel="Тип бокса:"
									 inputName="box_type_id"
									 :inputValue="this.$props.filters['box_type_id'] ?? null"
									 :inputOptions="this.boxes_types_list"
									 inputClass="rounded-1 p-2"
									 inputWrapClass="flex-column mb-2"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setupFilter('box_type_id', value)" />

						<InputSelect v-if="isBuildingGarage" inputId="commerce_status_id"
									 inputLabel="Статус:"
									 inputName="commerce_status_id"
									 :inputValue="this.$props.filters['commerce_status_id'] ?? null"
									 :inputOptions="this.commerce_status_list"
									 inputClass="rounded-1 p-2"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setupFilter('commerce_status_id', value)" />

						<SelectBox v-if="isBuildingGarage" inputId="commerce_specifications"
								   inputLabel="Технические характеристики:"
								   inputName="commerce_specifications"
								   :inputValue="this.$props.filters['commerce_specifications'] ?? null"
								   :inputOptions="this.commerce_specifications_list"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-3"
								   inputSearch="true"
								   inputMultiple="true"
								   @setValue="(value) => this.setupFilter('commerce_specifications', value)" />

						<SelectBox v-if="!this.$props.filters['commercial_type'].includes(3)" inputId="infrastructure_id"
								   inputLabel="Инфраструктура:"
								   inputName="infrastructure_id"
								   :inputValue="this.$props.filters['infrastructure_id'] ?? null"
								   :inputOptions="this.infrastructures_list"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-4"
								   inputSearch="true"
								   inputMultiple="true"
								   @setValue="(value) => this.setupFilter('infrastructure_id', value)" />


						<div v-if="showParkingType"
							 class="row-fluid">
							<div class="fs-2 my-3">Лифты</div>
							<div class="row row-cols-1">
								<div class="col">
									<InputNumber inputLabel="Грузовой:"
												 inputId="commerce_lifts_cargo_count"
												 inputName="commerce_lifts_cargo_count"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="шт."
												 :inputValue="this.$props.filters['commerce_lifts_cargo_count'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_lifts_cargo_count', value)" />
								</div>
								<div class="col mb-3">
									<InputNumber inputLabel="Грузоподъемность:"
												 inputId="commerce_lifts_cargo_carrying"
												 inputName="commerce_lifts_cargo_carrying"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="т."
												 :inputValue="this.$props.filters['commerce_lifts_cargo_carrying'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_lifts_cargo_carrying', value)" />
								</div>
							</div>
							<div class="row row-cols-1">
								<div class="col">
									<InputNumber inputLabel="Тельфер:"
												 inputId="commerce_lifts_telfer_count"
												 inputName="commerce_lifts_telfer_count"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="шт."
												 :inputValue="this.$props.filters['commerce_lifts_telfer_count'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_lifts_telfer_count', value)" />
								</div>
								<div class="col mb-3">
									<InputNumber inputLabel="Грузоподъемность:"
												 inputId="commerce_lifts_telfer_carrying"
												 inputName="commerce_lifts_telfer_carrying"
												 ref="commerce.lifts_telfer_carrying"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="т."
												 :inputValue="this.$props.filters['commerce_lifts_telfer_carrying'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_lifts_telfer_carrying', value)" />
								</div>
							</div>
							<div class="row row-cols-1">
								<div class="col">
									<InputNumber inputLabel="Пассажирский:"
												 inputId="commerce_lifts_passenger_count"
												 inputName="commerce_lifts_passenger_count"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="шт."
												 :inputValue="this.$props.filters['commerce_lifts_passenger_count'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_lifts_passenger_count', value)" />
								</div>
								<div class="col mb-3">
									<InputNumber inputLabel="Грузоподъемность:"
												 inputId="commerce_lifts_passenger_carrying"
												 inputName="commerce_lifts_passenger_carrying"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="т."
												 :inputValue="this.$props.filters['commerce_lifts_passenger_carrying'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_lifts_passenger_carrying', value)" />
								</div>
							</div>
						</div>
						<div v-if="showParkingType"
							 class="row-fluid">
							<div class="fs-2 my-3">Крановое оборудование</div>
							<div class="row row-cols-1">
								<div class="col">
									<InputNumber inputLabel="Мостовой кран:"
												 inputId="commerce_cranes_overhead_count"
												 inputName="commerce_cranes_overhead_count"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="шт."
												 :inputValue="this.$props.filters['commerce_cranes_overhead_count'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_cranes_overhead_count', value)" />
								</div>
								<div class="col mb-3">
									<InputNumber inputLabel="Грузоподъемность:"
												 inputId="commerce_cranes_overhead_carrying"
												 inputName="commerce_cranes_overhead_carrying"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="т."
												 :inputValue="this.$props.filters['commerce_cranes_overhead_carrying'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_cranes_overhead_carrying', value)" />
								</div>
							</div>
							<div class="row row-cols-1">
								<div class="col">
									<InputNumber inputLabel="Кран-балка:"
												 inputId="commerce_cranes_cathead_count"
												 inputName="commerce_cranes_cathead_count"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="шт."
												 :inputValue="this.$props.filters['commerce_cranes_cathead_count'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_cranes_cathead_count', value)" />
								</div>
								<div class="col mb-3">
									<InputNumber inputLabel="Грузоподъемность:"
												 inputId="commerce_cranes_cathead_carrying"
												 inputName="commerce_cranes_cathead_carrying"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="т."
												 :inputValue="this.$props.filters['commerce_cranes_cathead_carrying'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_cranes_cathead_carrying', value)" />
								</div>
							</div>
							<div class="row row-cols-1">
								<div class="col">
									<InputNumber inputLabel="Ж/д кран:"
												 inputId="commerce_cranes_railway_count"
												 inputName="commerce_cranes_railway_count"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="шт."
												 :inputValue="this.$props.filters['commerce_cranes_railway_count'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_cranes_railway_count', value)" />
								</div>
								<div class="col mb-3">
									<InputNumber inputLabel="Грузоподъемность:"
												 inputId="commerce_cranes_railway_carrying"
												 inputName="commerce_cranes_railway_carrying"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="т."
												 :inputValue="this.$props.filters['commerce_cranes_railway_carrying'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_cranes_railway_carrying', value)" />
								</div>
							</div>
							<div class="row row-cols-1">
								<div class="col">
									<InputNumber inputLabel="Козловой кран:"
												 inputId="commerce_cranes_gantry_count"
												 inputName="commerce_cranes_gantry_count"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="шт."
												 :inputValue="this.$props.filters['commerce_cranes_gantry_count'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_cranes_gantry_count', value)" />
								</div>
								<div class="col mb-3">
									<InputNumber inputLabel="Грузоподъемность:"
												 inputId="commerce_cranes_gantry_carrying"
												 inputName="commerce_cranes_gantry_carrying"
												 inputClass="rounded-1 mb-10"
												 inputGroupClass="col w-50"
												 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
												 inputLabelClass="col w-50 text-dark-gray"
												 inputAppend="т."
												 :inputValue="this.$props.filters['commerce_cranes_gantry_carrying'] ?? null"
												 @setValue="(value) => this.setupFilter('commerce_cranes_gantry_carrying', value)" />
								</div>
							</div>
						</div>

					</div>

					<Switcher inputId="with_photo"
							  inputName="with_photo"
							  inputLabel="Только с фотографиями"
							  :inputValue="this.$props.filters['with_photo']"
							  inputClass="px-0"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setupFilter('with_photo', value)" />

					<Switcher inputId="company_objects"
							  inputName="company_objects"
							  inputLabel="Объекты компании"
							  :inputValue="this.$props.filters['company_objects']"
							  inputClass="px-0"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setupFilter('company_objects', value)" />

					<Switcher inputId="with_commission"
							  inputName="with_commission"
							  inputLabel="С комиссией"
							  :inputValue="this.$props.filters['with_commission']"
							  inputClass="px-0"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setupFilter('with_commission', value)" />

					<SelectBox v-if="this.$props.filters['company_objects']"
							   :inputLabelClass="'text-font-secondary mb-3'"
							   :inputId="'filter_employees'"
							   :inputName="'filter_employees'"
							   :inputLabel="'Ответственный'"
							   :inputOptions="this.employees_list"
							   :inputClass="'rounded-1 p-2'"
							   inputMultiple="true"
							   inputSearch="true"
							   :inputWrapClass="'mb-20'"
							   :inputValue="this.$props.filters['employees']"
							   @setValue="(value) => this.setupFilter('employees', value)" />

					<Switcher v-if="this.$props.filters['company_objects']" inputId="in_advert"
							  inputName="in_advert"
							  inputLabel="В рекламе"
							  :inputValue="this.$props.filters['in_advert']"
							  inputClass="px-0"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setupFilter('in_advert', value)" />

					<InputText inputId="object_id"
							   inputLabel="ID объекта в системе"
							   inputName="object_id"
							   inputClass="rounded-1 mb-0"
							   :inputValue="this.$props.filters['object_id']"
							   inputWrapClass="flex-column mb-3"
							   :inputDisabled="this.is_restricted_access"
							   @setValue="(value) => this.setupFilter('object_id', value)" />

<!--					<InputText v-if="this.user_region_code == 78"
							   inputId="id_ap"
							   inputLabel="ID в «Аренда-Питер»"
							   inputName="id_ap"
							   inputPlaceholder="XXXXXX"
							   inputClass="rounded-1 mb-0"
							   :inputValue="this.$props.filters['id_ap']"
							   inputWrapClass="flex-column mb-3"
							   :inputDisabled="this.is_restricted_access"
							   @setValue="(value) => this.setupFilter('id_ap', value)"
							   :inputHelpText="this.getHelpText('id_ap')" />-->

<!--					<InputText v-if="this.user_region_code == 78"
							   inputId="id_emls"
							   inputLabel="ID в EMLS"
							   inputName="id_emls"
							   inputPlaceholder="XXXXXX"
							   inputClass="rounded-1 mb-0"
							   :inputValue="this.$props.filters['id_emls']"
							   inputWrapClass="flex-column mb-3"
							   :inputDisabled="this.is_restricted_access"
							   @setValue="(value) => this.setupFilter('id_emls', value)"
							   :inputHelpText="this.getHelpText('id_emls')" />-->

					<DateTimePicker inputId="date_add"
									inputName="date_add"
									ref="date_add"
									inputLabel="Не позднее:"
									inputLabelClass="text-font-secondary mb-3"
									inputClass="rounded-3 p-2"
									inputWrapClass="mb-20"
									:inputValue="this.$props.filters['date_add']"
									displayFormat="DD.MM.YYYY"
									@setValue="(value) => this.setupFilter('date_add', value)" />

					<TextArea inputId="search_query"
							  inputLabel="Поиск в описании"
							  inputName="search_query"
							  :inputValue="this.$props.filters['search_query']"
							  inputClass="rounded-1 mb-10"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setupFilter('search_query', value)"
							  inputHelpText="Введите слово или фразу из описания объекта" />

				</div>
				<div v-if="(this.$props.filters['company_objects'] || this.$props.filters['search_target'] == 5)">
					<div class="form-label text-font-primary fs-1 mb-3">Дополнительные поля</div>
					<CustomFieldsList  :isFilter="true" :custom-fields="this.custom_fields_list"
									  @setValue="(data) => this.setupFilter('custom_fields.' + data.name, data.value)" />
				</div>
				<ToggleButtons v-if="this.search_targets"
							   inputId="search_target"
							   inputName="search_target"
							   inputLabel="Источник"
							   inputType="radio"
							   inputLabelClass="text-font-primary fs-1 mb-3"
							   :inputValue="this.$props.filters['search_target']"
							   :inputOptions="this.search_targets"
							   :inputDisabled="Boolean(this.$props.filters['company_objects'])"
							   inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
							   inputWrapClass="flex-column mb-3"
							   @setValue="(value) => this.setupFilter('search_target', value)" />

			</div>
		</div>

		<!-- Фильтры: объекты -->
		<div v-if="this.$props.section == 'objects'" class="filter-block">
			<div class="d-flex align-items-start p-20">
				<span class="me-auto fs-2">Фильтр</span>
				<button type="button" class="btn-close ms-auto me-0" @click="this.$emit('closeFilter', true)"></button>
			</div>
			<div v-if="options.types" class="">
				<ul class="p-0 m-0">
					<li v-for="(item, value) in this.options.types"
						:key="value"
						class="border-top border-brd-primary p-20 position-relative">
						<a href="javascript:{}"
						   role="button"
						   class="stretched-link fs-3 font-semi fw-semi text-font-secondary"
						   :class="{ 'text-black': (this.$props.filters['operation_type'] == value) }"
						   @click="this.setupFilter('operation_type', value)">

							<span class="me-10">{{ item.label }}</span>

							<img v-if="item.icon"
								 :src="require(item.icon)"
								 alt="Icon"
								 class="me-10"
								 width="24"
								 height="24">

							<span v-if="item.count" class="badge bg-primary rounded-1">
								<span v-text="item.count"></span>
								<span class="visually-hidden">{{ item.title }}</span>
							</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div v-if="this.$props.section == 'objects'" class="filter-block mb-20">
			<div class="mb-20 border-top border-brd-primary px-20 pt-20">
				<span class="fs-2">Параметры</span>
			</div>
			<div class=" px-20">
								<InputText inputId="price_from"
									inputName="price_from"
									ref="price_from"
									inputLabel="Цена, от:"
									inputLabelClass="text-font-secondary mb-3"
									inputClass="rounded-3 p-2"
									inputWrapClass="mb-2"
									:inputValue="(!this.isEmpty(this.$props.filters['price_from'])) ? this.$props.filters['price_from'] : null"
									:inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
									inputMode="numeric"
									inputAppend=" руб."
									inputStep="10000"
									inputMin="0"
									inputMax="2147000000"
									inputIsPrice="true"
									@setValue="(value) => this.setupFilter('price_from', value)"
								/>

								<InputText inputId="price_to"
									inputName="price_to"
									ref="price_to"
									inputLabel="Цена, до:"
									inputLabelClass="text-font-secondary mb-3"
									inputClass="rounded-3 p-2"
									inputWrapClass="mb-20"
									:inputValue="(!this.isEmpty(this.$props.filters['price_to'])) ? this.$props.filters['price_to'] : null"
									:inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
									inputMode="numeric"
									inputAppend=" руб."
									inputStep="10000"
									inputMin="0"
									inputMax="2147000000"
									inputIsPrice="true"
									@setValue="(value) => this.setupFilter('price_to', value)"
								/>
                <SelectBox :inputLabelClass="'text-font-secondary mb-3'"
						   :inputId="'filter_employees'"
						   :inputName="'filter_employees'"
						   :inputLabel="'Ответственный'"
						   :inputOptions="this.employees_list"
						   :inputClass="'rounded-1 p-2'"
						   :inputValue="this.$props.filters['employees']"
						   inputMultiple="true"
						   inputSearch="true"
						   :inputWrapClass="'mb-20'"
						   @setValue="(value) => this.setupFilter('employees', value)" />

                <SelectBox :inputLabelClass="'text-font-secondary mb-3'"
						   :inputId="'filter_types'"
						   :inputName="'filter_types'"
						   :inputLabel="'Тип'"
						   :inputOptions="this.types_list"
						   :inputClass="'rounded-1 p-2'"
						   :inputValue="this.$props.filters['type']"
						   inputMultiple="true"
						   :inputWrapClass="'mb-20'"
						   @setValue="(value) => this.setupFilter('type', value)" />

                <SelectBox :inputLabelClass="'text-font-secondary mb-3'"
						   :inputId="'filter_tags'"
						   :inputName="'filter_tags'"
						   :inputLabel="'Теги'"
						   :inputOptions="this.tags_list"
						   :inputClass="'rounded-1 p-2'"
						   :inputValue="this.$props.filters['activities']"
						   inputMultiple="true"
						   inputSearch="true"
						   :inputWrapClass="'mb-20'"
						   @setValue="(value) => this.setupFilter('activities', value)" />

				<Switcher inputId="is_contract"
						  inputLabel="Договоры"
						  inputName="is_contract"
						  :inputValue="(!this.isEmpty(this.$props.filters['is_contract'])) ? this.$props.filters['is_contract'] : 0"
						  inputClass="px-0"
						  inputWrapClass="flex-column mb-3"
						  @setValue="(value) => this.setupFilter('is_contract', value)" />

				<div v-if="(!this.isEmpty(this.$props.filters['is_contract'])) ? true : false" class="row mt-3">

					<SelectBox inputId="contract_type"
							   inputName="contract_type"
							   inputLabelClass="text-font-secondary mb-3"
							   inputLabel="Тип договора"
							   :inputOptions="this.contract_types"
							   :inputValue="this.$props.filters['contract_type']"
							   inputClass="rounded-1 p-2"
							   inputMultiple="true"
							   inputSearch="true"
							   inputWrapClass="flex-column mb-3"
							   @setValue="(value) => this.setupFilter('contract_type', value)" />

					<InputText inputId="contract_number"
							   inputName="contract_number"
							   inputLabelClass="text-font-secondary mb-3"
							   inputLabel="Номер договора"
							   :inputValue="this.$props.filters['contract_number']"
							   inputClass="rounded-1 p-2"
							   inputWrapClass="flex-column mb-3"
							   @setValue="(value) => this.setupFilter('contract_number', value)" />

					<div class="d-flex flex-column">
						<div class="row mb-3">
							<div class="col-12 mb-3">
								<span class="text-font-secondary">Период окончания договора</span>
							</div>
							<div class="col-6">
								<DateTimePicker inputId="contract_start"
												inputName="contract_start"
												ref="contract_start"
												inputLabel="От"
												inputLabelClass="col-4 pt-2 text-font-secondary text-center"
												inputGroupClass="col"
												inputClass="rounded-3 p-2"
												inputWrapClass="row"
												:inputValue="this.$props.filters['contract_start']"
												displayFormat="DD.MM.YYYY"
												@setValue="(value) => this.setupFilter('contract_start', value)" />
							</div>
							<div class="col-6">
								<DateTimePicker inputId="contract_end"
												inputName="contract_end"
												ref="contract_end"
												inputLabel="до"
												inputLabelClass="col-4 pt-2 text-font-secondary text-center"
												inputGroupClass="col"
												inputClass="rounded-3 p-2"
												inputWrapClass="row"
												:inputValue="this.$props.filters['contract_end']"
												displayFormat="DD.MM.YYYY"
												@setValue="(value) => this.setupFilter('contract_end', value)" />
							</div>
						</div>
					</div>
				</div>

        <SelectBox
            :inputLabelClass="'text-font-secondary mb-3'"
            :inputId="'filter_ads_state'"
            :inputName="'filter_ads_state'"
            :inputLabel="'Рекламный статус'"
            :inputOptions="this.ads_state_list"
            :inputValue="this.$props.filters['ads_state']"
            :inputClass="'rounded-1 p-2'"
            inputMultiple="true"
            :inputWrapClass="'mb-20'"
            @setValue="(value) => this.setupFilter('ads_state', value)"
        />

        <div class="mt-3">
          <button type="button"
                  aria-label="С отчётами"
                  class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
                  :class="{ 'active': (this.$props.filters['advertised'] && !this.isEmpty(this.$props.filters['advertised']['zipal'])) ? this.$props.filters['advertised']['zipal'] : 0 }"
                  @click="this.setupFilter('advertised.zipal', (this.$props.filters['advertised']['zipal'] = !this.$props.filters['advertised']['zipal']))">
            С отчётами
          </button>
          <button type="button"
                  aria-label="Бесплатные"
                  class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
                  :class="{ 'active': (this.$props.filters['advertised'] && !this.isEmpty(this.$props.filters['advertised']['free'])) ? this.$props.filters['advertised']['free'] : 0 }"
                  @click="this.setupFilter('advertised.free', (this.$props.filters['advertised']['free'] = !this.$props.filters['advertised']['free']))">
            Бесплатные
          </button>
          <button type="button"
                  aria-label="Пакеты"
                  class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
                  :class="{ 'active': (this.$props.filters['advertised'] && !this.isEmpty(this.$props.filters['advertised']['packages'])) ? this.$props.filters['advertised']['packages'] : 0 }"
                  @click="this.setupFilter('advertised.packages', (this.$props.filters['advertised']['packages'] = !this.$props.filters['advertised']['packages']))">
            Пакеты
          </button>
        </div>
        <div class="mt-3 button-filtr-advert">
          <button
              type="button"
              aria-label="Авито"
              class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
              :class="{ 'active': (this.$props.filters['advertised'] && !this.isEmpty(this.$props.filters['advertised']['avito'])) ? this.$props.filters['advertised']['avito'] : 0 }"
              @click="this.setupFilter('advertised.avito', (this.$props.filters['advertised']['avito'] = !this.$props.filters['advertised']['avito']))">
            <img :src="require('@/assets/svg/avito-logo.svg')" alt="Авито" height="32" />
          </button>
          <button
              type="button"
              aria-label="Циан"
              class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
              :class="{ 'active': (this.$props.filters['advertised'] && !this.isEmpty(this.$props.filters['advertised']['cian'])) ? this.$props.filters['advertised']['cian'] : 0 }"
              @click="this.setupFilter('advertised.cian', (this.$props.filters['advertised']['cian'] = !this.$props.filters['advertised']['cian']))">
            <img :src="require('@/assets/svg/cian-logo.svg')" alt="Циан" height="32" />
          </button>
          <button
              type="button"
              aria-label="ДомКлик"
              class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
              :class="{ 'active': (this.$props.filters['advertised'] && !this.isEmpty(this.$props.filters['advertised']['domclick'])) ? this.$props.filters['advertised']['domclick'] : 0 }"
              @click="this.setupFilter('advertised.domclick', (this.$props.filters['advertised']['domclick'] = !this.$props.filters['advertised']['domclick']))">
            <img :src="require('@/assets/svg/domclick-logo.svg')" alt="ДомКлик" height="32" />
          </button>
          <button
              type="button"
              aria-label="JCat"
              class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
              :class="{ 'active': (this.$props.filters['advertised'] && !this.isEmpty(this.$props.filters['advertised']['jcat'])) ? this.$props.filters['advertised']['jcat'] : 0 }"
              @click="this.setupFilter('advertised.jcat', (this.$props.filters['advertised']['jcat'] = !this.$props.filters['advertised']['jcat']))">
            <img :src="require('@/assets/svg/jcat-logo.svg')" alt="JCat" height="32" />
          </button>
          <button
              type="button"
              aria-label="JCat"
              class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
              :class="{ 'active': (this.$props.filters['advertised'] && !this.isEmpty(this.$props.filters['advertised']['yandex'])) ? this.$props.filters['advertised']['yandex'] : 0 }"
              @click="this.setupFilter('advertised.yandex', (this.$props.filters['advertised']['yandex'] = !this.$props.filters['advertised']['yandex']))">
            <img :src="require('@/assets/svg/yandex-logo.svg')" alt="Яндекс" height="32" />
          </button>
		  
		  <button
              type="button"
              aria-label="Продвижение"
              class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
              :class="[(this.$props.filters.promo && !this.isEmpty(this.$props.filters.promo)) ? 'active' : '']"
              data-bs-toggle="modal"
                   	data-bs-target="#advertPromotionModalObjects"
                   	:data-bs-object-id="null"
					data-bs-place="filters"
					title="Продвижение">
			  Продвижение
          </button>
		 
        </div>

				<div class="mt-3">
					<div class="d-inline-flex w-auto me-auto">
						<button
							type="button"
							aria-label="Поиск"
							class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
							:class="{ 'active': !this.isEmpty(this.$props.filters['custom_fields']) }"
							data-bs-toggle="modal"
							data-bs-target="#customFieldsFilterModal">
							Дополнительные поля
						</button>
						<button v-if="!this.isEmpty(this.$props.filters['custom_fields'])"
								type="button"
								class="crm-btn btn btn-outline-secondary bg-light-gray ms-2 me-auto border border-brd-primary rounded-1"
								aria-label="Очистить дополнительные поля"
								@click="this.$props.filters['custom_fields'] = null;">
							<img :src="require('@/assets/icons/delete.svg')" alt="Очистить" width="14" height="14">
						</button>
					</div>
                </div>
			</div>
		</div>

		<!-- Фильтры: реклама -->
		<div v-if="this.$props.section == 'adverts'" class="filter-block">
			<div class="d-flex align-items-start p-20">
				<span class="me-auto fs-2">Фильтр</span>
				<button type="button" class="btn-close ms-auto me-0" @click="this.$emit('closeFilter', true)"></button>
			</div>
			<div v-if="options.types" class="">
				<ul class="p-0 m-0">
					<li v-for="(type, index) in options.types" :key="index" class="border-top border-brd-primary p-20 position-relative">
						<a href="javascript:{}"
						   role="button"
						   class="stretched-link fs-3 font-semi fw-semi text-font-secondary"
						   :class="{ 'text-black': (this.$props.filters['operation_type'] == index) }"
						   @click="this.setupFilter('operation_type', index)">
							{{ type.label }}
							<span v-if="type.count" class="badge bg-primary rounded-1">
								<span v-text="type.count"></span>
								<span class="visually-hidden">{{ type.title }}</span>
							</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div v-if="this.$props.section == 'adverts'" class="filter-block mb-20">
			<div class="mb-20 border-top border-brd-primary px-20 pt-20">
				<span class="fs-2">Параметры</span>
			</div>
			<div class=" px-20">
								<InputText inputId="price_from"
									inputName="price_from"
									ref="price_from"
									inputLabel="Цена, от:"
									inputLabelClass="text-font-secondary mb-3"
									inputClass="rounded-3 p-2"
									inputWrapClass="mb-2"
									:inputValue="(!this.isEmpty(this.$props.filters['price_from'])) ? this.$props.filters['price_from'] : null"
									:inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
									inputMode="numeric"
									inputAppend=" руб."
									inputStep="10000"
									inputMin="0"
									inputMax="2147000000"
									inputIsPrice="true"
									@setValue="(value) => this.setupFilter('price_from', value)"
								/>
								<InputText inputId="price_to"
									inputName="price_to"
									ref="price_to"
									inputLabel="Цена, до:"
									inputLabelClass="text-font-secondary mb-3"
									inputClass="rounded-3 p-2"
									inputWrapClass="mb-20"
									:inputValue="(!this.isEmpty(this.$props.filters['price_to'])) ? this.$props.filters['price_to'] : null"
									:inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
									inputMode="numeric"
									inputAppend=" руб."
									inputStep="10000"
									inputMin="0"
									inputMax="2147000000"
									inputIsPrice="true"
									@setValue="(value) => this.setupFilter('price_to', value)"
								/>
                <SelectBox
                    :inputLabelClass="'text-font-secondary mb-3'"
                    :inputId="'filter_employees'"
                    :inputName="'filter_employees'"
                    :inputLabel="'Ответственный'"
                    :inputOptions="this.employees_list"
                    :inputValue="this.$props.filters['employees']"
                    :inputClass="'rounded-1 p-2'"
					inputMultiple="true"
					inputSearch="true"
                    :inputWrapClass="'mb-20'"
					@setValue="(value) => this.setupFilter('employees', value)"
                />

                <SelectBox
                    :inputLabelClass="'text-font-secondary mb-3'"
                    :inputId="'filter_types'"
                    :inputName="'filter_types'"
                    :inputLabel="'Тип'"
                    :inputOptions="this.types_list"
					:inputValue="this.$props.filters['type']"
                    :inputClass="'rounded-1 p-2'"
					inputMultiple="true"
                    :inputWrapClass="'mb-20'"
					@setValue="(value) => this.setupFilter('type', value)"
                />

                <SelectBox
                    :inputLabelClass="'text-font-secondary mb-3'"
                    :inputId="'filter_tags'"
                    :inputName="'filter_tags'"
                    :inputLabel="'Теги'"
                    :inputOptions="this.tags_list"
					:inputValue="this.$props.filters['activities']"
                    :inputClass="'rounded-1 p-2'"
                    inputMultiple="true"
					inputSearch="true"
                    :inputWrapClass="'mb-20'"
					@setValue="(value) => this.setupFilter('activities', value)"
                />

				<InputNumberBetween inputId="commission"
									inputName="commission"
									inputLabel="Комиссия, %"
									inputFromPlaceholder="от"
									inputToPlaceholder="до"
									inputStep="1"
									inputMin="1"
									inputMax="100"
									:inputValueFrom="this.$props.filters['commission_from']"
									:inputValueTo="this.$props.filters['commission_to']"
									inputClass="rounded-1"
									inputWrapClass="flex-column mb-3"
									@setValue="(value) => {
										this.setupFilter('commission_from', value.from);
										this.setupFilter('commission_to', value.to);
									}" />

				<Switcher inputId="is_contract"
						  inputLabel="Договоры"
						  inputName="is_contract"
						  :inputValue="(!this.isEmpty(this.$props.filters['is_contract'])) ? this.$props.filters['is_contract'] : 0"
						  inputClass="px-0"
						  inputWrapClass="flex-column mb-3"
						  @setValue="(value) => this.setupFilter('is_contract', value)" />

				<div v-if="(!this.isEmpty(this.$props.filters['is_contract'])) ? true : false" class="row mt-3">

					<SelectBox inputId="contract_type"
							   inputName="contract_type"
							   inputLabelClass="text-font-secondary mb-3"
							   inputLabel="Тип договора"
							   :inputOptions="this.contract_types"
							   :inputValue="this.$props.filters['contract_type']"
							   inputClass="rounded-1 p-2"
							   inputMultiple="true"
							   inputSearch="true"
							   inputWrapClass="flex-column mb-3"
							   @setValue="(value) => this.setupFilter('contract_type', value)" />

					<InputText inputId="contract_number"
							   inputName="contract_number"
							   inputLabelClass="text-font-secondary mb-3"
							   inputLabel="Номер договора"
							   :inputValue="this.$props.filters['contract_number']"
							   inputClass="rounded-1 p-2"
							   inputWrapClass="flex-column mb-3"
							   @setValue="(value) => this.setupFilter('contract_number', value)" />

					<div class="d-flex flex-column">
						<div class="row mb-3">
							<div class="col-12 mb-3">
								<span class="text-font-secondary">Период окончания договора</span>
							</div>
							<div class="col-6">
								<DateTimePicker inputId="contract_start"
												inputName="contract_start"
												ref="contract_start"
												inputLabel="От"
												inputLabelClass="col-4 pt-2 text-font-secondary text-center"
												inputGroupClass="col"
												inputClass="rounded-3 p-2"
												inputWrapClass="row"
												:inputValue="this.$props.filters['contract_start']"
												displayFormat="DD.MM.YYYY"
												@setValue="(value) => this.setupFilter('contract_start', value)" />
							</div>
							<div class="col-6">
								<DateTimePicker inputId="contract_end"
												inputName="contract_end"
												ref="contract_end"
												inputLabel="до"
												inputLabelClass="col-4 pt-2 text-font-secondary text-center"
												inputGroupClass="col"
												inputClass="rounded-3 p-2"
												inputWrapClass="row"
												:inputValue="this.$props.filters['contract_end']"
												displayFormat="DD.MM.YYYY"
												@setValue="(value) => this.setupFilter('contract_end', value)" />
							</div>
						</div>
					</div>
				</div>

        <SelectBox
            :inputLabelClass="'text-font-secondary mb-3'"
            :inputId="'filter_ads_state'"
            :inputName="'filter_ads_state'"
            :inputLabel="'Рекламный статус'"
            :inputOptions="this.ads_state_list"
            :inputValue="this.$props.filters['ads_state']"
            :inputClass="'rounded-1 p-2'"
            inputMultiple="true"
            :inputWrapClass="'mb-20'"
            @setValue="(value) => this.setupFilter('ads_state', value)"
        />

				<div class="mt-3">
                    <button type="button"
							aria-label="С отчётами"
							class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
							:class="{ 'active': (!this.isEmpty(this.$props.filters['advertised']['zipal'])) ? this.$props.filters['advertised']['zipal'] : 0 }"
							@click="this.setupFilter('advertised.zipal', (this.$props.filters['advertised']['zipal'] = !this.$props.filters['advertised']['zipal']))">
                        С отчётами
                    </button>
                    <button type="button"
							aria-label="Бесплатные"
							class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
							:class="{ 'active': (!this.isEmpty(this.$props.filters['advertised']['free'])) ? this.$props.filters['advertised']['free'] : 0 }"
							@click="this.setupFilter('advertised.free', (this.$props.filters['advertised']['free'] = !this.$props.filters['advertised']['free']))">
                        Бесплатные
                    </button>
                    <button type="button"
							aria-label="Пакеты"
							class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
							:class="{ 'active': (!this.isEmpty(this.$props.filters['advertised']['packages'])) ? this.$props.filters['advertised']['packages'] : 0 }"
							@click="this.setupFilter('advertised.packages', (this.$props.filters['advertised']['packages'] = !this.$props.filters['advertised']['packages']))">
                        Пакеты
                    </button>
                </div>
                <div class="mt-3 button-filtr-advert">
                    <button
                        type="button"
                        aria-label="Авито"
                        class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
						:class="{ 'active': (!this.isEmpty(this.$props.filters['advertised']['avito'])) ? this.$props.filters['advertised']['avito'] : 0 }"
						@click="this.setupFilter('advertised.avito', (this.$props.filters['advertised']['avito'] = !this.$props.filters['advertised']['avito']))">
						<img :src="require('@/assets/svg/avito-logo.svg')" alt="Авито" height="32" />
                    </button>
                    <button
                        type="button"
                        aria-label="Циан"
                        class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
						:class="{ 'active': (!this.isEmpty(this.$props.filters['advertised']['cian'])) ? this.$props.filters['advertised']['cian'] : 0 }"
						@click="this.setupFilter('advertised.cian', (this.$props.filters['advertised']['cian'] = !this.$props.filters['advertised']['cian']))">
						<img :src="require('@/assets/svg/cian-logo.svg')" alt="Циан" height="32" />
                    </button>
                    <button
                        type="button"
                        aria-label="ДомКлик"
                        class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
						:class="{ 'active': (!this.isEmpty(this.$props.filters['advertised']['domclick'])) ? this.$props.filters['advertised']['domclick'] : 0 }"
						@click="this.setupFilter('advertised.domclick', (this.$props.filters['advertised']['domclick'] = !this.$props.filters['advertised']['domclick']))">
						<img :src="require('@/assets/svg/domclick-logo.svg')" alt="ДомКлик" height="32" />
                    </button>
                    <button
                        type="button"
                        aria-label="JCat"
                        class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
						:class="{ 'active': (!this.isEmpty(this.$props.filters['advertised']['jcat'])) ? this.$props.filters['advertised']['jcat'] : 0 }"
						@click="this.setupFilter('advertised.jcat', (this.$props.filters['advertised']['jcat'] = !this.$props.filters['advertised']['jcat']))">
						<img :src="require('@/assets/svg/jcat-logo.svg')" alt="JCat" height="32" />
                    </button>
                    <button
                        type="button"
                        aria-label="JCat"
                        class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
						:class="{ 'active': (!this.isEmpty(this.$props.filters['advertised']['yandex'])) ? this.$props.filters['advertised']['yandex'] : 0 }"
						@click="this.setupFilter('advertised.yandex', (this.$props.filters['advertised']['yandex'] = !this.$props.filters['advertised']['yandex']))">
						<img :src="require('@/assets/svg/yandex-logo.svg')" alt="Яндекс" height="32" />
                    </button>
					
					<button
						type="button"
						aria-label="Продвижение"
						class="btn btn-select btn-light-gray rounded-3 me-2 mb-2"
						:class="[(this.$props.filters.promo && !this.isEmpty(this.$props.filters.promo)) ? 'active' : '']"
						data-bs-toggle="modal"
								data-bs-target="#advertPromotionModalAdvert"
								:data-bs-object-id="null"
								data-bs-place="filters"
								title="Продвижение">
						Продвижение
					</button>
                </div>
				<div class="mt-3">
					<div class="d-inline-flex w-auto me-auto mb-2">
						<button
							type="button"
							aria-label="Поиск"
							class="btn btn-select btn-light-gray rounded-3 me-2"
							:class="{ 'active': !this.isEmpty(this.$props.filters['custom_fields']) }"
							data-bs-toggle="modal"
							data-bs-target="#customFieldsFilterModal">
							Дополнительные поля
						</button>
						<button v-if="!this.isEmpty(this.$props.filters['custom_fields'])"
								type="button"
								class="crm-btn btn btn-outline-secondary bg-light-gray ms-1 my-auto me-auto border border-brd-primary rounded-1"
								aria-label="Очистить дополнительные поля"
								@click="this.$props.filters['custom_fields'] = null;">
							<img :src="require('@/assets/icons/delete.svg')" alt="Очистить" width="14" height="14">
						</button>
					</div>
				</div>
			</div>
		</div>

        <!-- Фильтры: кабинет -->
        <div v-if="this.$props.section == 'charts'" class="filter-block mb-20">
            <div class="d-flex align-items-start p-20">
                <span class="me-auto fs-2">Фильтр</span>
				<button type="button" class="btn-close ms-auto me-0" @click="this.$emit('closeFilter', true)"></button>
            </div>
			<div class="p-20 pb-0">

                <SelectBox :inputLabelClass="'text-font-secondary mb-3'"
						   :inputId="'employees'"
						   :inputName="'employees'"
						   inputLabel="Ответственный"
						   :inputOptions="this.employees_list"
						   :inputValue="this.$props.filters['employees']"
						   :inputClass="'rounded-1 p-2'"
						   inputMultiple="true"
						   inputSearch="true"
						   :inputWrapClass="'mb-20'"
						   @setValue="(value) => this.setupFilter('employees', value)" />

                <SelectBox :inputLabelClass="'text-font-secondary mb-3'"
						   :inputId="'type'"
						   :inputName="'type'"
						   inputLabel="Тип"
						   :inputOptions="this.types_list"
						   :inputValue="this.$props.filters['type']"
						   :inputClass="'rounded-1 p-2'"
						   inputMultiple="true"
						   inputSearch="true"
						   :inputWrapClass="'mb-20'"
						   @setValue="(value) => this.setupFilter('type', value)" />

                <SelectBox :inputLabelClass="'text-font-secondary mb-3'"
						   :inputId="'activities'"
						   :inputName="'activities'"
						   inputLabel="Тэги"
						   :inputOptions="this.tags_list"
						   :inputValue="this.$props.filters['activities']"
						   :inputClass="'rounded-1 p-2'"
						   inputMultiple="true"
						   inputSearch="true"
						   :inputWrapClass="'mb-20'"
						   @setValue="(value) => this.setupFilter('activities', value)" />

                <SelectBox :inputLabelClass="'text-font-secondary mb-3'"
						   :inputId="'estate'"
						   :inputName="'estate'"
						   inputLabel="Тип недвижимости"
						   :inputOptions="this.estate_types_list"
						   :inputValue="this.$props.filters['estate']"
						   :inputClass="'rounded-1 p-2'"
						   inputMultiple="true"
						   inputSearch="true"
						   :inputWrapClass="'mb-20'"
						   @setValue="(value) => this.setupFilter('estate', value)" />

                <SelectBox :inputLabelClass="'text-font-secondary mb-3'"
						   :inputId="'roles'"
						   :inputName="'roles'"
						   inputLabel="Роль клиента"
						   :inputOptions="this.roles_list"
						   :inputValue="this.$props.filters['roles']"
						   :inputClass="'rounded-1 p-2'"
						   inputMultiple="true"
						   inputSearch="true"
						   :inputWrapClass="'mb-20'"
						   @setValue="(value) => this.setupFilter('roles', value)" />

                <SelectBox :inputLabelClass="'text-font-secondary mb-3'"
						   :inputId="'sources'"
						   :inputName="'sources'"
						   inputLabel="Источник"
						   :inputOptions="this.sources_list"
						   :inputValue="this.$props.filters['sources']"
						   :inputClass="'rounded-1 p-2'"
						   inputMultiple="true"
						   inputSearch="true"
						   :inputWrapClass="'mb-20'"
						   @setValue="(value) => this.setupFilter('sources', value)" />

                <SelectBox :inputLabelClass="'text-font-secondary mb-3'"
						   :inputId="'funnel_id'"
						   :inputName="'funnel_id'"
						   inputLabel="Воронка"
						   :inputOptions="this.funnels_list"
						   :inputValue="this.$props.filters['funnel_id']"
						   :inputClass="'rounded-1 p-2'"
						   inputMultiple="true"
						   inputSearch="true"
						   :inputWrapClass="'mb-20'"
						   @setValue="(value) => this.setupFilter('funnel_id', value)" />

            </div>

			<div class="d-flex flex-column px-20 mb-10">
				<span class="text-font-secondary mb-3">Категория клиента</span>
				<ul class="d-flex check-group flex-wrap align-items-center m-0 p-0">
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority1"
							   id="priority_1"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (1 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.1', event.target.value)">
						<label class="btn btn-raspberry btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_1" />
					</li>
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority2"
							   id="priority_2"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (2 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.2', event.target.value)">
						<label class="btn btn-warning btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_2" />
					</li>
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority3"
							   id="priority_3"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (3 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.3', event.target.value)">
						<label class="btn btn-ocean btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_3" />
					</li>
					<li class="me-20 mb-10">
						<input type="radio"
							   class="btn-check"
							   name="priority4"
							   id="priority_4"
							   value="1"
							   autocomplete="off"
							   :checked="(!this.isEmpty(this.$props.filters['priority']) && (4 in Object.entries(this.$props.filters['priority'])))"
							   @click="(event) => this.setupFilter('priority.4', event.target.value)">
						<label class="btn btn-primary btn-lg rounded-circle"
							   style="width: 50px; height: 50px;"
							   for="priority_4" />
					</li>
				</ul>
			</div>

            <div class="mb-20">
                <ul class="p-0 m-0">
                    <li class="border-top border-brd-primary p-20 position-relative">
                        <a href="javascript:{}" role="button" class="stretched-link fs-3 font-semi fw-semi text-font-secondary">
                            Сегодня
                        </a>
                    </li>
                    <li class="border-top border-brd-primary p-20 position-relative">
                        <a href="javascript:{}" role="button" class="stretched-link fs-3 font-semi fw-semi text-font-secondary">
                            Неделя
                        </a>
                    </li>
                    <li class="border-top border-brd-primary p-20 position-relative">
                        <a href="javascript:{}" role="button" class="stretched-link fs-3 font-semi fw-semi text-font-secondary">
                            Месяц
                        </a>
                    </li>
                    <li class="border-top border-brd-primary p-20 position-relative">
                        <a href="javascript:{}" role="button" class="stretched-link fs-3 font-semi fw-semi text-font-primary">
                            Квартал
                        </a>
                    </li>
                    <li class="border-top border-brd-primary p-20 position-relative">
                        <a href="javascript:{}" role="button" class="stretched-link fs-3 font-semi fw-semi text-font-secondary">
                            Год
                        </a>
                    </li>
                    <li class="border-top border-brd-primary p-20 position-relative">
                        <a href="javascript:{}" role="button" class="stretched-link fs-3 font-semi fw-semi text-font-secondary">
                            Все время
                        </a>
                    </li>

                    <li class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center border-top border-bottom border-brd-primary p-20 position-relative">
                        <span class="fs-3 font-semi fw-semi text-font-secondary me-2 mb-10 mb-sm-0">Период:</span>
                        <DatePickerInputBetween />
                    </li>
                </ul>
            </div>

            <div class="mb-20">
                <ul class="p-0 m-0">
                    <li class="border-top border-brd-primary p-20 position-relative">
                        <a href="javascript:{}" role="button" class="stretched-link fs-3 font-semi fw-semi text-font-primary">
                            Все задачи
                        </a>
                    </li>
                    <li class="border-top border-brd-primary p-20 position-relative">
                        <a href="javascript:{}" role="button" class="stretched-link fs-3 font-semi fw-semi text-font-secondary">
                            Открытые
                        </a>
                    </li>
                    <li class="border-top border-brd-primary p-20 position-relative">
                        <a href="javascript:{}" role="button" class="stretched-link fs-3 font-semi fw-semi text-font-secondary">
                            Просроченные
                        </a>
                    </li>
                    <li class="border-top border-bottom border-brd-primary p-20 position-relative">
                        <a href="javascript:{}" role="button" class="stretched-link fs-3 font-semi fw-semi text-font-secondary">
                            Выполненные
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="filter-footer bg-white w-100"
			 ref="stickyButton"
			 style="z-index: 10;"
			 :class="{'position-sticky border-top bottom-0 p-20': !(this.$props.section == 'search') }">
			<div class="btn-group w-100">
				<button
					type="button"
					class="btn btn-primary fw-bold col-9 rounded-3 text-white fs-3"
					@click="this.applyFilter()"
					v-text="(this.$props.section == 'search') ? 'Найти' : 'Применить'" />
				<button v-if="!(this.$props.section == 'search')" type="button"
						class="d-flex ms-3 my-auto btn col-3"
						@click="this.$emit('closeFilter')">
					<img :src="require('@/assets/icons/arr-sm-up-icon.svg')" class="m-auto mt-1 icon" alt="Свернуть" width="18" height="18">
				</button>
			</div>
        </div>
		
    </div>
	
</template>

<style>
	.button-filtr-advert .btn-select{
		width: 125px;
		margin-left: 0.5rem;
	}
</style>

<script>

	import SelectBox from '@/components/inputs/SelectBox'
    import Switcher from '@/components/inputs/Switcher'
    import CommonService from '@/services/CommonService'
	import api from "@/api";
	import ToggleButtons from "@/components/inputs/ToggleButtons";
	import InputNumberBetween from "@/components/inputs/InputNumberBetween";
	//import InputRangeBetween from "@/components/inputs/InputRangeBetween";
	import InputText from "@/components/inputs/InputText";
	import InputNumber from "@/components/inputs/InputNumber";
	import InputSelect from "@/components/inputs/InputSelect";
	import TextArea from "@/components/inputs/TextArea";
	import DatePickerInputBetween from '@/components/inputs/DatePickerInputBetween'
	import CustomFieldsList from "@/components/common/CustomFieldsList";
	import DateTimePicker from "@/components/inputs/DateTimePicker";
	import moment from "moment";
	import InputCheckbox from "@/components/inputs/InputCheckbox";
	import {CommonDataService} from "@/services/CommonDataService";

    export default {

        name: "Filters",

        components: {
			InputCheckbox,
			DateTimePicker,
			TextArea,
			InputSelect,
			InputNumber,
			InputText,
			//InputRangeBetween,
			InputNumberBetween,
			ToggleButtons,
            SelectBox,
            Switcher,
            DatePickerInputBetween,
			CustomFieldsList,
		//	AdvertPromotionModal
        },

        props: {
            isOpen: {type: Boolean},
			section: {type: String},
			filters: {type: Object},
			options: {type: Object}
        },

        data() {
            return {
				employees_list: [],
				countries_list: [],
				regions_list: [],
				districts_list: [],
				metro_list: [],
				to_metro_list: [],
				types_list: [],
				ads_state_list: [
          {value: 1, name: 'Не размещается'},
          {value: 2, name: 'Отправлен'},
          {value: 3, name: 'С ошибками'},
          {value: 4, name: 'Размещается'}
        ],
				lease_list: [],
				commercial_list: [],
				tags_list: [],
				estate_types_list: [],
				roles_list: [],
				sources_list: [],
				partners_list: [],
				denial_work_list: [],
				periods_list: [],
				stages_list: [],
				fields_list: [],
				search_targets: [],
				custom_fields_list: [],

				legal_address_list: [],
				occupieds_list: [],
				layouts_list: [],
				wet_spots_list: [],
				conditions_list: [],
				furnitures_list: [],
				access_list: [],
				parking_types_list: [],
				parking_sub_types_list: [],
				vat_types_list: [],

				floor_materials_list: [],
				commerce_purpose_list: [],
				commerce_portal_list: [],
				houseline_types_list: [],
				entrance_types_list: [],
				garages_types_list: [],
				commerce_status_list: [],
				commerce_specifications_list: [],

				garages_list: [],
				boxes_types_list: [],
				contract_types: [],

				commerce_land_categories_list: [],
				commerce_permitted_use_list: [],
				commerce_electricity_list: [],
				commerce_gas_list: [],
				commerce_water_list: [],
				commerce_sewage_list: [],
				commerce_driveways_list: [],

				building_types_list: [],
				building_classes_list: [],
				building_categories_list: [],
				building_ventilations_list: [],
				building_conditioning_list: [],
				building_heating_list: [],
				building_firefighting_list: [],
				commerce_infrastructures_list: [],
				commerce_infrastructures2_list: [],
				commerce_infrastructures3_list: [],

            }
        },
		methods: CommonService.mergeRecursive({
			setCommercialType() {
				this.setupFilter('commercial_type', this.commercial_list.filter((type) => type.checked).map((type) => type.value))
			},
			moment(config) {
				if (typeof config != "undefined")
					return moment(config);
				else
					return moment();
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			getTypes(section) {
				let _this = this;
				if (typeof section !== "undefined") {
					return api.get('/common/types', {
						params: {
							section: section
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							if (typeof _this.$store !== "undefined") {

								if (section == 'objects')
									_this.$store.commit('setObjectTypesList', list);

								if (section == 'clients')
									_this.$store.commit('setClientsTypesList', list);

								if (section == 'requisitions')
									_this.$store.commit('setRequisitionsTypesList', list);

								if (section == 'to_metro')
									_this.$store.commit('setToMetroTypesList', list);

								if (section == 'estate_types')
									_this.$store.commit('setEstateTypesList', list);

							}
							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getTypes()::axios', error);

					});
				}
			},
			open_filter_promo(){
				this.$refs.advertPromotionFilters.showModal();
				
			},
			getList(section) {
				let _this = this;
				if (typeof section !== "undefined") {
					return api.get('/common/list', {
						params: {
							section: section
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getList()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							/*if (typeof _this.$store !== "undefined") {

								if (section == 'objects')
									_this.$store.commit('setObjectTypesList', list);

								if (section == 'to_metro')
									_this.$store.commit('setToMetroTypesList', list);

							}*/

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getList()::axios', error);

					});
				}
			},
			getLists(sections) {
				return api.get('/common/lists', {
					params: {
						sections: sections
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getLists()::axios', {response: response.data});

					return response.data.lists;

				}).catch(function (error) {

					CommonService.log('error', 'getLists()::axios', error);

					return null;
				});
			},
			getEmployees() {

				let _this = this;
				let params = {
					add_groups: true
				};

				if (this.section == 'charts') {
					params.active = false;
					params.self_exclude = false;
				}

				return api.get('/common/employees', {
					params: params
				})
				.then((response) => {

					CommonService.log('debug', 'getEmployees()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						let groups = (response.data.groups) ? response.data.groups : [];
						//console.log(groups);

						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, employee]) => {

								if (employee.group_id) {

									let group_id = employee.group_id;
									if (groups[group_id]) {

										list.push({
											value: 'all_' + group_id,
											name: '<b>' + groups[group_id] + '</b>'
										});

										delete groups[group_id];
									}
								}

								let department_name = (employee.department)  ? ' (' + employee.department + ')' : '';
								list.push({
									value: employee.id,
									name: (employee.group_id) ? '&nbsp;&nbsp;-&nbsp;' + employee.name : employee.name + department_name
								});
							});
						}


						if (typeof _this.$store !== "undefined" && this.section != 'charts')
							_this.$store.commit('setEmployeesList', list);

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getEmployees()::axios', error);

				});
			},
			getRoles(section) {
				return api.get('/common/roles', {
					params: {
						section: section
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getRoles()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, role]) => {
								list.push({
									value: role.id.toString(),
									name: role.name
								});
							});
						}
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getRoles()::axios', error);

				});
			},
			getFunnels(section) {
				return api.get('requisitions/funnels', {
					params: {
						section: section
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getFunnels()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push(option);
						});

						if (section == 'clients')
							this.$store.commit('setFunnelsList', list);

						if (section == 'requisitions')
							this.$store.commit('setReqFunnels', list);

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getFunnels()::axios', error);

				});
			},
			getSources() { // @todo: need to remove, implement in `CommonDataService`

				let _this = this;
				return api.get('/common/sources', {
					params: {}
				})
				.then((response) => {

					CommonService.log('debug', 'getSources()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, source]) => {
								list.push({
									value: source.id.toString(),
									name: source.name
								});
							});
						}

						CommonService.log('info', 'Filters::getSources()', list);

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setSourcesList', list);

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getSources()::axios', error);

				});
			},
			getPartners() {

				let _this = this;
				return api.get('/partners/list', {
					params: {}
				})
				.then((response) => {

					CommonService.log('debug', 'getPartners()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						let groups = (response.data.groups) ? response.data.groups : [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, employee]) => {

								if (employee.group_id) {

									let group_id = employee.group_id;
									if (groups[group_id]) {

										list.push({
											value: 'all_' + group_id,
											name: '<b>' + groups[group_id] + '</b>'
										});

										delete groups[group_id];
									}
								}

								let positions = (employee.positions)  ? ' (' + employee.positions + ')' : '';
								list.push({
									value: employee.id,
									name: (this.isEmpty(positions)) ? '&nbsp;&nbsp;-&nbsp;' + employee.name : employee.name + positions
								});
							});
						}

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setPartnersList', list);

						return list;
					}

					return {};
				}).catch(function (error) {

					CommonService.log('error', 'getPartners()::axios', error);

				});
			},
			getCommercialTypes() {
				let _this = this;
				return api.get('/common/commercial').then((response) => {

					CommonService.log('debug', 'getCommercialTypes()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name,
							});
						});

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setCommercialList', list);

						return this.$store.getters.commercialList;
					}

				}).catch(function (error) {

					CommonService.log('error', 'getCommercialTypes()::axios', error);

				});
			},
			getSearchTargets() {
				let _this = this;
				return api.get('/common/types', {
					params: {
						section: 'search'
					}
				}).then((response) => {

					CommonService.log('debug', 'getSearchTargets()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setSearchTargets', list);

						return list;
					}

				}).catch(function (error) {

					CommonService.log('error', 'getSearchTargets()::axios', error);

				});
			},
			getRegions() {
				let _this = this;
				return api.get('/common/regions').then((response) => {

					CommonService.log('debug', 'getRegions()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name,
								kladr: option.kladr
							});
						});

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setRegionsList', list);

						return list;
					}

				}).catch(function (error) {

					CommonService.log('error', 'getRegions()::axios', error);

				});
			},
			getDistricts(region_id) {
				let _this = this;
				return api.get('/common/districts', {
					params: {
						//region_id: this.$store.user_info.region_id
						region_id: region_id ?? null
					}
				}).then((response) => {

					CommonService.log('debug', 'getDistricts()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						let groups = (response.data.groups) ? response.data.groups : [];

						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, district]) => {

								if (district.group_id) {

									let group_id = district.group_id;
									if (groups[group_id]) {

										list.push({
											value: '#',
											name: '<b>' + groups[group_id] + '</b>'
										});

										delete groups[group_id];
									}
								}

								list.push({
									value: district.id,
									name: district.name
								});
							});
						}

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setDistrictsList', list);

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getDistricts()::axios', error);

				});
			},
			getMetro(region_id) {
				let _this = this;
				return api.get('/common/metro', {
					params: {
						//region_id: this.$store.user_info.region_id
						region_id: region_id ?? null
					}
				}).then((response) => {

					CommonService.log('debug', 'getMetro()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setMetroList', list);

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getMetro()::axios', error);

				});
			},
			getCustomFields(section) {
				let _this = this;
				return api.get('/objects/custom-fields', {
					params: {
						section: section,
						is_active: true,
						is_filtrable: true,
					}
				}).then((response) => {

					CommonService.log('debug', 'getCustomFields()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = {
							fields: response.data.fields,
							blocks: response.data.blocks,
							types: response.data.types,
						};

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setCustomFieldsList', list);

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getCustomFields()::axios', error);

				});
			},
			getFieldsList(section) {
				return api.get('/fields', {
					params: {
						section: section,
						filtrable: true,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getFieldsList()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						return response.data.list;
					} else {
						return {};
					}
				}).catch(function (error) {

					CommonService.log('error', 'getFieldsList()::axios', error);

				});
			},
			getTags() {

				let _this = this;
				return api.get('/common/tags').then((response) => {

						CommonService.log('debug', 'getTags()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							if (typeof _this.$store !== "undefined")
								_this.$store.commit('setTagsList', list);

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

					CommonService.log('error', 'getTags()::axios', error);

				});
			},
			getStages(funnel_id) {
				return api.get('/common/stages-list', {
					params: {
						funnel_id: funnel_id
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getStages()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, item]) => {
								list.push({
									value: item.id,
									name: item.name
								});
							});
						}
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getStages()::axios', error);

				});
			},
			setupFilter(filter_name, filter_value) {
				let filter_parent = null;
				let filter_parts = filter_name.split('.', 2);
				if (filter_parts.length == 2) {
					filter_parent = filter_parts[0];
					filter_name = filter_parts[1];
				} else {
					if (['search', 'objects', 'adverts', 'active'].includes(this.$props.section)) {
						if (this.inArray(filter_name, ['price_from', 'price_to'])) {
							filter_value = parseInt(filter_value.replace(/\s/g, ''));
						}
					}
				}

				if (filter_parent && filter_name && filter_value) {

					if (!this.isEmpty(this.$props.filters[filter_parent])) {
						this.$props.filters[filter_parent] = {
							...{
								[filter_name]: filter_value
							},
							...this.$props.filters[filter_parent]
						};
					} else {
						this.$props.filters[filter_parent] = {
							[filter_name]: filter_value
						};
					}
				} else if (filter_name && filter_value) {
					if (filter_name == 'status') {
						if (this.$props.filters['status'] == filter_value)
							this.$props.filters['status'] = null;
						else
							this.$props.filters[filter_name] = filter_value;
					} else {
						this.$props.filters[filter_name] = filter_value;
					}
				} else {
					if (filter_parent)
						delete this.$props.filters[filter_parent][filter_name];
					else
						delete this.$props.filters[filter_name];
				}

				if (this.$props.filters['object_type'] !== 4)
					this.$props.filters['commercial_type'] = [];

				if (this.isEmpty(this.$props.filters['address']))
					this.$props.filters['address'] = null;

				if (this.isEmpty(this.$props.filters['search_query']))
					this.$props.filters['search_query'] = null;

				if (this.$props.section == 'search') {
					if (filter_name == 'company_objects' && Boolean(filter_value)) {
						this.$props.filters['search_target'] = 2;
					}
					if (filter_name == 'regions') {
						this.getDistricts(filter_value).then(data => {
							this.districts_list = data
						});
						this.getMetro(filter_value).then(data => {
							this.metro_list = data
						});
					}
				}

				if (this.$props.section == 'clients' || this.$props.section == 'requisitions') {
					if (this.$props.filters['is_no_tasks'] == 1) {
						this.$props.filters['is_tasks'] = 0;
						this.$props.filters['is_today_tasks'] = 0;
						this.$props.filters['is_over_tasks'] = 0;
					}

					if (this.$props.filters['is_tasks'] == 1)
						this.$props.filters['is_no_tasks'] = 0;

					if (this.$props.filters['is_today_tasks'] == 1)
						this.$props.filters['is_no_tasks'] = 0;

					if (this.$props.filters['is_over_tasks'] == 1)
						this.$props.filters['is_no_tasks'] = 0;

					if (filter_parent == 'fields' && !this.isEmpty(filter_value)) {
						this.$props.filters['fields'][filter_name] = filter_value;
					}
				}

				if (filter_name == 'period' && this.$props.filters['period'] != 9 && filter_value !== 9) {
					this.$props.filters['period_start'] = null;
					this.$props.filters['period_end'] = null;
				}

				if (process.env.NODE_ENV == "development") {
					if (!this.isEmpty(filter_parent)) {
						console.debug('setupFilter()', {
							name: filter_parent + '.' + filter_name,
							value: filter_value,
							filters: this.$props.filters
						});
					} else {
						console.debug('setupFilter()', {
							name: filter_name,
							value: filter_value,
							filters: this.$props.filters
						});
					}
				}

				//this.$emit('filterChange', { name: filter_name, value: filter_value }); //@todo: крашит значения инпутов с between
				this.$emit('setupFilter', this.$props.filters);
			},
			setupPriceFilter(value) {
				CommonService.debounce(() => {
					this.setupFilter('price_from', parseInt(value.from));
					this.setupFilter('price_to', parseInt(value.to));
				}, 1000);
			},
			applyFilter() {
				this.is_open = false;

				CommonService.log('debug', 'applyFilter()', this.$props.filters);

				this.$emit('applyFilter', this.$props.filters);
			},
			icon(path, class_name, title, size) {
				return CommonService.buildIconImage(path, class_name, title, size);
			},
			getCustomFieldValue(key) {
				
				if (!this.isEmpty(key) && !this.isEmpty(this.$props.filters['fields']))
					return this.$props.filters['fields'][key];
				
				return null;
			},
			getHelpText(input_name) {

				let help_text = '';

				if (input_name == 'search_id_ap')
					help_text += 'Введите ID объекта в системе Аренда-Питер.<br/>';
				else if (input_name == 'search_id_emls')
					help_text += 'Введите ID объекта в системе EMLS.<br/>';

				if (this.is_restricted_access) {
					help_text += this.icon(require('@/assets/icons/info.svg'), 'd-inline-flex ms-0 me-2', 'Info', [16, 16]);
					help_text += 'Доступно в платной версии.'
				}
				return help_text;
			},
		}, CommonDataService.methods),
		mounted() {

			if (this.section == "charts") {

				this.getEmployees().then(data => { this.employees_list = data });

				if (!this.isEmpty(this.$store.getters.clientsTypesList))
					this.types_list = this.$store.getters.clientsTypesList;
				else
					this.getTypes('clients').then(data => this.types_list = data);

				if (!this.isEmpty(this.$store.getters.tagsList))
					this.tags_list = this.$store.getters.tagsList;
				else
					this.getTags().then(data => this.tags_list = data);

				if (!this.isEmpty(this.$store.getters.estateTypesList))
					this.estate_types_list = this.$store.getters.estateTypesList;
				else
					this.getTypes('estate_types').then(data => this.estate_types_list = data);

				if (!this.isEmpty(this.$store.getters.clientsRolesList))
					this.roles_list = this.$store.getters.clientsRolesList;
				else
					this.getRoles('clients').then(data => this.roles_list = data);

				if (!this.isEmpty(this.$store.getters.sourcesList))
					this.sources_list = this.$store.getters.sourcesList;
				else
					this.getSources().then(data => this.sources_list = data);

				if (!this.isEmpty(this.$store.getters.clientsFunnels))
					this.funnels_list = this.$store.getters.clientsFunnels;
				else
					this.getFunnels('clients').then(data => this.funnels_list = data);

			} else if (this.section == "clients" || this.section == "requisitions") {

				if (!this.isEmpty(this.$store.getters.employeesList))
					this.employees_list = this.$store.getters.employeesList;
				else
					this.getEmployees().then(data => { this.employees_list = data });

				if (this.section == "clients") {

					if (!this.isEmpty(this.$store.getters.objectsTypesList))
						this.types_list = this.$store.getters.objectsTypesList;
					else
						this.getTypes('objects').then(data => { this.types_list = data });

				} else if (this.section == "requisitions") {

					/*if (!this.isEmpty(this.$store.getters.requisitionsTypesList))
						this.types_list = this.$store.getters.requisitionsTypesList;
					else*/
						this.getTypes('requisitions').then(data => { this.types_list = data });

				}

				if (this.section !== 'search') {
					if (!this.isEmpty(this.$store.getters.tagsList))
						this.tags_list = this.$store.getters.tagsList;
					else
						this.getTags().then(data => {
							this.tags_list = data
						});
				}

				if (this.section == "clients") {

					if (!this.isEmpty(this.$store.getters.estateTypesList))
						this.estate_types_list = this.$store.getters.estateTypesList;
					else
						this.getTypes('estate_types').then(data => this.estate_types_list = data);

					if (!this.isEmpty(this.$store.getters.clientsRolesList)) {
						this.roles_list = this.$store.getters.clientsRolesList;
					} else {
						this.getRoles('clients').then(data => {
							this.roles_list = data
						});
					}
				} else if (this.section == "requisitions") {
					if (!this.isEmpty(this.$store.getters.requisitionsRolesList)) {
						this.roles_list = this.$store.getters.requisitionsRolesList;
					} else {
						this.getRoles('requisitions').then(data => {
							this.roles_list = data
						});
					}
				}

				if (!this.isEmpty(this.$store.getters.sourcesList)) {
					this.sources_list = this.$store.getters.sourcesList;
				} else {
					this.getSources().then(data => { this.sources_list = data });
				}

				if (!this.isEmpty(this.$store.getters.partnersList)) {
					this.partners_list = this.$store.getters.partnersList;
				} else {
					this.getPartners().then(data => { this.partners_list = data });
				}

				this.getList('denial_work').then(data => { this.denial_work_list = data });

				this.getList('periods').then(data => { this.periods_list = data });

				let funnel_id = this.$props.filters['funnel_id'] ?? 0;
				this.getStages(funnel_id).then(data => { this.stages_list = data });

				this.getFieldsList(this.section).then(data => {
					this.fields_list = data;
					Object.entries(this.fields_list).forEach(field => {
						this.$props.filters.fields = {...this.$props.filters.fields, ...{
							[field[1].id]: field[1].value
						}};
					});
				});


			}

			if (this.inArray(this.section, ['objects', 'adverts', 'clients', 'requisitions'])) {

				if (!this.isEmpty(this.$store.state.contract_types))
					this.contract_types = this.$store.state.contract_types;
				else
					this.getTypes('contract_types').then(data => { this.contract_types = data });

			}

			if (this.section == "search") {

				if (!this.isEmpty(this.$store.state.obj_types_list))
					this.types_list = this.$store.state.obj_types_list;
				else
					this.getTypes('objects').then(data => { this.types_list = data });

				if (!this.isEmpty(this.$store.state.lease_list))
					this.lease_list = this.$store.state.lease_list;
				else
					this.getTypes('lease').then(data => { this.lease_list = data });

				if (!this.isEmpty(this.$store.state.commercialList))
					this.commercial_list = this.$store.state.commercialList;
				else
					this.getCommercialTypes().then(data => { this.commercial_list = data });

				if (!this.isEmpty(this.$store.getters.searchTargets))
					this.search_targets = this.$store.getters.searchTargets;
				else
					this.getSearchTargets().then(data => { this.search_targets = data });

				if (!this.isEmpty(this.$store.getters.regionsList))
					this.regions_list = this.$store.getters.regionsList;
				else
					this.getRegions().then(data => { this.regions_list = data });

				/*if (!this.isEmpty(this.$store.state.districts_list))
					this.districts_list = this.$store.state.districts_list;
				else
					this.getDistricts().then(data => { this.districts_list = data });*/

				this.getDistricts().then(data => { this.districts_list = data });

				/*if (!this.isEmpty(this.$store.state.metro_list))
					this.metro_list = this.$store.state.metro_list;
				else
					this.getMetro().then(data => { this.metro_list = data });*/

				this.getMetro().then(data => { this.metro_list = data });

				if (!this.isEmpty(this.$store.state.to_metro_list))
					this.to_metro_list = this.$store.state.to_metro_list;
				else
					this.getTypes('to_metro').then(data => { this.to_metro_list = data });

				if (!this.isEmpty(this.$store.getters.employeesList))
					this.employees_list = this.$store.getters.employeesList;
				else
					this.getEmployees().then(data => { this.employees_list = data });

				if (!this.isEmpty(this.$store.state.custom_fields_list))
					this.custom_fields_list = this.$store.state.custom_fields_list;
				else
					this.getCustomFields('objects').then(data => { this.custom_fields_list = data });

				let _this = this;
				this.getLists([
					'countries',
					'legal_address',
					'occupieds',
					'layouts',
					'wet_spots',
					'conditions',
					'commerce_purpose',
					'furnitures',
					'access',
					'parking_types',
					'parking_sub_types',

					'floor_materials',
					'commerce_portal',
					'houseline_types',
					'commerce_specifications',

					'vat_types',
					'building_types',
					'building_classes',
					'building_categories',
					'building_ventilations',
					'building_conditioning',
					'building_heating',
					'building_firefighting',
					'entrance_types',
					'garages',
					'garages_types',
					'boxes_types',

					'commerce_land_categories',
					'commerce_permitted_use',
					'commerce_electricity',
					'commerce_gas',
					'commerce_water',
					'commerce_sewage',
					'commerce_driveways',

					'commerce_status',
					'commerce_infrastructures',
					'commerce_infrastructures2',
					'commerce_infrastructures3',
				]).then(data => {

					if (!_this.isEmpty(data)) {
						Object.entries(data).forEach(([section, list]) => {
							if (!_this.isEmpty(list)) {
								for (var i = 0; i < list.length; i++) {
									list[i].value = list[i]['id'];
									delete list[i].id;
								}

								if (typeof _this[section + '_list'] !== "undefined") {
									if (this.inArray(section, [
										'commerce_purpose',
										'commerce_land_categories',
										'commerce_permitted_use'
									])) {
										_this[section + '_list'] = this.sortArray(list);
									} else {
										_this[section + '_list'] = list;
									}
								} else {
									CommonService.log('error', 'getLists()', 'Not found variable by name `' + section + '_list`');
								}
							}
						});
					}

					return _this;
				});




			} else if (this.section == "objects" || this.section == "adverts" || this.section == "not_in_adverts") {

				if (!this.isEmpty(this.$store.state.employees_list))
					this.employees_list = this.$store.state.employees_list;
				else
					this.getEmployees().then(data => { this.employees_list = data });

				if (!this.isEmpty(this.$store.state.obj_types_list))
					this.types_list = this.$store.state.obj_types_list;
				else
					this.getTypes('objects').then(data => { this.types_list = data });

				if (!this.isEmpty(this.$store.state.custom_fields_list))
					this.custom_fields_list = this.$store.state.custom_fields_list;
				else
					this.getCustomFields('custom-fields').then(data => { this.custom_fields_list = data });

				if (!this.isEmpty(this.$store.getters.tagsList))
					this.tags_list = this.$store.getters.tagsList;
				else
					this.getTags().then(data => {
						this.tags_list = data
					});

			}

			/*let _this = this;
			window.addEventListener('click', function(e) {
				if (!_this.isEmpty(_this.$refs.filters)) {
					if (!_this.$refs.filters.contains(e.target)) {
						_this.is_open = false;
					}
				}
			});*/

			/*if (this.section != "search") {

				const options = {
					root: this.$refs.filters,
					rootMargin: '0px',
					threshold: 1.0
				}

				const stickyButton = this.$refs.stickyButton;
				const observer = new window.IntersectionObserver(
					([e]) => {
						console.log(e.intersectionRatio);
						e.target.classList.toggle('is-sticky', e.intersectionRatio < 1);
					},
					options
				);

				observer.observe(stickyButton);
			}*/
		},
		watch: {
			isOpen: function(newVal, oldVal) {
				let backdrop = null;
				if (this.$props.section !== 'search') {
					if (this.$props.isOpen) {
						backdrop = document.createElement('div');
						backdrop.id = 'backdrop';
						backdrop.classList.add('offcanvas-backdrop', 'fade', 'show');
						backdrop.style.cssText = "z-index:998;";
						document.body.appendChild(backdrop)
					} else {
						let backdrop = document.getElementById('backdrop');
						if (typeof(backdrop) != 'undefined' && backdrop != null) {
							backdrop.remove();
						}
					}
				}

				return this.$props.isOpen;
			},
			funnel_id: function(newVal, oldVal) {
				if (this.$props.section == 'clients' || this.$props.section == 'requisitions') {
					if (newVal !== oldVal) {
						this.$props.filters['stages'] = null;
						this.setupFilter('stages', null);
						this.getStages(newVal).then(data => { this.stages_list = data });
					}
				}
			},
		},
		computed: {
			showHouselineType() {
				return [2, 3, 4, 6, 8].every((type) => this.$props.filters['commercial_type'].includes(type))
			},
			isCommercialOrGarage() {
				return [7, 9].every((type) => this.$props.filters['commercial_type'].includes(type))
			},
			showCommercePurpose() {
				return [3, 5, 8].every((type) => this.$props.filters['commercial_type'].includes(type));
			},
			isBuildingGarage() {
				return this.$props.filters['commercial_type'].includes(7);
			},
			showColumnGrid() {
				return [2, 4, 5].every((type) => this.$props.filters['commercial_type'].includes(type));
			},
			showCeilingHeight() {
				return [1, 3, 4, 7].every((type) => this.$props.filters['commercial_type'].includes(type));
			},
			showParkingTypeId() {
				return [1, 3, 5, 6, 7].every((type) => this.$props.filters['commercial_type'].includes(type));
			},
			showParkingType() {
				return [2, 4].every((type) => this.$props.filters['commercial_type'].includes(type));
			},
			showAccessId() {
				return [1, 4, 5].every((type) => this.$props.filters['commercial_type'].includes(type));
			},
			showFurniture() {
				return [1, 3, 5, 6, 8].every((type) => this.$props.filters['commercial_type'].includes(type));
			},
			isCommercialType() {
				return this.$props.filters['commercial_type'].includes(9)
			},
			showLayoutId() {
				return [1, 5].every((type) => this.$props.filters['commercial_type'].includes(type));
			},
			showOccupiedId() {
				return [1, 3, 5].every((type) => this.$props.filters['commercial_type'].includes(type));
			},
			showLegalAddress() {
				return [6, 7, 8, 9].every((type) => !this.$props.filters['commercial_type'].includes(type));
			},
			showTaxNumber() {
				return [7, 8].every((type) => !this.$props.filters['commercial_type'].includes(type))
			},
			showAreaLive() {
				return !this.inArray(this.$props.filters['object_type'], [4, 7]);
			},
			showNotFirstFloor() {
				return this.inArray(this.$props.filters['object_type'], [1, 2]) && !this.inArray(this.$props.filters['object_type'], [7, 9]);
			},
			showFlatBetween() {
				return !this.inArray(this.$props.filters['object_type'], [7]) && !this.isCommercialOrGarage;
			},
			showYearBetween() {
				return this.$props.filters['operation_type'] == 1 && !this.inArray(this.$props.filters['object_type'], [7]) && !this.$props.filters['commercial_type'].includes(9);
			},
			areaFullInputLabel() {
				return (this.inArray(this.$props.filters['object_type'], [7]) || (this.$props.filters['object_type'] == 4 && this.$props.filters['commercial_type'].includes(9))) ? 'Общая площадь, сот.' : 'Общая площадь, кв.м'
			},
			is_open: {
				get() {
					if (!this.isEmpty(this.$props.isOpen))
						return this.$props.isOpen;

					return false;
				},
				set(value) {
					value = (value.toString() === 'true');
				}
			},
			country_id: {
				get() {
					if (!this.isEmpty(this.$props.filters['country_id']))
						return this.$props.filters['country_id'];

					return 171;
				},
				set(value) {
					value = parseInt(value.trim());
				}
			},
			funnel_id: {
				get() {
					if (!this.isEmpty(this.$props.filters['funnel_id']))
						return this.$props.filters['funnel_id'];

					return 0;
				},
				set(value) {
					value = parseInt(value.trim());
				}
			},
			user_region_id() {
				if (!this.isEmpty(this.$store.getters.userInfo)) {
					if (typeof this.$store.getters.userInfo.region_id !== "undefined")
						return this.$store.getters.userInfo.region_id;
				}

				return null;
			},
			user_region_code() {
				if (!this.isEmpty(this.$store.getters.userInfo)) {
					if (typeof this.$store.getters.userInfo.region.code !== "undefined")
						return this.$store.getters.userInfo.region.code;
				}

				return null;
			},
			is_restricted_access() {
				if (!this.isEmpty(this.$store.getters.userInfo)) {
					if (typeof this.$store.getters.userInfo.restricted_access !== "undefined")
						return this.$store.getters.userInfo.restricted_access;
				}

				return false;
			},
			infrastructures_list() {
				if (this.inArray(this.$props.filters['commercial_type'], [4, 7]))
					return this.commerce_infrastructures2_list
				else if (this.$props.filters['commercial_type'] == 2)
					return this.commerce_infrastructures3_list
				else
					return this.commerce_infrastructures_list
			},
		},
        beforeUnmount() {
            let backdrop = document.getElementById('backdrop');

			if (typeof(backdrop) != 'undefined' && backdrop != null) {
				backdrop.remove();
			}
        }
	}
</script>