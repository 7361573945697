<template>
    <div class="modal fade modal-fullscreen" id="autoSearchEditModal" ref="autoSearchEditModal" tabindex="-1"
		 aria-labelledby="Параметры автопоиска"
		 data-bs-backdrop="static"
		 data-bs-keyboard="false"
		 aria-hidden="true">
        <div class="modal-dialog modal-fullscreen modal-fullscreen modal-dialog-scrollable">
            <div class="modal-content p-0 rounded-1">
                <div class="modal-header row p-0 pt-2 px-3">
                    <div class="d-flex col-12 align-items-center container-fluid pt-2 px-3 mb-2">
                        <span class="modal-title fs-2 fw-semi font-semi me-auto" style="max-width: 90%;">Параметры автопоиска</span>
                        <button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
					<div class="d-flex col-12 text-nowrap-mask scroller scroller-x px-20 pb-0 mb-0" role="tablist">
						<NavTabs :navItems="this.tabItems "/>
					</div>
                </div>
                <div class="modal-body px-4 border-0">
					<div v-if="!this.isEmpty(this.errors)" class="d-flex flex-column mb-10">
						<span class="alert alert-danger mb-2"
							  v-for="(error, index) in this.errors"
							  :key="index"
							  v-text="error" />
					</div>
					<div class="d-flex flex-column mb-10">
						<div class="form-label text-font-primary fs-2 mb-3">Основная информация</div>

						<ToggleButtons v-if="this.inArray(this.item.deal_type, [1, 2, 3])" inputId="object_type"
									   inputLabel="Тип объекта:"
									   inputName="object_type"
									   ref="object_type"
									   inputType="radio"
									   inputLabelClass="text-font-secondary mb-3"
									   :inputValue="(!this.isEmpty(this.item.object_type)) ? this.item.object_type : null"
									   :inputOptions="this.objects_types_list"
									   inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
									   inputWrapClass="flex-column mb-3"
									   inputRequired="true"
									   @setValue="(value) => this.setAndValidate('object_type', value)" />

						<ToggleButtons v-if="this.inArray(this.item.deal_type, [1, 2]) && this.item.object_type == 4" inputId="commercial_type"
									   inputLabel="Тип коммерции:"
									   inputName="commercial_type"
									   ref="commercial_type"
									   inputType="checkbox"
									   inputLabelClass="text-font-secondary mb-3"
									   :inputValue="(!this.isEmpty(this.item.commercial_type)) ? this.item.commercial_type : null"
									   :inputOptions="this.commercial_types_list"
									   inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
									   inputWrapClass="flex-column mb-3"
									   inputRequired="true"
									   @applyFilters="(value) => this.setAndValidate('commercial_type', value)"  />

						<ToggleButtons v-if="this.item.deal_type == 1" inputId="lease_type"
									   inputLabel="Срок аренды:"
									   inputName="lease_type"
									   ref="lease_type"
									   inputType="radio"
									   inputLabelClass="text-font-secondary mb-3"
									   :inputValue="(!this.isEmpty(this.item.lease_type)) ? this.item.lease_type : null"
									   :inputOptions="this.lease_types_list"
									   inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
									   inputWrapClass="flex-column mb-3"
									   inputRequired="true"
									   @setValue="(value) => this.setAndValidate('lease_type', value)" />

						<div v-if="this.inArray(this.item.deal_type, [1, 2, 3])" inputId="price" class="col">

							<Switcher inputId="use_area_price2"
							  inputName="use_area_price2"
							  inputLabel="Цена за квадратный метр"
							  :inputValue="this.item.use_area_price"
							  inputClass="px-0"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setAndValidate('use_area_price', value)" />

							<InputText inputId="price_from"
									   inputName="price_from"
									   ref="price_from"
									   inputLabel="Цена, от:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-2"
									   :inputValue="(!this.isEmpty(this.item.price_from)) ? this.item.price_from : null"
									   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
									   inputMode="numeric"
									   inputAppend=" руб."
									   inputStep="10000"
									   inputMin="0"
									   inputMax="2147000000"
									   inputIsPrice="true"
									   inputRequired="true"
									   @setValue="(value) => this.setAndValidate('price_from', value)" />
							
							<InputText inputId="price_to"
									   inputName="price_to"
									   ref="price_to"
									   inputLabel="Цена, до:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="(!this.isEmpty(this.item.price_to)) ? this.item.price_to : null"
									   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
									   inputMode="numeric"
									   inputAppend=" руб."
									   inputStep="10000"
									   inputMin="0"
									   inputMax="2147000000"
									   inputIsPrice="true"
									   inputRequired="true"
									   @setValue="(value) => this.setAndValidate('price_to', value)" />
						</div>

						<InputNumber v-if="this.item.deal_type == 2 && this.item.object_type == 2" inputId="rooms"
									 inputName="rooms"
									 inputLabel="Всего комнат в квартире:"
									 inputPlaceholder="до"
									 inputStep="1"
									 inputMin="1"
									 inputMax="30"
									 :inputValue="(!this.isEmpty(this.item.rooms)) ? this.item.rooms : null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 @setValue="(value) => this.setAndValidate('rooms', value)" />

						<InputNumberBetween v-if="this.inArray(this.item.object_type, [1, 3, 4, 5, 6, 7])" inputId="area_full"
											inputName="area_full"
											:inputLabel="(this.inArray(this.item.object_type, [7]) || (this.item.object_type == 4 && this.inArray(9, this.item.commercial_type))) ? 'Общая площадь, сот.:' : 'Общая площадь, кв.м:'"
											inputFromPlaceholder="от"
											inputToPlaceholder="до"
											inputStep="1"
											inputMin="0"
											inputMax="10000"
											:inputValueFrom="(!this.isEmpty(this.item.area_full_from)) ? this.item.area_full_from : null"
											:inputValueTo="(!this.isEmpty(this.item.area_full_to)) ? this.item.area_full_to : null"
											inputClass="rounded-1"
											inputWrapClass="flex-column mb-3"
											@setValue="(value) => {
												this.setAndValidate('area_full_from', value.from);
												this.setAndValidate('area_full_to', value.to);
											}" />

						<InputNumberBetween v-else-if="this.item.object_type == 2" inputId="area_full"
											inputName="area_full"
											inputLabel="Площадь комнаты, кв.м:"
											inputFromPlaceholder="от"
											inputToPlaceholder="до"
											inputStep="1"
											inputMin="0"
											inputMax="10000"
											:inputValueFrom="(!this.isEmpty(this.item.area_full_from)) ? this.item.area_full_from : null"
											:inputValueTo="(!this.isEmpty(this.item.area_full_to)) ? this.item.area_full_to : null"
											inputClass="rounded-1"
											inputWrapClass="flex-column mb-3"
											@setValue="(value) => {
												this.setAndValidate('area_full_from', value.from);
												this.setAndValidate('area_full_to', value.to);
											}" />

						<InputNumberBetween v-if="this.inArray(this.item.object_type, [1, 2, 3])" inputId="area_kitchen"
											inputName="area_kitchen"
											inputLabel="Площадь кухни, кв.м:"
											inputFromPlaceholder="от"
											inputToPlaceholder="до"
											inputStep="1"
											inputMin="1"
											inputMax="100"
											:inputValueFrom="(!this.isEmpty(this.item.area_kitchen_from)) ? this.item.area_kitchen_from : null"
											:inputValueTo="(!this.isEmpty(this.item.area_kitchen_to)) ? this.item.area_kitchen_to : null"
											inputClass="rounded-1"
											inputWrapClass="flex-column mb-3"
											@setValue="(value) => {
												this.setAndValidate('area_kitchen_from', value.from);
												this.setAndValidate('area_kitchen_to', value.to);
											}" />

            <ToggleButtons v-if="this.inArray(this.item.object_type, [1, 2, 3])" inputId="rooms"
                           inputLabel="Комнат:"
                           inputName="rooms"
                           ref="rooms"
                           inputType="checkbox"
                           inputLabelClass="text-font-secondary mb-3"
                           :inputValue="(!this.isEmpty(this.item.rooms)) ? this.item.rooms : null"
                           :inputOptions="[
                 { value: 1, name: '1 комн.' },
                 { value: 2, name: '2 комн.' },
                 { value: 3, name: '3 комн.' },
                 { value: 4, name: '4+ комн.' },
                 { value: 5, name: 'Студия' }
               ]"
                           inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
                           inputWrapClass="flex-column mb-3"
                           @setValue="(value) => this.setAndValidate('rooms', value)" />

            <InputNumberBetween v-if="this.inArray(this.item.object_type, [1, 3, 5, 6])" inputId="etazh"
                                inputName="etazh"
                                inputLabel="Этаж:"
                                inputFromPlaceholder="от"
                                inputToPlaceholder="до"
                                inputStep="1"
                                inputMin="1"
                                inputMax="80"
                                :inputValueFrom="(!this.isEmpty(this.item.flat_from)) ? this.item.flat_from : null"
                                :inputValueTo="(!this.isEmpty(this.item.flat_to)) ? this.item.flat_to : null"
                                inputClass="rounded-1"
                                inputWrapClass="flex-column mb-3"
                                @setValue="(value) => {
												this.setAndValidate('flat_from', value.from);
												this.setAndValidate('flat_to', value.to);
											}" />

            <InputNumberBetween v-if="this.inArray(this.item.object_type, [1, 3, 5, 6])" inputId="etazhnost"
                                inputName="etazhnost"
                                inputLabel="Этажность:"
                                inputFromPlaceholder="от"
                                inputToPlaceholder="до"
                                inputStep="1"
                                inputMin="1"
                                inputMax="80"
                                :inputValueFrom="(!this.isEmpty(this.item.etazhnost_ot)) ? this.item.etazhnost_ot : null"
                                :inputValueTo="(!this.isEmpty(this.item.etazhnost_do)) ? this.item.etazhnost_do : null"
                                inputClass="rounded-1"
                                inputWrapClass="flex-column mb-3"
                                @setValue="(value) => {
												this.setAndValidate('etazhnost_ot', value.from);
												this.setAndValidate('etazhnost_do', value.to);
											}" />

						<Switcher v-if="this.inArray(this.item.object_type, [1, 2])" inputId="not_first_floor"
								  inputName="not_first_floor"
								  inputLabel="Не первый/последний этаж"
								  :inputValue="(!this.isEmpty(this.item.not_first_floor)) ? this.item.not_first_floor : false"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('not_first_floor', value)" />


<!--						<Switcher v-if="this.inArray(this.item.object_type, [1, 2])" inputId="not_last_floor"
								  inputName="not_last_floor"
								  inputLabel="Не последний этаж"
								  :inputValueTo="(!this.isEmpty(this.item.not_last_floor)) ? this.item.not_last_floor : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('not_last_floor', value)" />-->

					</div>

					<div class="d-flex flex-column mb-10">
						<div class="form-label text-font-primary fs-2 mb-3">Местоположение</div>

						<SelectBox v-if="this.inArray(this.item.deal_type, [1, 2, 3]) && !this.isEmpty(this.regions_list)" inputId="regions"
								   inputName="regions"
								   inputLabelClass="text-font-secondary mb-3"
								   inputLabel="Субъект РФ (регион):"
								   ref="regions"
								   :inputOptions="this.regions_list"
								   :inputValue="(!this.isEmpty(this.item.regions)) ? this.item.regions : null"
								   inputClass="rounded-1 p-2"
								   inputRequired="true"
								   inputMultiple="true"
								   inputSearch="true"
								   inputWrapClass="mb-20"
								   @setValue="(value) => this.setAndValidate('regions', value)" />

						<SelectBox v-if="this.inArray(this.item.deal_type, [1, 2, 3]) && !this.isEmpty(this.districts_list) && !this.isEmpty(this.item.regions)" inputId="districts"
								   inputName="districts"
								   inputLabelClass="text-font-secondary mb-3"
								   inputLabel="Район:"
								   :inputOptions="this.districts_list"
								   :inputValue="(!this.isEmpty(this.item.districts)) ? this.item.districts : null"
								   inputClass="rounded-1 p-2"
								   inputMultiple="true"
								   inputSearch="true"
								   inputWrapClass="mb-20"
								   @setValue="(value) => this.setAndValidate('districts', value)" />

						<SelectBox v-if="this.inArray(this.item.deal_type, [1, 2, 3]) && !this.isEmpty(this.metro_list) && !this.isEmpty(this.item.districts)" inputId="metro"
								   inputName="metro"
								   inputLabel="Метро:"
								   inputLabelClass="text-font-secondary mb-3"
								   :inputOptions="this.metro_list"
								   :inputValue="(!this.isEmpty(this.item.metro)) ? this.item.metro : null"
								   inputClass="rounded-1 p-2"
								   inputMultiple="true"
								   inputSearch="true"
								   inputWrapClass="mb-20"
								   @setValue="(value) => this.setAndValidate('metro', value)" />

						<div v-if="this.inArray(this.item.deal_type, [1, 2, 3]) && !this.isEmpty(this.metro_list) && !this.isEmpty(this.to_metro_list) && !this.isEmpty(this.item.metro)" class="row">
							<div class="col-8">
								<InputNumber inputId="metro_time"
											 inputLabel="До метро, мин.:"
											 inputName="metro_time"
											 inputLabelClass="text-font-secondary mb-3"
											 :inputValue="(!this.isEmpty(this.item.metro_time)) ? this.item.metro_time : null"
											 inputClass="rounded-1 mb-10"
											 inputWrapClass="flex-column mb-3"
											 @setValue="(value) => this.setAndValidate('metro_time', value)" />
							</div>
							<div class="col-4">
								<InputSelect inputId="to_metro"
											 inputLabel="&nbsp;"
											 inputName="to_metro"
											 :inputOptions="this.to_metro_list"
											 inputLabelClass="text-font-secondary mb-3"
											 :inputValue="(!this.isEmpty(this.item.to_metro)) ? this.item.to_metro : '1'"
											 inputClass="rounded-1 mb-10"
											 inputUsePlaceholder="true"
											 inputWrapClass="flex-column mb-3"
											 @setValue="(value) => this.setAndValidate('to_metro', value)" />

							</div>
						</div>
					</div>

					<div v-if="(this.item.deal_type == 1 && this.item.object_type == 4 && this.item.commercial_type && this.item.commercial_type.length) || this.item.deal_type > 1" 
						 class="d-flex flex-column mb-10">
						<div class="form-label text-font-primary fs-2 mb-1">Доп. параметры</div>
						<div class="form-text d-flex fs-5 mb-3">
							<img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -2px;">
							По данным параметрам поиск осуществляется только среди объектов компании.
						</div>

						<DateTimePicker v-if="this.item.deal_type == 3" inputId="newbuildings_end_date"
										inputLabel="Срок сдачи:"
										inputName="newbuildings_end_date"
										ref="newbuildings.end_date"
										:inputValue="(!this.isEmpty(this.item.newbuilding_date)) ? this.item.newbuilding_date : null"
										inputClass="rounded-1 p-2"
										displayFormat="DD.MM.YYYY"
										inputWrapClass="flex-column mb-2"
										@setValue="(value) => this.setAndValidate('newbuilding_date', value)" />

						<Switcher v-if="(this.item.deal_type == 2 || this.item.deal_type == 3) && this.item.object_type != 4" inputId="is_hypothec"
									   inputLabel="Ипотека"
									   inputName="is_hypothec"
									   ref="is_hypothec"
									   inputClass="ps-0 mb-4"
									   inputWrapClass="flex-column mb-2"
									   :inputValue="(!this.isEmpty(this.item.is_hypothec)) ? this.item.is_hypothec : false"
									   @setValue="(value) => this.setAndValidate('is_hypothec', value)" />

						<Switcher v-if="(this.item.deal_type == 2 || this.item.deal_type == 3) && this.item.object_type != 4" inputId="is_military_hypothec"
									   inputLabel="Военная ипотека"
									   inputName="is_military_hypothec"
									   ref="is_military_hypothec"
									   inputClass="ps-0 mb-4"
									   inputWrapClass="flex-column mb-2"
									   :inputValue="(!this.isEmpty(this.item.is_military_hypothec)) ? this.item.is_military_hypothec : false"
									   @setValue="(value) => this.setAndValidate('is_military_hypothec', value)" />

						<Switcher v-if="(this.item.deal_type == 2 || this.item.deal_type == 3) && this.item.object_type != 4" inputId="is_subsidy"
									   inputLabel="Субсидия"
									   inputName="is_subsidy"
									   ref="is_subsidy"
									   inputClass="ps-0 mb-4"
									   inputWrapClass="flex-column mb-2"
									   :inputValue="(!this.isEmpty(this.item.is_subsidy)) ? this.item.is_subsidy : false"
									   @setValue="(value) => this.setAndValidate('is_subsidy', value)" />

						<Switcher v-if="(this.item.deal_type == 2 || this.item.deal_type == 3) && this.item.object_type != 4" inputId="is_maternal_capital"
									   inputLabel="Материнский капитал"
									   inputName="is_maternal_capital"
									   ref="is_maternal_capital"
									   inputClass="ps-0 mb-4"
									   inputWrapClass="flex-column mb-2"
									   :inputValue="(!this.isEmpty(this.item.is_maternal_capital)) ? this.item.is_maternal_capital : false"
									   @setValue="(value) => this.setAndValidate('is_maternal_capital', value)" />

						<InputSelect v-if="this.item.deal_type == 2 && !this.inArray(this.item.object_type, [3, 4, 5, 7])"
									 inputId="house_type"
									 inputLabel="Тип дома:"
									 inputName="house_type"
									 ref="house_type"
									 :inputOptions="this.house_types_list"
									 :inputValue="(!this.isEmpty(this.item.house_type_id)) ? this.item.house_type_id : null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setAndValidate('house_type_id', value)" />

						<InputSelect v-if="this.item.deal_type == 2 && (this.item.object_type != 3 && this.item.object_type != 4 && this.item.object_type != 5 && this.item.object_type != 7 && !this.inArray(8, this.item.commercial_type))"
									 inputId="house_material_type"
									 inputLabel="Материал дома:"
									 inputName="house_material_type"
									 ref="house_material_type"
									 :inputOptions="this.house_materials_list"
									 :inputValue="(!this.isEmpty(this.item.house_material_type_id)) ? this.item.house_material_type_id : null"
									 inputClass="rounded-1"
									 inputWrapClass="flex-column mb-3"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setAndValidate('house_material_type_id', value)" />

						<InputNumber v-if="this.item.deal_type == 2 && (this.item.object_type != 3 && this.item.object_type != 4 && this.item.object_type != 5 && this.item.object_type != 7 && !this.inArray(8, this.item.commercial_type))" inputId="build_year"
									 inputLabel="Год постройки, не позднее:"
									 inputName="build_year"
									 ref="build_year"
									 :inputValue="(!this.isEmpty(this.item.build_year)) ? this.item.build_year : null"
									 inputClass="rounded-1 p-2"
									 inputMin="1947"
									 inputMax="2022"
									 inputWrapClass="flex-column mb-2"
									 @setValue="(value) => this.setAndValidate('build_year', value)" />

						<div v-if="(this.item.object_type == 4 && this.item.commercial_type && this.item.commercial_type.length)">
							<InputText v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6,9].includes(item))  " inputId="tax_number"
									   inputName="tax_number"
									   ref="tax_number"
									   inputClass="rounded-1 mb-10"
									   inputWrapClass="flex-column mb-2"
									   inputLabel="Номер налоговой:"
									   :inputValue="!this.isEmpty(this.item.tax_number) ? this.item.tax_number : null"
									   @setValue="(value) => this.setAndValidate('tax_number', value)" />
							
							<InputSelect v-if="this.item.commercial_type.some(item => [1,2,3,4,5].includes(item))" inputId="legal_address"
										 inputName="legal_address"
										 ref="legal_address"
										 inputLabel="Юридический адрес:"
										 :inputValue="!this.isEmpty(this.item.legal_address) ? this.item.legal_address : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.legal_address_list"
										 @setValue="(value) => this.setAndValidate('legal_address', value)" />
										 
							<InputNumberBetween v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6.8].includes(item))" inputId="flat"
												inputName="flat"
												inputLabel="Этаж:"
												inputFromPlaceholder="от"
												inputToPlaceholder="до"
												inputStep="1"
												inputMin="1"
												inputMax="120"
												:inputValueFrom="!this.isEmpty(this.item.flat_from) ? this.item.flat_from : null"
												:inputValueTo="!this.isEmpty(this.item.flat_to) ? this.item.flat_to : null"
												inputClass="rounded-1"
												inputWrapClass="flex-column mb-3"
												@setValue="(value) => {
													this.setAndValidate('flat_from', value.from);
													this.setAndValidate('flat_to', value.to);
												}" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,3,5].includes(item))" inputId="occupied_id"
										 inputName="occupied_id"
										 inputLabel="Помещение занято:"
										 :inputValue="!this.isEmpty(this.item.occupied_id) ? this.item.occupied_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.occupieds_list"
										 @setValue="(value) => this.setAndValidate('occupied_id', value)" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,3,5].includes(item))" inputId="layout_id"
										 inputName="layout_id"
										 inputLabel="Планировка:"
										 :inputValue="!this.isEmpty(this.item.layout_id) ? this.item.layout_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.layouts_list"
										 @setValue="(value) => this.setAndValidate('layout_id', value)" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,2,3,4,5].includes(item))" inputId="wet_spots_id"
										 inputName="wet_spots_id"
										 inputLabel="Количество мокрых точек:"
										 :inputValue="(!this.isEmpty(this.item.wet_spots_id)) ? this.item.wet_spots_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.wet_spots_list"
										 @setValue="(value) => this.setAndValidate('wet_spots_id', value)" />

							<InputNumberBetween v-if="this.item.commercial_type.some(item => [1,2,3,4,5].includes(item))" inputId="electricity_power"
												inputName="electricity_power"
												inputLabel="Электрическая мощность, кВт:"
												inputFromPlaceholder="от"
												inputToPlaceholder="до"
												inputStep="1"
												inputMin="0"
												inputMax="10000"
												:inputValueFrom="this.item.electricity_power_from"
												:inputValueTo="this.item.electricity_power_to"
												inputClass="rounded-1"
												inputWrapClass="flex-column mb-3"
												@setValue="(value) => {
													this.setAndValidate('electricity_power_from', value.from);
													this.setAndValidate('electricity_power_to', value.to);
												}" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6,8].includes(item))" inputId="condition_id"
										 inputName="condition_id"
										 inputLabel="Состояние:"
										 :inputValue="(!this.isEmpty(this.item.condition_id)) ? this.item.condition_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.conditions_list"
										 @setValue="(value) => this.setAndValidate('condition_id', value)" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,3,5,6,8].includes(item))" inputId="furniture_id"
										 inputName="furniture_id"
										 inputLabel="Мебель:"
										 :inputValue="(!this.isEmpty(this.item.furniture_id)) ? this.item.furniture_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.furnitures_list"
										 @setValue="(value) => this.setAndValidate('furniture_id', value)" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,3,4,5].includes(item))" inputId="access_id"
										 inputName="access_id"
										 inputLabel="Доступ:"
										 :inputValue="(!this.isEmpty(this.item.access_id)) ? this.item.access_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.access_list"
										 @setValue="(value) => this.setAndValidate('access_id', value)" />

							<InputSelect v-if="/*this.item.operation_type == 2 && */this.inArray(this.item.object_type, [1, 2, 4])" inputId="parking_type_id"
										 inputLabel="Тип парковки:"
										 inputName="parking_type_id"
										 ref="parking_type_id"
										 :inputValue="(!this.isEmpty(this.item.parking_type_id)) ? this.item.parking_type_id : null"
										 :inputOptions="this.parking_types1_list"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('parking_type_id', value)" />
							<InputSelect v-if="this.item.object_type == 4 && this.item.commercial_type.some(item => [2,4].includes(item))" inputId="parking_id"
										 inputLabel="Парковка:"
										 inputName="parking_id"
										 ref="parking_id"
										 :inputValue="(!this.isEmpty(this.item.parking_id)) ? this.item.parking_id : null"
										 :inputOptions="this.parking_types2_list"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('parking_id', value)" />
							<InputSelect v-if="this.item.object_type == 4 && this.item.commercial_type.some(item => [2,4].includes(item))" inputId="parking_sub_type_id"
										 inputLabel="Тип парковки:"
										 inputName="parking_sub_type_id"
										 ref="parking_sub_type_id"
										 :inputValue="(!this.isEmpty(this.item.parking_sub_type_id)) ? this.item.parking_sub_type_id : null"
										 :inputOptions="this.parking_types3_list"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('parking_sub_type_id', value)" />

							<div v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6,9].includes(item))" class="row row-cols-2">
								<div class="col">
									<InputNumber inputId="parking_price"
												 inputName="parking_price"
												 inputClass="rounded-1 mb-10"
												 inputWrapClass="flex-column mb-3"
												 inputLabel="Стоимость парковки:"
												 inputMin="0"
												 inputMax="99999"
												 inputStep="1"
												 :inputValue="(!this.isEmpty(this.item.parking_price)) ? this.item.parking_price : null"
												 :inputDisabled="(this.item.parking_is_free)"
												 @setValue="(value) => this.setAndValidate('parking_price', value)" />
								</div>
								<div class="col pt-2">
									<InputCheckbox inputId="parking_is_free"
												   inputName="parking_is_free"
												   inputClass="mb-0"
												   inputWrapClass="flex-column pt-1 mt-4"
												   inputLabel="Бесплатно"
												   :inputValue="(!this.isEmpty(this.item.parking_is_free)) ? this.item.parking_is_free : false"
												   @setValue="(value) => this.setAndValidate('parking_is_free', value)" />
								</div>
							</div>

							<InputNumberBetween v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6,8].includes(item))" inputId="ceiling_height"
												inputName="ceiling_height"
												inputLabel="Высота потолков:"
												inputFromPlaceholder="от"
												inputToPlaceholder="до"
												inputStep="1"
												inputMin="0"
												inputMax="5"
												:inputValueFrom="(!this.isEmpty(this.item.ceiling_height_from)) ? this.item.ceiling_height_from : null"
												:inputValueTo="(!this.isEmpty(this.item.ceiling_height_to)) ? this.item.ceiling_height_to : null"
												inputClass="rounded-1"
												inputWrapClass="flex-column mb-3"
												@setValue="(value) => {
													this.setAndValidate('ceiling_height_from', value.from);
													this.setAndValidate('ceiling_height_to', value.to);
												}" />

							<InputText v-if="this.item.commercial_type.some(item => [2,4,5].includes(item))" inputId="column_grid"
									   inputName="column_grid"
									   inputClass="rounded-1 mb-10"
									   inputWrapClass="flex-column mb-2"
									   inputLabel="Сетка колон:"
									   :inputValue="(!this.isEmpty(this.item.column_grid)) ? this.item.column_grid : null"
									   @setValue="(value) => this.setAndValidate('column_grid', value)" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6,9].includes(item))" inputId="vat_type_id"
										 inputName="vat_type_id"
										 inputLabel="Налог:"
										 :inputValue="(!this.isEmpty(this.item.vat_type_id)) ? this.item.vat_type_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.vat_types_list"
										 @setValue="(value) => this.setAndValidate('vat_type_id', value)" />

							<div v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6,8,9].includes(item))" class="row row-cols-3">
								<div class="col">
									<InputNumber inputId="commerce_site_area"
												 inputName="commerce_site_area"
												 inputClass="rounded-1 mb-10"
												 inputWrapClass="flex-column mb-3"
												 inputLabel="Участок, га:"
												 inputMin="0"
												 inputMax="99999"
												 inputStep="1"
												 :inputValue="(!this.isEmpty(this.item.commerce_site_area)) ? this.item.commerce_site_area : null"
												 @setValue="(value) => this.setAndValidate('commerce_site_area', value)" />
								</div>
								<div class="col pt-2">
									<InputCheckbox inputId="commerce_site_owned"
												   inputName="commerce_site_owned"
												   inputClass="mb-4"
												   inputWrapClass="flex-column pt-1 mt-4"
												   inputLabel="собств."
												   :inputValue="(!this.isEmpty(this.item.commerce_site_owned)) ? (this.item.commerce_site_owned == 1) : false"
												   @setValue="(value) => this.setAndValidate('commerce_site_owned', 1)" />
								</div>
								<div class="col pt-2">
									<InputCheckbox inputId="commerce_site_owned2"
												   inputName="commerce_site_owned2"
												   inputClass="mb-0"
												   inputWrapClass="flex-column pt-1 mt-4"
												   inputLabel="аренда"
												   :inputValue="(!this.isEmpty(this.item.commerce_site_owned)) ? (this.item.commerce_site_owned == 2) : false"
												   @setValue="(value) => this.setAndValidate('commerce_site_owned', 2)" />
								</div>
							</div>

							<SelectBox v-if="this.item.commercial_type.some(item => [2,4,5].includes(item))" inputId="floor_material_id"
									   inputName="floor_material_id"
									   inputLabel="Материал пола:"
									   :inputValue="(!this.isEmpty(this.item.floor_material_id)) ? this.item.floor_material_id : null"
									   inputClass="rounded-1"
									   inputWrapClass="flex-column mb-3"
									   inputMultiple="true"
									   :inputOptions="this.floor_materials_list"
									   @setValue="(value) => this.setAndValidate('floor_material_id', value)" />

							<SelectBox v-if="this.item.commercial_type.some(item => [2,4,5].includes(item))" inputId="commerce_portal_id"
									   inputName="commerce_portal_id"
									   inputLabel="Ворота:"
									   :inputValue="(!this.isEmpty(this.item.commerce_portal_id)) ? this.item.commerce_portal_id : null"
									   inputClass="rounded-1"
									   inputWrapClass="flex-column mb-3"
									   inputMultiple="true"
									   :inputOptions="this.commerce_portal_list"
									   @setValue="(value) => this.setAndValidate('commerce_portal_id', value)" />

							<SelectBox v-if="this.item.commercial_type.some(item => [3,5, 8].includes(item))" inputId="commerce_purpose_id"
									   inputName="commerce_purpose_id"
									   inputLabel="Назначение:"
									   :inputValue="(!this.isEmpty(this.item.commerce_purpose_id)) ? this.item.commerce_purpose_id : null"
									   inputClass="rounded-1"
									   inputWrapClass="flex-column mb-3"
									   inputMultiple="true"
									   :inputOptions="this.commerce_purpose_list"
									   @setValue="(value) => this.setAndValidate('commerce_purpose_id', value)" />

							<InputSelect v-if="this.item.commercial_type.includes(9)" inputId="commerce_land_category_id"
										 inputLabel="Категория земли:"
										 inputName="commerce_land_category_id"
										 :inputValue="(!this.isEmpty(this.item.commerce_land_category_id)) ? this.item.commerce_land_category_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_land_categories_list"
										 @setValue="(value) => this.setAndValidate('commerce_land_category_id', value)" />

							<InputSelect v-if="this.item.commercial_type.includes(9)" inputId="commerce_permitted_use_id"
										 inputLabel="Вид разрешенного использования:"
										 inputName="commerce_permitted_use_id"
										 :inputValue="(!this.isEmpty(this.item.commerce_permitted_use_id)) ? this.item.commerce_permitted_use_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_permitted_use_list"
										 @setValue="(value) => this.setAndValidate('commerce_permitted_use_id', value)" />

							<InputSelect v-if="this.item.commercial_type.includes(9)" inputId="commerce_electricity_id"
										 inputLabel="Электричество:"
										 inputName="commerce_electricity_id"
										 :inputValue="(!this.isEmpty(this.item.commerce_electricity_id)) ? this.item.commerce_electricity_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_electricity_list"
										 @setValue="(value) => this.setAndValidate('commerce_electricity_id', value)" />

							<InputSelect v-if="this.item.commercial_type.includes(9)" inputId="commerce_gas_id"
										 inputLabel="Газ:"
										 inputName="commerce_gas_id"
										 :inputValue="(!this.isEmpty(this.item.commerce_gas_id)) ? this.item.commerce_gas_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_gas_list"
										 @setValue="(value) => this.setAndValidate('commerce_gas_id', value)" />

							<InputSelect v-if="this.item.commercial_type.includes(9)" inputId="commerce_water_id"
										 inputLabel="Вода:"
										 inputName="commerce_water_id"
										 :inputValue="(!this.isEmpty(this.item.commerce_water_id)) ? this.item.commerce_water_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_water_list"
										 @setValue="(value) => this.setAndValidate('commerce_water_id', value)" />

							<InputSelect v-if="this.item.commercial_type.includes(9)" inputId="commerce_sewage_id"
										 inputLabel="Канализация:"
										 inputName="commerce_sewage_id"
										 :inputValue="(!this.isEmpty(this.item.commerce_sewage_id)) ? this.item.commerce_sewage_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_sewage_list"
										 @setValue="(value) => this.setAndValidate('commerce_sewage_id', value)" />

							<InputSelect v-if="this.item.commercial_type.includes(9)" inputId="commerce_driveways_id"
										 inputLabel="Подъездные пути:"
										 inputName="commerce_driveways_id"
										 :inputValue="(!this.isEmpty(this.item.commerce_driveways_id)) ? this.item.commerce_driveways_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.commerce_driveways_list"
										 @setValue="(value) => this.setAndValidate('commerce_driveways_id', value)" />

							<InputNumberBetween v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6].includes(item))" inputId="building_year"
												inputName="building_year"
												inputLabel="Год постройки:"
												inputFromPlaceholder="от"
												inputToPlaceholder="до"
												inputStep="1"
												inputMin="1940"
												:inputMax="this.moment().format('YYYY')"
												:inputValueFrom="this.item.building_year_from"
												:inputValueTo="this.item.building_year_to"
												inputClass="rounded-1"
												inputWrapClass="flex-column mb-3"
												@setValue="(value) => {
													this.setAndValidate('building_year_from', value.from)
													this.setAndValidate('building_year_to', value.to);
												}" />

							<SelectBox v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6].includes(item))" inputId="building_type_id"
									   inputName="building_type_id"
									   inputLabel="Тип здания:"
									   inputClass="rounded-1 p-2"
									   inputWrapClass="flex-column mb-4"
									   :inputValue="(!this.isEmpty(this.item.building_type_id)) ? this.item.building_type_id : null"
									   :inputOptions="this.building_types_list"
									   inputSearch="true"
									   inputMultiple="true"
									   @setValue="(value) => this.setAndValidate('building_type_id', value)" />

							<SelectBox v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6].includes(item))" inputId="building_class_id"
									   inputName="building_class_id"
									   inputLabel="Класс здания:"
									   inputClass="rounded-1 p-2"
									   inputWrapClass="flex-column mb-4"
									   :inputValue="(!this.isEmpty(this.item.building_class_id)) ? this.item.building_class_id : null"
									   :inputOptions="this.building_classes_list"
									   inputSearch="true"
									   inputMultiple="true"
									   @setValue="(value) => this.setAndValidate('building_class_id', value)" />

							<InputNumberBetween v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6].includes(item))" inputId="building_area"
												inputName="building_area"
												inputLabel="Площадь здания, кв.м:"
												inputFromPlaceholder="от"
												inputToPlaceholder="до"
												inputStep="1"
												inputMin="0"
												inputMax="1000"
												:inputValueFrom="this.item.building_area_from"
												:inputValueTo="this.item.building_area_to"
												inputClass="rounded-1"
												inputWrapClass="flex-column mb-3"
												@setValue="(value) => {
											this.setAndValidate('building_area_from', value.from);
											this.setAndValidate('building_area_to', value.to);
										}" />

							<SelectBox v-if="this.item.commercial_type.some(item => [2,3,4,6].includes(item))" inputId="houseline_type_id"
									   inputLabel="Линия домов:"
									   inputName="houseline_type_id"
									   :inputValueTo="this.item.houseline_type_id"
									   :inputOptions="this.houseline_types_list"
									   inputClass="rounded-1 p-2"
									   inputWrapClass="flex-column mb-2"
									   inputSearch="true"
									   inputMultiple="true"
									   @setValue="(value) => this.setAndValidate('houseline_type_id', value)" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6].includes(item))" inputId="building_category_id"
										 inputName="building_category_id"
										 inputLabel="Категория:"
										 :inputValue="(!this.isEmpty(this.item.building_category_id)) ? this.item.building_category_id : null"
										 inputClass="rounded-1"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 :inputOptions="this.building_categories_list"
										 @setValue="(value) => this.setAndValidate('building_category_id', value)" />

							<SelectBox v-if="this.item.commercial_type.some(item => [2,3,4,6].includes(item))" inputId="commerce_entrance_id"
									   inputLabel="Вход:"
									   inputName="commerce_entrance_id"
									   :inputValue="(!this.isEmpty(this.item.commerce_entrance_id)) ? this.item.commerce_entrance_id : null"
									   :inputOptions="this.entrance_types_list"
									   inputClass="rounded-1 p-2"
									   inputWrapClass="flex-column mb-2"
									   inputMultiple="true"
									   @setValue="(value) => this.setAndValidate('commerce_entrance_id', value)" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6].includes(item))" inputId="building_ventilation_id"
										 inputLabel="Вентиляция:"
										 inputName="building_ventilation_id"
										 :inputValue="(!this.isEmpty(this.item.building_ventilation_id)) ? this.item.building_ventilation_id : null"
										 :inputOptions="this.building_ventilations_list"
										 inputClass="rounded-1 p-2"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('building_ventilation_id', value)" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6].includes(item))" inputId="building_conditioning_id"
										 inputLabel="Кондиционирование:"
										 inputName="building_conditioning_id"
										 :inputValue="(!this.isEmpty(this.item.building_conditioning_id)) ? this.item.building_conditioning_id : null"
										 :inputOptions="this.building_conditioning_list"
										 inputClass="rounded-1 p-2"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('building_conditioning_id', value)" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6].includes(item))" inputId="building_heating_id"
										 inputLabel="Отопление:"
										 inputName="building_heating_id"
										 :inputValue="(!this.isEmpty(this.item.building_heating_id)) ? this.item.building_heating_id : null"
										 :inputOptions="this.building_heating_list"
										 inputClass="rounded-1 p-2"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('building_heating_id', value)" />

							<InputSelect v-if="this.item.commercial_type.some(item => [1,2,3,4,5,6].includes(item))" inputId="building_firefighting_id"
										 inputLabel="Система пожаротушения:"
										 inputName="building_firefighting_id"
										 :inputValue="(!this.isEmpty(this.item.building_firefighting_id)) ? this.item.building_firefighting_id : null"
										 :inputOptions="this.building_firefighting_list"
										 inputClass="rounded-1 p-2"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('building_firefighting_id', value)" />

							<InputSelect v-if="this.item.commercial_type.includes(7)" inputId="garage_type_id"
										 inputLabel="Тип:"
										 inputName="garage_id"
										 :inputValue="(!this.isEmpty(this.item.garage_id)) ? this.item.garage_id : null"
										 :inputOptions="this.garages_list"
										 inputClass="rounded-1 p-2"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('garage_id', value)" />

							<InputSelect v-if="!this.item.commercial_type.some(item => [1,2,3,4,5,6,8,9].includes(item)) && this.item.garage_id == 2" inputId="garage_type_id"
										 inputLabel="Тип гаража:"
										 inputName="garage_type_id"
										 :inputValue="(!this.isEmpty(this.item.garage_type_id)) ? this.item.garage_type_id : null"
										 :inputOptions="this.garages_types_list"
										 inputClass="rounded-1 p-2"
										 inputWrapClass="flex-column mb-2"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('garage_type_id', value)" />

							<InputSelect v-if="!this.item.commercial_type.some(item => [1,2,3,4,5,6,8,9].includes(item)) && this.item.garage_id == 3" inputId="box_type_id"
										 inputLabel="Тип бокса:"
										 inputName="box_type_id"
										 :inputValue="(!this.isEmpty(this.item.box_type_id)) ? this.item.box_type_id : null"
										 :inputOptions="this.boxes_types_list"
										 inputClass="rounded-1 p-2"
										 inputWrapClass="flex-column mb-2"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('box_type_id', value)" />

							<InputSelect v-if="this.item.commercial_type.includes(7)" inputId="commerce_status_id"
										 inputLabel="Статус:"
										 inputName="commerce_status_id"
										 :inputValue="(!this.isEmpty(this.item.commerce_status_id)) ? this.item.commerce_status_id : null"
										 :inputOptions="this.commerce_status_list"
										 inputClass="rounded-1 p-2"
										 inputWrapClass="flex-column mb-3"
										 inputUsePlaceholder="true"
										 @setValue="(value) => this.setAndValidate('commerce_status_id', value)" />

							<SelectBox v-if="this.item.commercial_type.includes(7)" inputId="commerce_specifications"
									   inputLabel="Технические характеристики:"
									   inputName="commerce_specifications"
									   :inputValue="(!this.isEmpty(this.item.commerce_specifications)) ? this.item.commerce_specifications : null"
									   :inputOptions="this.commerce_specifications_list"
									   inputClass="rounded-1 p-2"
									   inputWrapClass="flex-column mb-3"
									   inputSearch="true"
									   inputMultiple="true"
									   @setValue="(value) => this.setAndValidate('commerce_specifications', value)" />

							<SelectBox v-if="!this.item.commercial_type.some(item => [1,2,3,4,5,6,7].includes(item))" inputId="infrastructure_id"
									   inputLabel="Инфраструктура:"
									   inputName="infrastructure_id"
									   :inputValue="(!this.isEmpty(this.item.infrastructure_id)) ? this.item.infrastructure_id : null"
									   :inputOptions="this.infrastructures_list"
									   inputClass="rounded-1 p-2"
									   inputWrapClass="flex-column mb-4"
									   inputSearch="true"
									   inputMultiple="true"
									   @setValue="(value) => this.setAndValidate('infrastructure_id', value)" />


						</div>
					</div>

					<div v-if="(this.item.deal_type > 0 && this.item.object_type == 4) && (this.item.commercial_type.some(item => [2,4].includes(item)))" class="d-flex flex-column mb-10">
						<div class="form-label text-font-primary fs-2 mb-3">Лифты</div>
						<div class="form-text d-flex fs-5 mb-3">
							<img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -2px;">
							По данным параметрам поиск осуществляется только среди объектов компании.
						</div>
						<div class="row row-cols-1">
							<div class="col">
								<InputNumber inputLabel="Грузовой:"
											 inputId="commerce_lifts_cargo_count"
											 inputName="commerce_lifts_cargo_count"
											 ref="commerce_lifts_cargo_count"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="шт."
											 :inputValue="(!this.isEmpty(this.item.commerce_lifts_cargo_count)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_lifts_cargo_count', value)" />
							</div>
							<div class="col mb-3">
								<InputNumber inputLabel="Грузоподъемность:"
											 inputId="commerce_lifts_cargo_carrying"
											 inputName="commerce_lifts_cargo_carrying"
											 ref="commerce_lifts_cargo_carrying"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="т."
											 :inputValue="(!this.isEmpty(this.item.commerce_lifts_cargo_carrying)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_lifts_cargo_carrying', value)" />
							</div>
						</div>
						<div class="row row-cols-1">
							<div class="col">
								<InputNumber inputLabel="Тельфер:"
											 inputId="commerce_lifts_telfer_count"
											 inputName="commerce_lifts_telfer_count"
											 ref="commerce_lifts_telfer_count"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="шт."
											 :inputValue="(!this.isEmpty(this.item.commerce_lifts_telfer_count)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_lifts_telfer_count', value)" />
							</div>
							<div class="col mb-3">
								<InputNumber inputLabel="Грузоподъемность:"
											 inputId="commerce_lifts_telfer_carrying"
											 inputName="commerce_lifts_telfer_carrying"
											 ref="commerce_lifts_telfer_carrying"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="т."
											 :inputValue="(!this.isEmpty(this.item.commerce_lifts_telfer_carrying)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_lifts_telfer_carrying', value)" />
							</div>
						</div>
						<div class="row row-cols-1">
							<div class="col">
								<InputNumber inputLabel="Пассажирский:"
											 inputId="commerce_lifts_passenger_count"
											 inputName="commerce_lifts_passenger_count"
											 ref="commerce_lifts_passenger_count"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="шт."
											 :inputValue="(!this.isEmpty(this.item.commerce_lifts_passenger_count)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_lifts_passenger_count', value)" />
							</div>
							<div class="col mb-3">
								<InputNumber inputLabel="Грузоподъемность:"
											 inputId="commerce_lifts_passenger_carrying"
											 inputName="commerce_lifts_passenger_carrying"
											 ref="commerce_lifts_passenger_carrying"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="т."
											 :inputValue="(!this.isEmpty(this.item.commerce_lifts_passenger_carrying)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_lifts_passenger_carrying', value)" />
							</div>
						</div>
					</div>

					<div v-if="(this.item.deal_type > 0 && this.item.object_type == 4) && (this.item.commercial_type.some(item => [2,4].includes(item)))" class="d-flex flex-column mb-10">
						<div class="form-label text-font-primary fs-2 mb-3">Крановое оборудование</div>
						<div class="form-text d-flex fs-5 mb-3">
							<img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -2px;">
							По данным параметрам поиск осуществляется только среди объектов компании.
						</div>
						<div class="row row-cols-1">
							<div class="col">
								<InputNumber inputLabel="Мостовой кран:"
											 inputId="commerce_cranes_overhead_count"
											 inputName="commerce_cranes_overhead_count"
											 ref="commerce_cranes_overhead_count"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="шт."
											 :inputValue="(!this.isEmpty(this.item.commerce_cranes_overhead_count)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_cranes_overhead_count', value)" />
							</div>
							<div class="col mb-3">
								<InputNumber inputLabel="Грузоподъемность:"
											 inputId="commerce_cranes_overhead_carrying"
											 inputName="commerce_cranes_overhead_carrying"
											 ref="commerce_cranes_overhead_carrying"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="т."
											 :inputValue="(!this.isEmpty(this.item.commerce_cranes_overhead_carrying)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_cranes_overhead_carrying', value)" />
							</div>
						</div>
						<div class="row row-cols-1">
							<div class="col">
								<InputNumber inputLabel="Кран-балка:"
											 inputId="commerce_cranes_cathead_count"
											 inputName="commerce_cranes_cathead_count"
											 ref="commerce_cranes_cathead_count"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="шт."
											 :inputValue="(!this.isEmpty(this.item.commerce_cranes_cathead_count)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_cranes_cathead_count', value)" />
							</div>
							<div class="col mb-3">
								<InputNumber inputLabel="Грузоподъемность:"
											 inputId="commerce_cranes_cathead_carrying"
											 inputName="commerce_cranes_cathead_carrying"
											 ref="commerce_cranes_cathead_carrying"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="т."
											 :inputValue="(!this.isEmpty(this.item.commerce_cranes_cathead_carrying)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_cranes_cathead_carrying', value)" />
							</div>
						</div>
						<div class="row row-cols-1">
							<div class="col">
								<InputNumber inputLabel="Ж/д кран:"
											 inputId="commerce_cranes_railway_count"
											 inputName="commerce_cranes_railway_count"
											 ref="commerce_cranes_railway_count"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="шт."
											 :inputValue="(!this.isEmpty(this.item.commerce_cranes_railway_count)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_cranes_railway_count', value)" />
							</div>
							<div class="col mb-3">
								<InputNumber inputLabel="Грузоподъемность:"
											 inputId="commerce_cranes_railway_carrying"
											 inputName="commerce_cranes_railway_carrying"
											 ref="commerce_cranes_railway_carrying"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="т."
											 :inputValue="(!this.isEmpty(this.item.commerce_cranes_railway_carrying)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_cranes_railway_carrying', value)" />
							</div>
						</div>
						<div class="row row-cols-1">
							<div class="col">
								<InputNumber inputLabel="Козловой кран:"
											 inputId="commerce_cranes_gantry_count"
											 inputName="commerce_cranes_gantry_count"
											 ref="commerce_cranes_gantry_count"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="шт."
											 :inputValue="(!this.isEmpty(this.item.commerce_cranes_gantry_count)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_cranes_gantry_count', value)" />
							</div>
							<div class="col mb-3">
								<InputNumber inputLabel="Грузоподъемность:"
											 inputId="commerce_cranes_gantry_carrying"
											 inputName="commerce_cranes_gantry_carrying"
											 ref="commerce_cranes_gantry_carrying"
											 inputClass="rounded-1 mb-10"
											 inputGroupClass="col w-50"
											 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
											 inputLabelClass="col w-50 text-dark-gray"
											 inputAppend="т."
											 :inputValue="(!this.isEmpty(this.item.commerce_cranes_gantry_carrying)) ? this.item.commerce_cranes_railway_count : null"
											 @setValue="(value) => this.setAndValidate('commerce_cranes_gantry_carrying', value)" />
							</div>
						</div>
					</div>

					<div class="d-flex flex-column mb-10">

						<TextArea inputId="search_query"
								  inputLabel="Поиск по словам в описании:"
								  inputName="search_query"
								  ref="search_query"
								  inputLabelClass="text-font-secondary mb-3"
								  :inputValue="(!this.isEmpty(this.item.search_query)) ? this.item.search_query : null"
								  inputClass="rounded-3 p-2"
								  inputWrapClass="mb-20"
								  @setValue="(value) => this.setAndValidate('search_query', value)" />

						<TextArea inputId="comment"
								  inputLabel="Комментарий:"
								  inputName="comment"
								  ref="comment"
								  inputLabelClass="text-font-secondary mb-3"
								  :inputValue="(!this.isEmpty(this.item.comment)) ? this.item.comment : null"
								  inputClass="rounded-3 p-2"
								  inputWrapClass="mb-20"
								  @setValue="(value) => this.setAndValidate('comment', value)" />

					</div>

					<div class="d-flex flex-column mb-0">

						<ToggleButtons inputId="search_target"
									   inputLabel="Источник поиска:"
									   inputName="search_target"
									   ref="search_target"
									   inputType="radio"
									   inputLabelClass="text-font-secondary mb-3"
									   :inputValue="(!this.isEmpty(this.item.search_target)) ? this.item.search_target : null"
									   :inputOptions="this.search_targets_list"
									   inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
									   inputWrapClass="flex-column mb-3"
									   inputRequired="true"
									   @setValue="(value) => this.setAndValidate('search_target', value)" />

						<Switcher v-if="this.item.is_search || this.item.is_reverse" inputId="is_active"
								  inputLabel="Автопоиск объектов по заявке"
								  inputName="is_active"
								  ref="is_active"
								  inputClass="ps-0 mb-4"
								  inputWrapClass="flex-column mb-2"
								  :inputValue="(!this.isEmpty(this.item.is_active)) ? this.item.is_active : false"
								  @setValue="(value) => this.setAndValidate('is_active', value)" />

						<Switcher v-if="this.item.is_search" inputId="telegram_notify"
								  inputLabel="Уведомлять о новых объектах в Телеграм"
								  inputName="telegram_notify"
								  ref="telegram_notify"
								  inputClass="ps-0 mb-4"
								  inputWrapClass="flex-column mb-2"
								  :inputValue="(!this.isEmpty(this.item.telegram_notify)) ? this.item.telegram_notify : false"
								  @setValue="(value) => this.setAndValidate('telegram_notify', value)" />

						<div class="form-text d-flex fs-5 mb-0">
							<img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -2px;">
							При изменении некоторых параметров очищаются прежние результаты автопоиска
							(за исключением просмотренных и игнорируемых объектов, объектов добавленных
							в Избранное и отправленных в PDF-презентации).
						</div>

					</div>
                </div>
				<div class="modal-footer position-sticky px-4">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						@click="this.deBounce(this.addEditFilter(), 1000)"
						v-text="(this.item.id) ? 'Сохранить' : 'Добавить'" />
				</div>
            </div>
        </div>
    </div>
</template>

<script>
	import api from "@/api";
	import CommonService from "@/services/CommonService";
	import NavTabs from "@/components/common/NavTabs";
	import ToggleButtons from "@/components/inputs/ToggleButtons";
	import InputText from "@/components/inputs/InputText";
	import InputNumber from "@/components/inputs/InputNumber";
	import InputNumberBetween from "@/components/inputs/InputNumberBetween";
	import Switcher from "@/components/inputs/Switcher";
	import InputSelect from "@/components/inputs/InputSelect";
	import SelectBox from "@/components/inputs/SelectBox";
	import DateTimePicker from "@/components/inputs/DateTimePicker";
	import TextArea from "@/components/inputs/TextArea";
	import InputCheckbox from "@/components/inputs/InputCheckbox";
	import moment from "moment";

    export default {
		name: "AutoSearchEditModal",
        components: {
			InputCheckbox,
			TextArea,
			DateTimePicker,
			SelectBox,
			InputSelect,
			Switcher,
			InputNumberBetween,
			InputNumber,
			InputText,
			ToggleButtons,
			NavTabs
        },
        data() {
            return {
				tabItems: [
					{
						id: 'sale-tab',
						label: "Покупка",
						title: "Покупка",
						active: true,
						action: () => this.setAndValidate('deal_type', 2)
					}, {
						id: 'rent-tab',
						label: "Аренда",
						title: "Аренда",
						active: false,
						action: () => this.setAndValidate('deal_type', 1)
					}, /*{
						id: 'new-buildings-tab',
						label: "Новостройки",
						title: "Новостройки",
						data_bs_toggle: null,
						data_bs_target: null,
						count: null,
						active: false,
						action: () => this.setAndValidate('deal_type', 3)
					},*/
				],
				filter_id: null,
				req_type_id: null,
				item: {
					id: null,
					deal_type: 2,
					object_type: 1,
					search_target: 1,
					commercial_type: [],
					regions: [],
					districts: [],
					metro: [],
					requisition: {
						id: null,
						name: null,
					},
					is_active: false,
					is_search: true,
					is_reverse: false,
				},

				is_form_changed: false,

				objects_types_list: [],
				commercial_types_list: [],
				search_targets_list: [],
				lease_types_list: [],
				regions_list: [],
				districts_list: [],
				metro_list: [],
				to_metro_list: [],
				house_types_list: [],
				house_materials_list: [],
				legal_address_list: [],
				occupieds_list: [],
				layouts_list: [],
				wet_spots_list: [],
				conditions_list: [],
				furnitures_list: [],
				access_list: [],
				parking_types1_list: [],
				parking_types2_list: [],
				parking_types3_list: [],
				vat_types_list: [],
				floor_materials_list: [],
				commerce_portal_list: [],
				commerce_purpose_list: [],
				commerce_land_categories_list: [],
				commerce_permitted_use_list: [],
				commerce_electricity_list: [],
				commerce_gas_list: [],
				commerce_water_list: [],
				commerce_sewage_list: [],
				commerce_driveways_list: [],
				building_types_list: [],
				building_classes_list: [],
				houseline_types_list: [],
				building_categories_list: [],
				entrance_types_list: [],
				building_ventilations_list: [],
				building_conditioning_list: [],
				building_heating_list: [],
				building_firefighting_list: [],
				garages_list: [],
				garages_types_list: [],
				boxes_types_list: [],
				commerce_status_list: [],
				commerce_specifications_list: [],
				commerce_infrastructures_list: [],
				commerce_infrastructures2_list: [],
				commerce_infrastructures3_list: [],
				errors: [],
            }
        },
		methods: {
			moment(config) {
				if (typeof config != "undefined")
					return moment(config);
				else
					return moment();
			},
			showNote(message) {
				return alert(message);
			},
			deBounce(callback, timeout) {
				return CommonService.debounce(callback, timeout);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			newArray(length) {
				return CommonService.newArray(length);
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			resetData() {
				this.filter_id = null;
				this.item = {
					id: null,
					deal_type: 2,
					object_type: 1,
					search_target: 1,
					commercial_type: [],
					regions: [],
					districts: [],
					metro: [],
					requisition: {
						id: null,
						name: null,
					}
				};
			},

			getTypes(section) {
				if (typeof section !== "undefined") {
					return api.get('/common/types', {
						params: {
							section: section
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							if (section == 'objects')
								this.$store.commit('setObjectTypesList', list);

							if (section == 'to_metro')
								this.$store.commit('setToMetroTypesList', list);

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getTypes()::axios', error);

					});
				}
			},
			getList(section) {
				if (typeof section !== "undefined") {
					return api.get('/common/list', {
						params: {
							section: section
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getList()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							/*if (section == 'objects')
								this.$store.commit('setObjectTypesList', list);

							if (section == 'to_metro')
								this.$store.commit('setToMetroTypesList', list);*/

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getList()::axios', error);

					});
				}
			},
			getLists(sections) {
				return api.get('/common/lists', {
					params: {
						sections: sections
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getLists()::axios', {response: response.data});

					return response.data.lists;

				}).catch(function (error) {

					CommonService.log('error', 'getLists()::axios', error);

					return null;
				});
			},
			getCommercialTypes() {
				return api.get('/common/commercial').then((response) => {

					CommonService.log('debug', 'getCommercialTypes()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name,
								checked: false
							});
						});

						this.$store.commit('setCommercialList', list);
						return list;
					}

				}).catch(function (error) {

					CommonService.log('error', 'getCommercialTypes()::axios', error);

				});
			},
			getSearchTargets() {
				return api.get('/common/types', {
					params: {
						section: 'autosearch'
					}
				}).then((response) => {

					CommonService.log('debug', 'getSearchTargets()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						this.$store.commit('setAutoSearchTargets', list);
						return list;
					}

				}).catch(function (error) {

					CommonService.log('error', 'getSearchTargets()::axios', error);

				});
			},
			getRegions() {
				return api.get('/common/regions', {
					params: {
						sub_regions: true
					}
				}).then((response) => {

					CommonService.log('debug', 'getRegions()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name,
								kladr: option.kladr
							});
						});

						this.$store.commit('setRegionsList', list);
						return list;
					}

				}).catch(function (error) {

					CommonService.log('error', 'getRegions()::axios', error);

				});
			},
			getDistricts(regions) {
				return api.get('/common/districts', {
					params: {
						region_id: regions ?? null
					}
				}).then((response) => {

					CommonService.log('debug', 'getDistricts()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						let groups = (response.data.groups) ? response.data.groups : [];

						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, district]) => {

								if (district.group_id) {

									let group_id = district.group_id;
									if (groups[group_id]) {

										list.push({
											value: '#',
											name: '<b>' + groups[group_id] + '</b>'
										});

										delete groups[group_id];
									}
								}

								list.push({
									value: district.id,
									name: district.name
								});
							});
						}

						this.$store.commit('setDistrictsList', list);
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getDistricts()::axios', error);

				});
			},
			getMetro(regions) {
				return api.get('/common/metro', {
					params: {
						region_id: regions ?? null
					}
				}).then((response) => {

					CommonService.log('debug', 'getMetro()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						this.$store.commit('setMetroList', list);
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getMetro()::axios', error);

				});
			},
			getHouseTypes() {
				return api.get('/common/types', {
					params: {
						section: 'house_types'
					}
				}).then((response) => {

					CommonService.log('debug', 'getHouseTypes()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						this.$store.commit('setHouseTypesList', list);
						return list;
					}

				}).catch(function (error) {

					CommonService.log('error', 'getHouseTypes()::axios', error);

				});
			},
			getHouseMaterialTypes() {
				return api.get('/common/types', {
					params: {
						section: 'house_materials'
					}
				}).then((response) => {

					CommonService.log('debug', 'getHouseMaterialTypes()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						this.$store.commit('setHouseMaterialsList', list);
						return list;
					}

				}).catch(function (error) {

					CommonService.log('error', 'getHouseMaterialTypes()::axios', error);

				});
			},

			setAndValidate(name, value) {

				let errors = [];
				let parent = null;
				let child = null;
				let parts = name.split('.', 2);
				if (parts.length == 2) {
					parent = parts[0];
					child = parts[1];
				}

				// Валидация значения
				if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {

					if (this.$refs[name].inputLabel) {
						let label = this.$refs[name].inputLabel.replace(/:+$/, '');

						let is_skip = false;
						if (name == 'funnel_id' && value == 0)
							is_skip = true;

						if (this.$refs[name].required && !is_skip) {
							if (value) {
								if (value.toString() == '0' || value.toString() == '-1')
									errors.push('Поле `' + label + '` обязательно к заполнению!');
								else if (this.isEmpty(value))
									errors.push('Поле `' + label + '` обязательно к заполнению!');
							} else {
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							}
						}

						if (!this.isEmpty(errors)) {
							this.errors[name] = {
								name: name,
								label: label,
								errors: errors
							};

							this.$refs[name].valid = false;
							this.$refs[name].invalid = errors.join('<br/>');
						} else {
							this.errors[name] = null;
							this.$refs[name].valid = true;
							this.$refs[name].invalid = false;
						}
					}
				}

				// Установка значения
				if (parent && child) {
					if (!this.isEmpty(this.item[parent])) {
						if (typeof (this.item[parent][child]) !== "undefined") {
							this.item[parent][child] = value;
						} else {
							this.item[parent] = {
								...{
									[child]: value
								},
								...this.item[parent]
							};
						}
					} else {
						this.item[parent] = {
							[child]: value
						};
					}
				} else if (parent == null && name) {
					this.item[name] = value;
				} else {
					if (parent && child)
						this.item[parent][child] = null;
					else
						this.item[name] = null;
				}

				if (name == 'commercial_type') {
					this.item[name] = this.commercial_types_list.filter(item => item.checked).map(item => item.value);
					// this.getC(this.item.regions).then(data => {
					// 	this.districts_list = data;
					// 	/*this.item.districts = null;
					// 	this.metro_list = null;*/
					// });
				}

				if (name == 'regions') {
					this.getDistricts(this.item.regions).then(data => {
						this.districts_list = data;
						/*this.item.districts = null;
						this.metro_list = null;*/
					});
				}

				if (name == 'districts') {
					this.getMetro(this.item.regions).then(data => {
						this.metro_list = data;
						/*this.item.metro = null;*/
					});
				}

				this.is_form_changed = true;

				CommonService.log('debug', 'setAndValidate()', { name: name, value: value, is_form_changed: this.is_form_changed, errors: errors });


				if (!this.isEmpty(errors))
					return false;

				return true;
			},
			async getFilter(filter_id) {
				return api.get('/autosearch/filter', {
					params: {
						filter_id: filter_id ?? null
					}
				}).then((response) => {

					CommonService.log('debug', 'getFilter()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						return response.data.filter;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getFilter()::axios', error);

				});
			},
			validateAll(data) {
				return true; // @todo: setup validation
			},
			addEditFilter() {
				if (this.validateAll(this.item)) {


					/*if (!this.isEmpty(this.item.price_from))
						this.item.price_from = parseInt(this.item.price_from);

					if (!this.isEmpty(this.item.price_to))
						this.item.price_to = parseInt(this.item.price_to);*/

					api.post('/autosearch/filter', this.item).then((response) => {

						CommonService.log('debug', 'addEditFilter()::axios', {response});

						if (response.status == 200 && response.data.success) {

							this.$emit('filterAdded', {filter_id: response.data.filter_id});
							this.emitter.emit("autoSearchFilterAdded", {filter_id: response.data.filter_id});

							let modal = CommonService.getModal('autoSearchEditModal')
							if (modal)
								modal.hide();

						} else {
							if (!this.isEmpty(response.data.errors)) {
								this.errors = response.data.errors;
							}
						}
					}).catch(function (error) {

						CommonService.log('error', 'addEditFilter()::axios', error);

					});
				}
			}
		},
		mounted() {
			var _this = this;
			var modal = this.$refs.autoSearchEditModal;
			if (modal && typeof modal !== "undefined") {

				modal.addEventListener('hidden.bs.modal', function (event) {
					_this.resetData();
					_this.emitter.emit("global.modalClose", modal);
					
					const crmNode = document.querySelector('.crm');

					if(crmNode) {
						crmNode.style.width = 'initial';
						crmNode.style.overflow = 'initial';
						crmNode.style.position = 'initial';
						crmNode.style.zIndex = 'initial';
					}
				});

				modal.addEventListener('show.bs.modal', function (event) {
					
					const crmNode = document.querySelector('.crm');

					if(crmNode) {
						crmNode.style.width = '100%';
						crmNode.style.overflow = 'hidden';
						crmNode.style.position = 'fixed';
						crmNode.style.zIndex = '1111';
					}
					
					let target = event.relatedTarget
					_this.errors = [];

					let filter_id = target.getAttribute('data-bs-asfilter-id');
					if (!_this.isEmpty(filter_id)) {
						_this.filter_id = filter_id;
						_this.getFilter(_this.filter_id).then(data => _this.item = data);
					}

					let req_type_id = target.getAttribute('data-bs-requisition-type-id');
					if (!_this.isEmpty(req_type_id)) {
						_this.req_type_id = req_type_id;
					}

					if (!_this.isEmpty(_this.req_type_id)) {
						if (_this.req_type_id == 1) {
							_this.item.is_search = true;
							_this.item.is_reverse = true;
						} else if (_this.req_type_id == 4) {
							_this.item.is_search = false;
							_this.item.is_reverse = true;
						}
					}

					setTimeout(() => {
						_this.getTypes('objects').then(data => _this.objects_types_list = data);
						_this.getCommercialTypes().then(data => _this.commercial_types_list = data);
						_this.getTypes('lease').then(data => _this.lease_types_list = data);
						_this.getRegions().then(data => _this.regions_list = data);

						// Устанавливаем регионы пользователя по-умолчанию
						let set_customs = false;
						if (!_this.isEmpty(_this.$store.getters.userInfo)) {
							if (typeof _this.$store.getters.userInfo.search_regions_id !== "undefined") {
								_this.item.regions = _this.$store.getters.userInfo.search_regions_id;

								if (typeof _this.$store.getters.userInfo.region_id !== "undefined") {
									_this.getDistricts(_this.$store.getters.userInfo.region_id).then(data => _this.districts_list = data);
									set_customs = true;
								}
							}
						}

						if (set_customs == false && !_this.isEmpty(_this.item.regions))
							_this.getDistricts(_this.item.regions).then(data => _this.districts_list = data);

						if (!_this.isEmpty(_this.item.regions) && !_this.isEmpty(_this.item.districts) && !_this.isEmpty(_this.districts_list))
							_this.getMetro(_this.item.regions).then(data => _this.metro_list = data);

						if (!_this.isEmpty(_this.item.regions) && !_this.isEmpty(_this.item.districts) && !_this.isEmpty(_this.metro_list))
							_this.getTypes('to_metro').then(data => _this.to_metro_list = data);

						_this.getSearchTargets().then(data => _this.search_targets_list = data);
						_this.getHouseTypes().then(data => _this.house_types_list = data);
						_this.getHouseMaterialTypes().then(data => _this.house_materials_list = data);

						_this.getLists([
							'legal_address',
							'occupieds',
							'layouts',
							'wet_spots',
							'conditions',
							'furnitures',
							'access',
							'parking_types1_list',
							'parking_types2_list',
							'parking_types3_list',
							'vat_types',
							'floor_materials',
							'commerce_portal',
							'commerce_purpose',
							'commerce_land_categories',
							'commerce_permitted_use',
							'commerce_electricity',
							'commerce_gas',
							'commerce_water',
							'commerce_sewage',
							'commerce_driveways',
							'building_types',
							'building_classes',
							'houseline_types',
							'building_categories',
							'entrance_types',
							'building_ventilations',
							'building_conditioning',
							'building_heating',
							'building_firefighting',
							'garages',
							'garages_types',
							'boxes_types',
							'commerce_status',
							'commerce_specifications',
							'commerce_infrastructures',
							'commerce_infrastructures2',
							'commerce_infrastructures3',
						]).then(data => {

							if (!_this.isEmpty(data)) {
								Object.entries(data).forEach(([section, list]) => {
									if (!_this.isEmpty(list)) {
										for (var i = 0; i < list.length; i++) {
											list[i].value = list[i]['id'];
											delete list[i].id;
										}

										if (typeof _this[section + '_list'] !== "undefined") {
											if (_this.inArray(section, [
												'commerce_purpose',
												'commerce_land_categories',
												'commerce_permitted_use'
											])) {
												_this[section + '_list'] = _this.sortArray(list);
											} else {
												_this[section + '_list'] = list;
											}
										} else {
											CommonService.log('error', 'getLists()', 'Not found variable by name `' + section + '_list`');
										}
									}
								});
							}

							return _this;
						});
					}, 0);

					if (process.env.NODE_ENV == "development") {
						console.debug('autoSearchEditModal::show.bs.modal', {
							filter_id: _this.filter_id
						});
					}

					if (_this.isEmpty(_this.item['deal_type']))
						_this.setAndValidate('deal_type', 1);

					_this.emitter.emit("global.modalShown", modal);
				});
			}
		},
		watch: {

		},
		computed: {
			is_beta_tester() {
				let user_id = parseInt(this.$store.getters.userInfo.id);
				let beta_testers = this.$root.config.beta_testers_ids;
				return this.inArray(user_id, beta_testers);
			},
			infrastructures_list() {
				if (!this.isEmpty(this.item.commercial_type)) {

					if (this.item.commercial_type.includes(7))
						return this.commerce_infrastructures2_list // автомойка - шиномонтаж
					else if (this.item.commercial_type.some(item => [2,4].includes(item)))
						return this.commerce_infrastructures3_list; // буфет, гостинница
				}

				return this.commerce_infrastructures_list
			},
		},
    }
</script>